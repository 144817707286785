<template>
  <Modal id="AccessObjSetModal" ref="modal">
    <template #head>
      <h4 style="float: left">Uprawnienia</h4>
      <img style="float: right; margin-right: 40px;"  src="@/assets/img/edit.svg" alt="" @click="switchToManage"/>
    </template>
    <template #body>
      <ul class="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0 mb-3"
          role="tablist" style="cursor: pointer">
        <li class="nav-item">
          <a class="nav-link" @click="switchToUser">
            Użytkownicy
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active">
            Grupy
          </a>
        </li>
      </ul>
      <SystemAccessObjSetTable ref="accessObjTable" :groups="groups" v-on:accessUpdate="accessUpdate"/>
    </template>
  </Modal>
</template>

<script>

import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine";
import {mapState} from "vuex";
import SystemAccessObjSetTable from "./ObjSetTable";
import axios from "@/axios";
import Modal from "@/Library/Components/Modal";


export default {
  name: "SystemAccessObjGroupSet",
  components: {Modal, SystemAccessObjSetTable},
  extends: ComponentFormStateMachine,
  data() {
    return {
      id_obj: null,
      window: null,
      groups:[],

      get_group_url:'/usergroup/get'
    }
  },
  methods: {

    modal() {
      return this.$refs.modal;
    },
    async open() {
      await this.loadGroups();
      this.modal().open();
    },
    close() {
      this.modal().close();
    },
    refreshAccess() {

      this.$refs.accessObjTable.refreshAccess();
    },

    /**
     * Wczytuje użytkowników
     */
    async loadGroups() {

      /**
       * Aktualizacja stanu
       */
      this.stateUpdate('sendInfo');

      /**
       * Wysłanie żądania
       */
      await axios
          .get(this.get_group_url)
          .then(response => {
            /**
             * Przypisanie danych
             */
            let groups = response.data.data;
            console.log(groups)
            /**
             * Wszyscy użytkownicy dla potrzeb dodawania
             */
            this.groups = groups;

            /**
             * Aktualizacja stanu
             */
            this.stateUpdate('sendSuccess');
          })
          .catch(error => console.log(error))
    },

    accessUpdate(accessAll){
      console.log(accessAll)
      let access = {}
      for(let accessRow of accessAll){
        let permission_local = [];
        let permission_local_count = 0;
        for(let [permission_name, permission_value] of Object.entries(accessRow.permissions)){
          if(permission_value === 'local'){
            permission_local[permission_name] = permission_value;
            permission_local_count++;
          }
        }

        if (permission_local_count > 0){
          access[accessRow.user.id] = {
            user: accessRow.user,
            permissions: permission_local,
          }
        }
      }

      let window = this.window;

      if(window && window.accessUpdate && typeof window.accessUpdate === 'function'){
        window.accessUpdate(access)
      }

    },

    switchToManage(){

      let app = this.$root.$refs.app;
      let accessObjGroupManage = app.$refs.accessObjGroupManage;

      accessObjGroupManage.id_obj = this.id_obj;
      accessObjGroupManage.window = this.window;

      this.close();

      accessObjGroupManage.open(this.id_obj)
      accessObjGroupManage.refreshAccess()

    },
    
    switchToUser() {

      let app = this.$root.$refs.app;
      let accessObjSet = app.$refs.accessObjSet;

      accessObjSet.id_obj = this.id_obj;
      accessObjSet.window = this.window;

      this.close();

      accessObjSet.open(this.id_obj)
      accessObjSet.refreshAccess()

    },
  },
  computed: {
    ...mapState('app', ['config']),

  },
  watch: {
    id_obj: async function (id_obj) {
      this.$refs.accessObjTable.id_obj = id_obj;
    }
  }
}
</script>


<style scoped>

</style>