<template>
  <input class="row-nice__input" type="month" v-model="value" @keyup="updateValue" @change="updateValue"/>
</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputMonth",
  extends: InputBase,
  props: {
    name: {},
    value: {}
  },
  methods: {
    val() {
      return this.value;
    },
    updateValue() {
      this.$emit('updateValue', this.name, this.value);
    }
  }
}
</script>