<template>

    <tr v-if="access">
      <td>
        {{access.group.name}}
      </td>
      <td>
        <Row type="toggle"
             :label="permissions[perrmision_key]"
             :name="perrmision_key"
             v-for="(perrmision_value, perrmision_key)  in access.permissions"
             :value="perrmision_value"
             :value_input="'local'"
             :value_input_false="false"
             v-on:updateValue="updateValue" />
      </td>
    </tr>

</template>

<script>

import axios from "@/axios";
import ShowUser from "@/Library/Show/User";
import Row from "@/Library/Forms/Row";

export default {
  name: "SystemAccessObjSetTableRow",
  components: {Row, ShowUser},
  props: ['access','permissions','id_obj'],
  emits: ['accessUpdate'],
  data: () => ({
  }),
  methods: {

    perrmissionName(perrmission_val){
      return this.permissions[perrmission_val];
    },



    updateValue(name, value) {

      if( value === null || value === false)
        this.accessRevoke(name);

      if( value !== null &&  value !== false)
        this.accessGrant(name);

    },

    async accessGrant(code){

      this.access.permissions[code] = 'local';

      let data = {
        'id_user_group': this.access.group.id,
        'privileges': code,
        'id_obj': this.id_obj,
      }

      await axios.post('useraccess/grant',data).then(function (data) { })

      this.$emit('accessUpdate', this.access);

    },
    async accessRevoke(code){

      this.access.permissions[code] = 'no';

      let data = {
        'id_user_group': this.access.group.id,
        'privileges': code,
        'id_obj': this.id_obj,
      }

      await axios.delete('useraccess/revoke',{params: data}).then(function (data) { })

      this.$emit('accessUpdate', this.access);

    },

  },
  computed: {
  },
  watch: {

  },
}
</script>

<style scoped>

</style>