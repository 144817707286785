<template>
  <a :href="'mailto:'+mail_show"
     :class="{
      'no-mail': mail_show === 'Brak'
     }"
     class="show-mail">{{mail_show}}</a>

</template>

<script>

export default {
  name: "ShowMail",
  props: ['mail'],

  computed: {
    mail_show(){

      let ret = "Brak";
      if( this.mail ){
        ret = this.mail;
    }

      return ret;

  }
  }

}
</script>

<style scoped lang="scss">

  .show-mail{

    &.no-mail{
      color: #ccc;
    }

  }

</style>