<template>

    <div>

        <Row label="Nazwa szablonu" name="name" type="text" :value="obj.name" ref="name" @updateValue="updateValue"/>

        Lista zadań
        <Row label="" name="data" type="textlist" class="m-0" :value="obj.data" ref="data" @updateValue="updateValue"/>

    </div>
</template>

<script>
import Row from "@/Library/Forms/Row";
import ControllForm from "@/Library/Controll/Form";

export default {
    name: "TicketTaskTemplateForm",
    components: {Row},
    mixins: [ControllForm],
    data: () => ({
        row_save_to: 'props',
    }),
    props: {
        obj: {
            default() {
                return {};
            }
        },
    },
    computed: {}
}
</script>

<style scoped lang="scss">
</style>