<template>

  <div class="access-form">
    <div class="row-nice mb-1">
      <InputAutocomplete :value.sync="user" :value_show.sync="user_show" url="user/get" url_parameter_name="search_text"
                         :input_id="inputId"
                         v-on:updateValue="updateAutocompleteValue"
                         ref="userInput"
      />
      <label :for="inputId" class="row-nice__label">
        Użytkownik
      </label>
    </div>

    <InputToggle class="mb-1" v-for="(permission_value, permission_key) in permissions" :label="permission_value"
                 :name="permission_key" value_input="local" :value="access[permission_key]"
                 v-on:updateValue="(name, value) => { access[name] = value }"/>
<div class="clearfix"></div>
    <button class="btn btn-success w-100" @click="accessAdd">Dodaj</button>

  </div>
</template>

<script>
import InputAutocomplete from "../../Forms/InputAutocomplete";
import InputToggle from "../../Forms/InputToggle";
import axios from "@/axios";

export default {
  name: "SystemAccessObjManageAdd",
  components: {InputToggle, InputAutocomplete},
  props: {
    'permissions': {
      type: Object,
      default() {
        return {}
      },
    },
    'id_obj': {
      type: String,
      default() {
        return null
      },
    },
  },
  emits: ['accessAdd'],
  data: () => ({
    'input_id': null,
    'user': "",
    'user_show': "",
    'user_avatar': "",
    'access': {},
  }),
  computed: {
    inputId() {
      return this.input_id ? this.input_id : 'input' + parseInt(Math.random() * 99999);
    }
  },
  methods: {
    updateAutocompleteValue(name, value, label, avatar) {
      this.user = value;
      this.user_show = label;
      this.user_avatar = avatar;
    },
    async accessAdd(e) {

      e.preventDefault();

      let access_selected = [];

      let access_keys = Object.keys(this.access);
      let access_values = Object.values(this.access);
      let access_size = access_keys.length;
      for (let i = 0; i < access_size; i++)
        if (access_values[i] === 'local')
          access_selected.push(access_keys[i]);

      let data_send = {
        'id_user': this.user,
        'id_obj': this.id_obj,
        'user_id': this.user,
        'user_name': this.user_show,
        'user_avatar': this.user_avatar,
        'access': access_selected
      }

      this.clear();
      await axios
          .post('useraccess/objaccessadd', data_send)
          .then(function (result) {

          })
          .catch(function (erros) {

          })

      this.$emit('accessAdd', data_send);


    },
    clear(){
      this.user = null;
      this.user_show = null;
      this.user_avatar = null;

      this.$refs.userInput.clear();

      let keys = Object.keys(this.permissions);
      let count = keys.length;
      this.access = [];
      for (let i = 0; i < count; i++)
        this.access[keys[i]] = 'no';

      // let permission_keys = Object.keys(this.permissions);
      // let permission_values = Object.values(this.permissions);
      // for (let i = 0; i < count; i++)
      //   this.permissions[permission_keys[i]] = 'no';

    },
  },
  mounted() {

    this.clear();

  }

}
</script>

<style scoped lang="scss">

.access-form {
  background-color: #fafafa;

  padding: 20px;

  border-radius: 6px;
}

</style>