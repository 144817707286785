<template>
  <a :href="link_show"
     :class="{'show-link--no': link_show === 'Brak'}"
     class="show-link">{{link_show}}</a>

</template>

<script>

export default {
  name: "ShowLink",
  props: ['data'],

  computed: {
    link_show(){
      return this.data ?? "Brak";
    }
  }
}
</script>

<style scoped lang="scss">

  .show-link{

    &--no{
      color: #ccc;
    }

  }

</style>