<template>
    <ShowAudio :audio="data" v-if="typeOut === 'audio'" :class="this.class"/>
    <ShowLetterAvatar :text="data" v-if="typeOut === 'letter-avatar'" :class="this.class"/>
    <ShowColor :data="data" v-if="typeOut === 'color'"/>
    <ShowDate :date="data" v-if="typeOut === 'date'"/>
    <ShowDateTime :date="data" v-if="typeOut === 'datetime'" :show_time="true"/>
    <ShowInfo :data="data" :config="config" v-if="typeOut === 'info' || typeOut === 'text'"/>
    <ShowLink :data="data" v-if="typeOut === 'link'" :class="this.class" :style="this.style"/>
    <ShowMail :mail="data" v-if="typeOut === 'mail'" :class="this.class" :style="this.style"/>
    <ShowOption :data="data" v-if="typeOut === 'radio' || typeOut === 'select'" v-bind="config"/>
    <ShowPercent :percent="data" v-if="typeOut === 'percent'"/>
    <ShowPhone :number="data" v-if="typeOut === 'phone'" :class="this.class" :style="this.style"/>
    <ShowPrice :price="data" v-if="typeOut === 'price'"/>
    <ShowProgress :data="data" v-if="typeOut === 'progress'" :class="this.class" :style="this.style"/>
    <ShowStatus :status="data" v-if="typeOut === 'status'"/>
    <ShowTime :date="data" v-if="typeOut === 'time'" :show_second="true"/>
    <ShowUser :user="data" v-if="typeOut === 'user'" :class="this.class"/>
    <ShowVideo :video="data" v-if="typeOut === 'video'" :class="this.class"/>
    <ShowYesNo :data="data" :config="config" v-if="typeOut === 'yesno'"/>
    <ShowLocation :data="data" v-if="typeOut === 'location'"/>
</template>

<script>
import ShowUser from "@/Library/Show/User";
import ShowPrice from "@/Library/Show/Price";
import ShowDate from "@/Library/Show/Date";
import ShowDateTime from "@/Library/Show/DateTime";
import ShowTime from "@/Library/Show/Time";
import ShowStatus from "@/Library/Show/Status";
import ShowInfo from "@/Library/Show/Info";
import ShowPercent from "@/Library/Show/Percent";
import ShowAudio from "@/Library/Show/Audio";
import ShowVideo from "@/Library/Show/Video";
import ShowProgress from "@/Library/Show/Progress";
import ShowYesNo from "@/Library/Show/YesNo";
import ShowMail from "@/Library/Show/Mail";
import ShowLink from "@/Library/Show/Link.vue";
import ShowColor from "@/Library/Show/Color.vue";
import ShowOption from "@/Library/Show/Option.vue";
import ShowPhone from "@/Library/Show/Phone.vue";
import ShowLetterAvatar from "@/Library/Show/ShowLetterAvatar.vue";
import ShowLocation from "@/Library/Show/Location.vue";

export default {
    name: "Show",
    components: {
        ShowLocation,
        ShowLetterAvatar,
        ShowPhone,
        ShowOption,
        ShowColor,
        ShowLink,
        ShowMail,
        ShowYesNo,
        ShowProgress,
        ShowVideo,
        ShowAudio, ShowPercent, ShowInfo, ShowStatus, ShowTime, ShowDateTime, ShowDate, ShowPrice, ShowUser
    },
    props: {
        type: {
            default() {
                return "info";
            }
        },
        data: {
            default() {
                return null;
            }
        },
        config: {
            default() {
                return {};
            }
        }
    },
    computed: {

        typeOut() {

            let ret = this.type;

            if ((ret === 'radio' || ret === 'select') && this.config['options'] === 'yn')
                ret = 'yesno';

            return ret;

        },

        class() {

            let ret = null;
            if (this.config && this.config['class'] !== undefined)
                ret = this.config['class'];

            return ret;

        },
        style() {

            let ret = null;
            if (this.config && this.config['style'] !== undefined)
                ret = this.config['style'];

            return ret;

        }
    }
}
</script>

<style scoped>

</style>