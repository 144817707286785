<template>
  <div>
    <!-- ========== Left Sidebar Start ========== -->
    <!-- ========== App Menu ========== -->
    <div class="app-menu navbar-menu custom-nav nav-our">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <!-- Dark Logo-->
        <router-link to="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="@/assets/images/logo-sm.png" alt="" height="22"/>
          </span>
          <span class="logo-lg">
            <logotext/>
          </span>
        </router-link>
        <!-- Light Logo-->
        <router-link to="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="@/assets/images/logo-sm.png" alt="" height="22"/>
          </span>
          <span class="logo-lg">
            <logotext/>
          </span>
        </router-link>
        <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                id="vertical-hover">
          <i class="ri-record-circle-line"></i>
        </button>
      </div>

      <template v-if="type === 'domain'">
        <SimpleBar id="scrollbar" class="h-100 menu-left" ref="scrollbar">
          <MenuDomain></MenuDomain>
        </SimpleBar>

        <MenuDomain class="menu-top"></MenuDomain>
      </template>

      <template v-if="type === 'standard'">
        <SimpleBar id="scrollbar" class="h-100 menu-left" ref="scrollbar">
          <Menu></Menu>
        </SimpleBar>

        <Menu class="menu-top"></Menu>

        <router-link v-if=" this.layoutType === 'vertical'" to="/domain/" class="btn btn-domain">
          <span class="btn-domain__icon material-symbols-outlined">public</span>
          <span class="btn-domain__name">Serwisy</span>
        </router-link>
      </template>

    </div>
    <!-- Left Sidebar End -->
    <!-- Vertical Overlay-->
    <div class="vertical-overlay" @click="toggleMenu"></div>
  </div>
</template>

<script>
import {SimpleBar} from "simplebar-vue3";
import Menu from "@/layouts/part/menu.vue";

import router from "@/router";
import {mapState} from "vuex";
import Logotext from "@/layouts/part/LogoText.vue";
import MenuDomain from "@/layouts/part/menu-domain.vue";

export default {
  name: "side-bar",
  components: {MenuDomain, Logotext, Menu, SimpleBar},
  props: {
    type: {
      default(){
        return 'standard'
      }
    },
  },
  data: () => ({

  }),
  computed: {
    ...mapState('user', ['info']),
    ...mapState('app', ['layoutMode', 'layoutType']),
  },
  methods: {

    toggleMenu() {


      document.body.classList.toggle("vertical-sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("vertical-sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("vertical-sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
  }
}
</script>

<style scoped lang="scss">

.menu-left,
.menu-top {
  display: none;

}

[data-layout="vertical"] .menu-left {
  display: block;

}

[data-layout="horizontal"] .menu-top {
  display: block;
}


.btn-domain {

  margin: -15px 15px 15px;
  width: calc(100% - 30px);
  background-color: var(--secondary-color);
  color: #fff;

  cursor: pointer;

  &:hover {
    background-color: var(--secondary-color-darker) !important;
    color: #fff !important;
  }

  [data-layout=vertical][data-sidebar-size=sm] & {
    //display: none;
    padding-left: 0;
    padding-right: 0;
  }

  [data-layout=vertical][data-sidebar-size=sm] &__icon {
    display: initial;
  }

  [data-layout=vertical][data-sidebar-size=sm] &__name {
    display: none;
  }

  [data-layout=vertical][data-sidebar-size=lg] &__icon {
    display: none;
  }

  [data-layout=vertical][data-sidebar-size=lg] &__name {
    display: initial;
  }
}

</style>