<script>

import string from "@/Library/Helpers/string";
import axios from "@/axios";

export default {
  name: "Validate",
  props:
      [
        'validate-is-equal-to',
        'validate-required',
        'validate-unique',
        'validate-min',
        'validate-min-length',
        'validate-max',
        'validate-max-length',
        'validate-nip',
        'validate-mail',
        'validate-strength',
        'validate-postalCode',
      ],
  emits: ['updateValidate'],
  data() {
    return {
      valid: true,
      valid_test: false,
      valid_code: "",
      valid_text: "",
    }
  },
  methods: {
    validators() {

      let validate_prefix = 'validate';
      let validate_prefix_length = validate_prefix.length;
      let arr = this.$props;
      let arr_correct = [];

      for (const [key, value] of Object.entries(arr)) {
        let add = true
        if (value === undefined)
          add = false;

        if (key.substring(0, validate_prefix_length) !== validate_prefix)
          add = false;

        if (add)
          arr_correct[key.substring(validate_prefix_length).toLowerCase()] = value;

      }

      return arr_correct;

    },
    async validTestOne(validator, data) {

      let ret = {
        valid: true,
        name: this.name,
        code: '',
        text: '',
      };

      switch (validator) {

        case 'required':
          if (this.value === "" || this.value === undefined || this.value === null) {

            ret = {
              valid: false,
              name: this.name,
              code: 'is-empty',
              text: 'Pole jest wymagane',
            }
          }
          break;
        case 'unique':

          let params = {};
          params[this.name] = this.value;

          let result_data = await axios.get(data, {params: params}).then(r => r.data.data);

          if (!result_data || result_data.length > 0) {
            ret = {
              valid: false,
              name: this.name,
              code: 'not-unique',
              text: 'Taki obiekt już istnieje',
            }
          }
          break;

        case 'isequalto':

          let id = data;
          let element = document.getElementById(id);

          let value_compare = this.value;
          value_compare = value_compare === undefined ? '' : value_compare;


          if (value_compare !== element.value) {

            ret = {
              valid: false,
              name: this.name,
              code: 'not-equal',
              text: 'Pola nie są równe',
            }
          }
          break;

        case 'min':
          if (parseFloat(this.value) < parseFloat(data)) {

            ret = {
              valid: false,
              name: this.name,
              code: 'is-too-small',
              text: 'Proszę podać conajmniej ' + data,
            }
          }
          break;
        case 'minlength':
          if (this.value.length < parseInt(data)) {

            ret = {
              valid: false,
              name: this.name,
              code: 'is-too-short',
              text: 'Za krótki ' + data,
            }
          }
          break;

        case 'max':
          if (parseFloat(this.value) > parseFloat(data)) {

            ret = {
              valid: false,
              name: this.name,
              code: 'is-too-big',
              text: 'Proszę podać conajwyżej ' + data,
            }
          }
          break;
        case 'maxlength':
          if (this.value.length > parseInt(data)) {

            ret = {
              valid: false,
              name: this.name,
              code: 'is-too-big',
              text: 'Za długi ' + data,
            }
          }
          break;

        case 'nip':
          let nip = String(this.value);
          let sum = 0;
          let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
          if (/[a-zA-Z]/.test(nip)) {
            ret = {
              valid: false,
              name: this.name,
              code: 'not-valid-nip',
              text: 'NIP nie może mieć liter',
            }
            break;
          }
          if (nip.length < 10) {
            ret = {
              valid: false,
              name: this.name,
              code: 'not-valid-nip',
              text: 'Za krótki NIP',
            }
            break;
          }
          if (nip.length > 10) {
            ret = {
              valid: false,
              name: this.name,
              code: 'not-valid-nip',
              text: 'Za długi NIP',
            }
            break;
          }
          for (let i = 0; i < 9; i++) {
            sum += nip[i] * weights[i];
          }
          if (sum % 11 !== Number(nip[9])) {
            ret = {
              valid: false,
              name: this.name,
              code: 'not-valid-nip',
              text: 'Nieprawidłowy NIP - zła cyfra kontrolna',
            }
          }
          break;

        case 'strength':

          let strongRegex = new RegExp("^(?=.{2,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
          let mediumRegex = new RegExp("^(?=.{2,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
          let weekRegex = new RegExp("(?=.{2,}).*", "g");

          let result = {};
          result['strong'] = strongRegex.test(this.value);
          result['medium'] = mediumRegex.test(this.value);
          result['week'] = weekRegex.test(this.value);

          let test_value = result[data] ?? false;


          if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.value)) {
            ret = {
              valid: test_value,
              name: this.name,
              code: 'not-valid-pass',
              text: 'Hasło zbyt słabe',
            }
          }
          break;
        case 'mail':
          if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.value)) {
            ret = {
              valid: false,
              name: this.name,
              code: 'not-valid-mail',
              text: 'Nieprawidłowy email',
            }
          }
          break;
      }

      return ret;


    },
    async validTest(config = []) {

      let first_stop = config['first_stop'] ? config['first_stop'] : true;

      let vaslidators = this.validators();

      let result;

      let errors = [];

      console.log('V - Test '+this.name)
      for (const [validator, data] of Object.entries(vaslidators)) {

        console.log('V - Loop '+validator)
        result = await this.validTestOne(validator, data);

        if (result.valid === false)
          errors.push(result);

        if (errors.length && first_stop)
          break;

      }

      return errors;

    },
    async validate() {

      this.valid = true;
      this.valid_test = true;
      this.valid_code = "";
      this.valid_text = "";

      let errors = await this.validTest();

      if (errors.length > 0) {

        let first_error = errors[0];

        this.valid = first_error.valid;
        this.valid_code = first_error.code;
        this.valid_text = first_error.text;

      }

      this.$emit('updateValidate', errors);

      return errors;

    },
    validateReset() {

      this.valid = true;
      this.valid_test = false;
      this.valid_code = "";
      this.valid_text = "";
    }

  },

  computed: {},
  mounted() {

  },
  watch: {
    value(value) {

      if (this.valid_test)
        this.validate();

    }
  }

}
</script>

<style scoped>

</style>