<template>
  <draggable
      tag="ul"
      :list="objs"
      :group="{ name: 'g1' }"
      :item-key="index_column"
      :disabled="!move"
      @end="$emit('actionUpdateLocation', objs)"
      class="p-0">
    <template #item="{ element }">
    <li class="list-item">
      <span v-if="show && element.link"><router-link :to="element.link"> {{ element.name }}</router-link></span>
      <span v-if="show && !element.link"> {{ element.name }}</span>
      <span v-if="!show">{{ element.name }}</span>

      <div class="list-item__buttons">
        <div class="list-button" v-if="edit_button"
             @click="$emit('actionEditClick', element)">
          <ShowSvg icon="edit" color="#465d8b"/>
        </div>
        <div class="list-button" v-if="delete_button"
             @click="$emit('actionDeleteClick', element)">
          <ShowSvg icon="delete" color="red"/>
        </div>
        <a class="list-button list-button--drag" v-if="move"/>
      </div>
    </li>
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import ShowSvg from "@/Library/Show/Svg";

export default {
  name: "List",
  components: {ShowSvg, draggable},
  emits: ['actionShowClick', 'actionEditClick', 'actionDeleteClick','actionUpdateLocation'],
  props: {

    /**
     * Elementy listy
     */
    objs: {
      default() {
        return {};
      }
    },

    /**
     * Czy pokazać element listy
     */
    show: {
      type: Boolean,
      default() {
        return false;
      }
    },

    /**
     * Czy można przeciągać elementy
     */
    move: {
      type: Boolean,
      default() {
        return false;
      }
    },

    /**
     * Czy pokazać przycisk edycji
     */
    edit_button: {
      type: Boolean,
      default() {
        return false;
      }
    },

    /**
     * Czy pokazać przycisk usuwania
     */
    delete_button: {
      type: Boolean,
      default() {
        return false;
      }
    },

    /**
     * Nazwa indeksu
     */
    index_column: {
      type: String,
      default() {
        return 'name';
      }
    },
  },
  mounted() {
    console.log(this.objs)
  }
}
</script>

<style scoped lang="scss">

.list-item {
  display: inline-block;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;

  position: relative;
  margin-bottom: 15px;
  padding: 12px;

  border: 1px solid #eee;

  &__buttons {
    position: absolute;
    right: 18px;
    top: 12px;

    cursor: pointer;

    display: flex;
  }


  [data-layout-mode="dark"] &{
    background-color: var(--sp-card-bg);
    border-color: var(--sp-card-bg);
  }
}

.list-button{
  margin-left: 12px;

  &--drag {
    display: block;
    width: 20px;
    height: 7px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    margin-top: 8px;
  }
}

</style>