<template>
  <div class="info" v-if="!isLink" v-html="data">

  </div>
  <router-link v-if="isLink" :to="config.link" v-html="data"></router-link>

</template>

<script>

export default {
  name: "ShowInfo",
  props: {
    data: {},
    config: {},
  },
  computed:{
    isLink() {
      return this.config.link !== undefined;
    }
  }

}
</script>

<style scoped lang="scss">

  .contact-info{

    &__name{

    }
    &__mail{

    }
    &__phone{

    }

  }

</style>