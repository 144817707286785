<script>

import ControllToast from '@/Library/Controll/Toast';

export default {
  name: "ControllApp",
  mixins: [ControllToast],
  data: () => ({
    internetConnect: navigator.onLine,
    internetChangeToast: false,
    internetChange: false,
    internetIsBack: false,
    internetIsGone: false,
  }),
  methods: {

    internetUpdate(e) {
      const { type } = e;
      this.internetConnect = type === 'online';
    }
  },
  computed: {

  },
  watch: {
    internetConnect(v) {

      this.internetChange = true;

      if (v) {
        this.internetIsBack = true;
      }
      if (!v) {
        this.internetIsGone = true;
      }

      if(this.internetChangeToast && this.internetIsBack){
        this.toastSuccess('Hurrra masz z powrotem internet');
      }

      if(this.internetChangeToast && this.internetIsGone){
        this.toastError('Straciłeś dostęp do internetu');
      }

      setTimeout(() => {
        this.internetChange = false;
        this.internetIsBack = false;
        this.internetIsGone = false;
      }, 1000);
    }
  },
  mounted() {
    window.addEventListener('online', this.internetUpdate);
    window.addEventListener('offline', this.internetUpdate);
  },
  unmounted() {
    window.removeEventListener('online', this.internetUpdate);
    window.removeEventListener('offline', this.internetUpdate);
  }

}
</script>
