<script>

import ComponentStateMachine from "@/Library/Extends/ComponentStateMachine";

/**
 * Stan kompunentu gdy oczekuje na akcje użytkownika
 *
 * @type {string}
 */
const modal_form_state_waiting = 'waiting';

/**
 * Stan kompunentu gdy oczekuje na odpowiedź z serwera
 * @type {string}
 */
const modal_form_state_loading = 'loading';

/**
 * Stan kompunentu gdy serwer zwrócił błąd
 * @type {string}
 */
const modal_form_state_error = 'error';

/**
 * Stan kompunentu gdy serwer zwrócił sukces
 * @type {string}
 */
const modal_form_state_success = 'success';

/**
 * Stan kompunentu gdy modal jest zamknięty
 * @type {string}
 */
const modal_form_state_close = 'close';

/**
 * Stan kompunentu gdy modal jest otwarty
 * @type {string}
 */
const modal_form_state_open = 'open';

export default {
  name: "ComponentModalFormStateMachine",
  extends: ComponentStateMachine,
  data() {
    return {
      errorMessage: "",
      is_show: false,
      states: {
        modal_form_state_close: modal_form_state_close,
        modal_form_state_open: modal_form_state_open,
        modal_form_state_waiting: modal_form_state_waiting,
        modal_form_state_loading: modal_form_state_loading,
        modal_form_state_error: modal_form_state_error,
        modal_form_state_success: modal_form_state_success,
      },

      state: modal_form_state_close,
      state_transitions: {
        [modal_form_state_close]: {
          'openModal': modal_form_state_waiting,
          'sendInfo': modal_form_state_loading,
        },
        [modal_form_state_waiting]: {
          'sendInfo': modal_form_state_loading,
          'closeModal': modal_form_state_close
        },
        [modal_form_state_loading]: {
          'sendSuccess': modal_form_state_success,
          'sendWrong': modal_form_state_error
        },
        [modal_form_state_error]: {
          'sendInfo': modal_form_state_loading,
          'closeModal': modal_form_state_close
        },
        [modal_form_state_success]: {
          'sendInfo': modal_form_state_loading,
          'closeModal': modal_form_state_close
        },

        [modal_form_state_open]: {
          'closeModal': modal_form_state_close
        },
      }
    }
  },
}
</script>