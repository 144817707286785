<template>

  <div class="table-block w-100">
    <table class="table dataTable no-footer dtr-column table-striped table-hover mb-2">
      <thead class="table__thead" v-if="head_show">
      <tr class="table__thead-tr">
        <th class="table__thead-th column-check position-relative" v-if="select_row_show">
          <input-checkbox name="select-all" :checked="isSelectAll" @updateValue="updateSelectAll"/>
        </th>
        <th class="table__thead-th column-number position-relative" v-if="number_show">
          {{number_name}}
        </th>
        <th :class="`table__thead-th position-relative column-${column.key}`" v-for="(column, code) in columns"
            v-show="column.show === undefined || column.show === true"
            @click="this.$emit('updateSort', column.key, column.type)">

          <div class="table-sort" v-if="sort === code">
            <img class="table-sort__img" src="@/assets/img/dart_fill.svg" alt="" :class="{
              'table-sort__img--rotate': sort_type === 'DESC'
            }"/>
          </div>
          {{ column.label }}
        </th>
        <th class="table__thead-th column-buttons position-relative" :title="sort+` `+sort_type"
            v-if="select_column_show">
          <img src="@/assets/img/columns.svg" alt="" @click="openOptions" style="float:right; margin: 0 4px;">
        </th>
      </tr>
      </thead>
      <tbody class="table__tbody">
      <tr v-for="(obj,key,index) in objs" class="obj-table-row" :style="row_style">

        <td class="obj-table-row__check" v-if="select_row_show">
          <input-checkbox :name="obj.id" :value="obj.check" @updateValue="(name,value)=>{updateCheck(name, value) } "/>
        </td>
        <td class="obj-table-row__number" v-if="number_show">
          {{number_start+parseInt(key)+1}}
        </td>

        <slot :obj="obj" :columns="columnsShowArr" name="default"/>
      </tr>
      <tr><slot name="form"></slot></tr>
      </tbody>
      <tfoot>
      <slot name="foot"></slot>
      </tfoot>
    </table>

    <teleport to="body">
      <TableOptions :columns="columns" v-on:updateColumns="updateColumns" ref="columns_menu"/>
    </teleport>
  </div>

</template>

<script>
import TableOptions from "@/Library/Show/TableOptions";
import InputCheckbox from "@/Library/Forms/InputCheckbox";

export default {
  name: "Table",
  components: {InputCheckbox, TableOptions},
  props: {
    objs: {},
    columns: {},
    sort: {},
    sort_type: {},
    storage_prefix: {},
    head_show:  {
      default() {
        return true;
      }
    },
    number_show: {
      default() {
        return true;
      }
    },
    number_start: {
      default() {
        return 0;
      }
    },
    number_name: {
      default() {
        return 'Lp';
      }
    },
    number_column_name: {
      default() {
        return 'number';
      }
    },
    select_row_show: {
      default() {
        return false;
      }
    },
    select_row_column_name: {
      default() {
        return 'check';
      }
    },
    select_column_show: {
      default() {
        return true;
      }
    },
    row_style: {
      default() {
        return '';
      }
    }
  },
  emits: ['updateSort','updateCheck'],
  data: () => ({

  }),
  computed: {
    columnsCount(){

    },
    columnsShowArr() {

      return this.columnsShow();
    },
    isSelectAll() {

      let select_row_column_name = this.select_row_column_name;

      let count_all = this.objs.length;
      let count_select = this.objs.filter((value)=>{
        if(value[select_row_column_name] === true)
          return value;
      }).length;

      return count_all === count_select;

    }
  },
  methods: {

    updateCheck(id, value) {

      console.log(id, value)

      for(let obj of this.objs){

        if(obj.id === id){

          obj[this.select_row_column_name] = value;
          this.$emit('updateCheck', id, value)
        }

      }

    },
    selectAll() {

      let select_row_column_name = this.select_row_column_name;

      this.objs.map((value)=>{
        value[select_row_column_name] = true;
      });

    },
    unselectAll() {

      let select_row_column_name = this.select_row_column_name;

      this.objs.map((value)=>{
        value[select_row_column_name] = false;
      });
    },

    updateSelectAll(name, value) {

      if (value === true) {
        this.selectAll();
      }
      if (value === false) {
        this.unselectAll();

      }

    },

    storageName(column_name) {

      let storage_prefix = this.storage_prefix ? this.storage_prefix + '_' : '';

      let name = 'column_'

      return storage_prefix + name + column_name;

    },

    columnsShow() {

      let ret = [];

      // let codes = Object.keys(this.columns);


      this.columns.map(function (column, idx) {
        if (column.show === true)
          ret.push(column.key);
      })

      if (this.select_column_show === false) {
        delete ret['buttons'];
      }

      return ret;

    },
    openOptions: function (e) {

      let target = e.target;

      let columns_menu = this.$refs.columns_menu;

      columns_menu.openBottomElement(target);

    },
    updateColumns: function (new_columns) {
      let self = this;

      Object.assign(this.columns, new_columns);
      this.columns.map(function (column) {
        let storage_name = self.storageName(column.key);
        localStorage.setItem(storage_name, column.show)
      });

    }
  },
  mounted() {
    let self = this;

    // let columns_key = Object.keys(this.columns);
    // let columns_val = Object.values(this.columns);
    this.columns.map(function (column, index) {

      let column_name = column.key
      let storage_name = self.storageName(column_name);

      let state = true;
      let default_state = null;
      let storage_state = null;

      if (column.default_show !== undefined)
        default_state = column.default_show

      if (localStorage.getItem(storage_name))
        storage_state = (localStorage.getItem(storage_name)) === 'true';

      if (default_state !== null)
        state = default_state;
      if (storage_state !== null)
        state = storage_state;

      column.show = state;

    })

  }

}
</script>

<style scoped lang="scss">

thead {

  //position: sticky;
  //top: 100px; /* Don't forget this, required for the stickiness */
}

.table-sort {
  position: absolute;

  background-color: #fff;

  right: 0;
  top: 50%;

  transform: translateY(-50%);

  &__img {
    float: right;
    padding: 6px;
    transform: rotate(180deg);

    &--rotate {
      transform: rotate(0deg);
    }
  }
}

.column{
  &-check{
    width: 40px;
  }
  &-number{
    width: 40px;

  }
}

</style>