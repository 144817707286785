<script>
import Core_Url from "../Helpers/url";
import ComponentListStateMachine from "./ComponentListStateMachine";
import View from "./View";

export default {
  name: "ViewList",
  extends: View,
  mixins: [ComponentListStateMachine],
  data() {
    return {
      storage_prefix: "",
      objs: [],
      pars: {},
      type: "list",
      sort: "id",
      sort_type: "DESC",
      sort_method: 'Date',
      page: 1,
      page_count: 20,
      count: 0,
      refresh_silent: false,
    }
  },
  computed: {
    numberStart() {
      return (this.page - 1) * this.page_count;
    }
  },
  methods: {

    setPars: function (pars) {
      delete pars['page'];

      this.pars = pars;
    },

    setPage: function (page) {
      this.page = parseInt(page);
    },

    setType: function (type) {
      this.type = type;
      this.storageSet('show_type', type);
    },

    setSort: function (sort, sort_method) {

      if (this.sort === sort) {
        this.sort_type = this.sort_type === 'ASC' ? 'DESC' : 'ASC';
      }

      this.sort = sort;
      this.sort_method = sort_method;

      this.storageSet('sort', this.sort);
      this.storageSet('sort_type', this.sort_type);
      this.storageSet('sort_method', this.sort_method);

    },
    setUri: function () {

      let params = this.pars;

      if (this.page)
        params.page = this.page;

      let uri = Object
          .keys(params)
          .map(value => `${value}=${encodeURIComponent(params[value])}`)
          .join('&');

      let url = `${this.$route.path}?${uri}`

      this.$router.push(url)

    },

    loadUri: function (query) {


      query = JSON.parse(JSON.stringify(query));

      let page = query.page ? query.page : 1;

      this.setPage(page);
      this.setPars(query);
      this.setFilter(query);

      this.refresh();

    },

    async load() {
      await this.refresh();
    },

    async loadSilent() {

      this.refresh_silent = true;

      await this.load();

      this.refresh_silent = false;

    },

    updatePars: function (pars) {
      this.setPage(1);
      this.setPars(pars);
      this.setUri()
      this.refresh();
    },
    updatePage: function (page) {
      this.setPage(page)
      this.setUri()
      this.refresh();
    },
    updatePageCount(page_count) {
      this.page_count = page_count;
      this.refresh();
    },
    updateType: function (type) {
      this.setType(type)
    },
    updateSort: function (sort, sort_method) {
      this.setSort(sort, sort_method);
      this.setPage(1);
      this.refresh();
    },

    stateLoading() {
      return this.stateCompare('loading') && this.refresh_silent === false;
    },

    setFilter: function (pars) {
      if (this.$refs.filter)
        this.$refs.filter.setValueFromUri(pars);
    },

    changeFiltr: function (pars) {

      this.setPage(1)
      this.setPars(pars)
      this.setUri(pars)

      this.refresh();
    },

    refresh: function () {

    },

    viewMounted() {

      this.observeAdd('refresh', 'loadSilent');

      this.type = this.storageGet('show_type', 'list');
      this.sort = this.storageGet('sort', 'create_date');
      this.sort_type = this.storageGet('sort_type', 'DESC');
      this.sort_method = this.storageGet('sort_method', 'Date');

      let urlPars = Core_Url.getAllPars(window.location.href);

      this.loadUri(urlPars);

    },

    /**
     * Akcja związana z odmonotwaniem
     */
    viewUnmounted() {
      this.observeRemoveAll();
    }

  },
  mounted() {
    this.viewMounted();
  },
  unmounted() {
    this.viewUnmounted();
  },
}
</script>

<style scoped>

</style>