<template>

  <div class="access-form">
    <div class="row-nice mb-1">
      <Row type="autocomplete" :value.sync="group" :value_show.sync="group_show" url="/usergroup/get"
           url_parameter_name="search_text"
           :input_id="inputId"
           v-on:updateValue="updateAutocompleteValue" label="Grupa"
           ref="groupInput"/>
    </div>

    <InputToggle class="mb-1" v-for="(permission_value, permission_key) in permissions" :label="permission_value"
                 :name="permission_key" :value_input="'yes'" :value_input_false="'no'" :value="access[permission_key]"
                 v-on:updateValue="(name, value) => { access[name] = value }"/>
    <div class="clearfix"/>
    <button class="btn btn-success w-100" @click="accessAdd">Dodaj</button>
  </div>
</template>

<script>
import InputAutocomplete from "@/Library/Forms/InputAutocomplete";
import InputToggle from "@/Library/Forms/InputToggle";
import axios from "@/axios";
import Row from "@/Library/Forms/Row";

export default {
  name: "SystemAccessObjGroupManageAdd",
  components: {Row, InputToggle, InputAutocomplete},
  props: {
    'permissions': {
      type: Object,
      default() {
        return {}
      },
    },
    'id_obj': {
      type: String,
      default() {
        return null
      },
    },
  },
  emits: ['accessAdd'],
  data: () => ({
    'input_id': null,
    'group': "",
    'group_show': "",
    'access': {},
  }),
  computed: {
    inputId() {
      return this.input_id ? this.input_id : 'input' + parseInt(Math.random() * 99999);
    }
  },
  methods: {
    updateAutocompleteValue(name, value, label) {
      this.group = value;
      this.group_show = label;
    },

    async accessAdd(e) {

      e.preventDefault();

      /**
       * Wyjście z trybu edycji
       */
      this.type = 'show'

      /**
       * Uprawenienia do wysłania
       */
      let access_to_send = [];

      /**
       * Przejście po wszystkich uprawnieniach i przypisanie odpowiedniego statusu
       */
      for (let [key, access] of Object.entries(this.access)) {
        access_to_send.push({
          id_obj: this.id_obj,
          id_user_group: this.group,
          status: access === 'yes' ? 'yes' : 'no',
          privileges: key
        })
      }

      /**
       * Wysłanie danych
       */
      await axios
          .post('useraccess/set', {access: access_to_send})
          .catch(error => {
          })

      /**
       * Wyczyszczenie formularza
       */
      this.clear();

      this.$emit('accessAdd', access_to_send);
    },

    clear() {
      this.group = null;
      this.group_show = null;

      this.$refs.groupInput.clear();

      let keys = Object.keys(this.permissions);
      let count = keys.length;
      this.access = [];
      for (let i = 0; i < count; i++)
        this.access[keys[i]] = 'no';
    },
  },

  mounted() {
    this.clear();
  }
}
</script>

<style scoped lang="scss">

.access-form {
  background-color: #fafafa;

  padding: 20px;

  border-radius: 6px;
}

</style>