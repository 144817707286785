<template>
  <InputToggle
      ref="input"
      :id="this.id"
      :name="this.name"
      :label="this.label"
      :class="this.class"
      :value="this.value"
      :value_input="'yes'"
      :value_input_false="'no'"
      @updateValidate="updateValidate"
      @updateValue="updateValue"
      :validate-required="this.validateRequired"
  />
</template>

<script>
import InputBase from "./InputBase";
import InputToggle from "@/Library/Forms/InputToggle";

export default {
  name: "InputYesNo",
  components: {InputToggle},
  extends: InputBase,
  emits: ['update', 'updateValue'],
  props: {
    'id': {
      default() {
        return 'input-'+parseInt(Math.random()*10000000000);
      }
    },
    'name': {},
    'class': {},
    'value': {},
    'value_input': {
      default() {
        return true;
      }
    },
    'value_input_false': {
      default() {
        return false;
      }
    },
    'label': {},
    'checked': {}
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {

    input: function () {
      return this.$refs.input;
    },
    updateValidate: function (validate_arr) {

      this.$emit('updateValidate', validate_arr)

    },

    updateValue: function (name, value, value_show) {

      let input = this.input();

      this.$emit('updateValue', this.name, value, value_show);

      if (this.$parent.valid_test === true) {
        let self = this;
        setTimeout(function (e) {
          input.validate();
        }, 10)
      }
    },
    change() {

      let show = this.checked ? this.label : "";

      let ret = this.isChecked ? this.value_input : this.value_input_false;

      this.$emit('update', this.checked)
      this.$emit('updateValue', this.name, ret, show)
    },
    isCheckedUpdate() {
      this.isChecked = false;
      if (this.value === this.value_input)
        this.isChecked = true;
      if (this.checked)
        this.isChecked = true;


    },
  },
  computed: {

  },
  mounted() {
    this.isCheckedUpdate();

  },

  watch: {
    value(newValue, oldValue) {

      this.isCheckedUpdate();

    },
    value_input(newValue, oldValue) {

      this.isCheckedUpdate();

    },
    checked(newValue, oldValue) {

      this.isCheckedUpdate();

    },
  }
}
</script>

<style scoped lang="scss">
.input-toggle {
  position: relative;
  display: flex;
  //margin: 10px 0;
  cursor: pointer;


  &__item {
    border: 0.5px solid rgba(117, 117, 117, 0.31);
    position: relative;

    &:before {
      border: none;
      width: 2.5em;
      height: 2.5em;
      background: #fff;
      transform: rotate(-25deg);
      content: "";
      position: absolute;
    }

    &:after {
      background: transparent;
      height: calc(100% + 8px);
      border-radius: 30px;
      top: -5px;
      width: calc(100% + 8px);
      left: -4px;
      z-index: 0;
      content: "";
      position: absolute;
    }
  }

  &__input {
    height: 40px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 40px;

    &:checked + label {
      background: #3ea5fe;
    }

    &:checked + label:before {
      left: 23px !important;
    }
  }

  &__item {
    width: 3em;
    min-width: 3em;
    background: #2e394d;
    height: 1.5em;
    display: inline-block;
    border-radius: 50px;
    position: relative;
    transition: all 0.3s ease;
    transform-origin: 20% center;
    cursor: pointer;
    margin-right: 8px;

    &:before {
      content: "";
      display: block;
      transition: all 0.2s ease;
      width: 1em;
      height: 1em;
      top: 0.23em;
      left: 0.25em;
      border-radius: 2em;
      transition: 0.3s ease;
    }
  }

  &__name {
    text-align: left;

    float: left;
  }

}

</style>