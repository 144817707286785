import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/test',
        name: 'Test',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Base/Test/Test')
        }
    },
    {
        path: '/condition',
        name: 'Condition',
        meta: {
            'layout': 'Blank',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Base/Condition.vue')
        }
    },
    {
        path: '/',
        name: 'Home',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/HomeView')
        }
    },
    {
        path: '/account',
        name: 'UserAccount',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Authentication/Account')
        }
    },
    {
        path: '/account/editdata',
        name: 'UserAccountEditData',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Authentication/AccountEditdata.vue')
        }
    },
    {
        path: '/account/editpass',
        name: 'UserAccountEditPass',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Authentication/AccountEditpass.vue')
        }
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            layout: 'Login',
            requiresNoAuth: true
        },
        component() {
            return import('@/views/Authentication/Login.vue')
        }
    },
    {
        path: '/auth/logout',
        name: 'logout',
        meta: {
            layout: 'Login',
            requiresAuth: true
        },
        component() {
            return import('@/views/Authentication/Logout.vue')
        }
    },
    {
        path: '/recover',
        name: 'Recover',
        meta: {
            layout: 'Login',
            requiresNoAuth: true
        },
        component() {
            return import('@/views/Authentication/Recover.vue')
        }
    },
    {
        path: '/config',
        name: 'Config',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Config/Config.vue')
        }
    },
    {
        path: '/system',
        name: 'System',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/System/System.vue')
        }
    },
    {
        path: '/config/test',
        name: 'BaseTest',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Test.vue')
        }
    },
    {
        path: '/domain/config',
        name: 'DomainConfig',
        meta: {
            'layout': 'Domain',
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Domain/Config/Config.vue')
        }
    },
    {
        path: '/domain/deleted',
        name: 'DomainDeleted',
        meta: {
            'layout': 'Domain',
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Domain/Domain/Deleted.vue')
        }
    },
    {
        path: '/domain',
        name: 'DomainList',
        meta: {
            'layout': 'Domain',
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Domain/Domain/Domain')
        }
    },
    {
        path: '/domain/new',
        name: 'DomainNew',
        meta: {
            'layout': 'Domain',
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Domain/Domain/New')
        }
    },
    {
        path: '/domain/:id/edit',
        props: true,
        name: 'DomainEdit',
        meta: {
            'layout': 'Domain',
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Domain/Domain/Edit')
        }
    },
    {
        path: '/domain/:id',
        props: true,
        name: 'DomainShow',
        meta: {
            'layout': 'Domain',
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Domain/Domain/Show')
        }
    },

    /**
     * DomainRequest
     */
    {
        path: '/domain/request',
        name: 'DomainRequestList',
        meta: {
            layout: 'Domain',
            requiresAuth: true
        },
        component() {
            return import('@/views/Domain/Request/List')
        }
    },
    {
        path: '/domain/request/:id',
        name: 'DomainRequestShow',
        props: true,
        meta: {
            layout: 'Domain',
            requiresAuth: true
        },
        component() {
            return import('@/views/Domain/Request/Show')
        }
    },
    {
        path: '/domain/request/:id/edit',
        name: 'DomainRequestEdit',
        props: true,
        meta: {
            layout: 'Domain',
            requiresAuth: true
        },
        component() {
            return import('@/views/Domain/Request/Edit')
        }
    },
    {
        path: '/domain/request/new',
        name: 'DomainRequestNew',
        meta: {
            layout: 'Domain',
            requiresAuth: true
        },
        component() {
            return import('@/views/Domain/Request/New')
        }
    },
    {
        path: '/system/mail/:id',
        props: true,
        name: 'MailShow',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Mail/Show')
        }
    },
    {
        path: '/system/mail',
        props: true,
        name: 'Mail',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Mail/List')
        }
    },
    {
        path: '/config/mail/',
        name: 'MailConfig',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Mail/Config.vue')
        }
    },
    {
        path: '/site/text',
        name: 'Site',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Text/List')
        }
    },
    {
        path: '/site/text/:id',
        name: 'SiteShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Text/Show')
        }
    },
    {
        path: '/site/text/:id/edit',
        name: 'SiteEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Text/Edit')
        }
    },
    {
        path: '/site/text/new',
        name: 'SiteNew',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Text/New')
        }
    },
    {
        path: '/site/menu',
        name: 'MenuList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/List')
        }
    },
    {
        path: '/site/menu/:id',
        name: 'MenuShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/Show')
        }
    },
    {
        path: '/site/menu/pos/:id',
        name: 'MenuPosShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/ShowPosition')
        }
    },
    {
        path: '/site/menu/:id/edit',
        name: 'MenuEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/Part/Form')
        }
    },
    {
        path: '/site/menu/new',
        name: 'MenuNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/Part/Form')
        }
    },
    {
        path: '/site/section',
        name: 'SectionList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Section/List')
        }
    },
    {
        path: '/site/section/:id',
        name: 'SectionShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Section/Show')
        }
    },

    /**
     * Newsletter
     */

    {
        path: '/newsletter',
        name: 'Newsletter',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Newsletter/List')
        }
    },
    {
        path: '/newsletter/:id',
        name: 'NewsletterShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Newsletter/Show')
        }
    },
    {
        path: '/newsletter/:id/edit',
        name: 'NewsletterEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Newsletter/Edit')
        }
    },
    {
        path: '/newsletter/new',
        name: 'NewsletterNew',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Newsletter/New')
        }
    },

    /*Mieszkańcy*/
    {
        path: '/article/:type',
        name: 'ArticleList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Article/List')
        }
    },
    {
        path: '/article/:type/:id',
        name: 'ArticleShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Article/Show')
        }
    },
    {
        path: '/article/:type/:id_article/:id',
        name: 'ArticleVersion',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Version/Show.vue')
        }
    },
    {
        path: '/article/:type/:id/edit',
        name: 'ArticleEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Article/Edit')
        }
    },
    {
        path: '/article/:type/new',
        name: 'ArticleNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Article/New')
        }
    },
    {
        path: '/article/:type/category',
        name: 'ArticleCategoryList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Category/List')
        }
    },
    {
        path: '/article/:type/category/:id',
        name: 'ArticleCategoryShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Category/Show')
        }
    },
    {
        path: '/article/:type/config',
        name: 'ArticleSettings',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Config/Config')
        }
    },

    /*Miejsca*/
    {
        path: '/place',
        name: 'PlaceList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/List')
        }
    },
    {
        path: '/place/:id',
        name: 'PlaceShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/Show')
        }
    },
    {
        path: '/place/:id/edit',
        name: 'PlaceEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/Edit')
        }
    },
    {
        path: '/place/new',
        name: 'PlaceNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/New')
        }
    },
    {
        path: '/place/category',
        name: 'PlaceCategoryList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Category/List')
        }
    },
    {
        path: '/place/category/:id',
        name: 'PlaceCategoryShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Category/Show')
        }
    },
    {
        path: '/place/config',
        name: 'PlaceSettings',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/Config')
        }
    },
    {
        path: '/place/import',
        name: 'PlaceImport',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/Import.vue')
        }
    },

    /*Trasy*/
    {
        path: '/place/route',
        name: 'RouteList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Route/List')
        }
    },
    {
        path: '/place/route/:id',
        name: 'RouteShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Route/Show')
        }
    },
    {
        path: '/place/route/:id/edit',
        name: 'RouteEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Route/Edit')
        }
    },
    {
        path: '/place/route/new',
        name: 'RouteNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Route/New')
        }
    },


    /*Trasy*/
    {
        path: '/config/template',
        name: 'TemplateList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Template/Template/List.vue')
        }
    },
    {
        path: '/config/template/show/:id',
        name: 'TemplateShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Template/Template/Show.vue')
        }
    },
    // {
    //     path: '/config/template/:id/edit',
    //     name: 'TemplateEdit',
    //     props: true,
    //     meta: {
    //         layout: 'Standard',
    //         requiresAuth: true
    //     },
    //     component() {
    //         return import('@/views/Base/Template/Edit')
    //     }
    // },
    // {
    //     path: '/config/template/new',
    //     name: 'TemplateNew',
    //     props: true,
    //     meta: {
    //         layout: 'Standard',
    //         requiresAuth: true
    //     },
    //     component() {
    //         return import('@/views/Base/Template/New')
    //     }
    // },
    /*{
      path: '/place/category',
      name: 'PlaceCategoryList',
      meta: {
        layout: 'Standard',
        requiresAuth: true
      },
      component() {
        return import('@/views/Place/Category/List')
      }
    },
    {
      path: '/place/category/:id',
      name: 'PlaceCategoryShow',
      props: true,
      meta: {
        layout: 'Standard',
        requiresAuth: true
      },
      component() {
        return import('@/views/Place/Category/Show')
      }
    },
    {
      path: '/place/settings',
      name: 'PlaceSettings',
      props: true,
      meta: {
        layout: 'Standard',
        requiresAuth: true
      },
      component() {
        return import('@/views/Place/Place/Settings')
      }
    },*/

    /*Użytkownicy*/


    {
        path: '/user',
        name: 'UserList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/List')
        }
    },
    {
        path: '/user/:id',
        name: 'UserShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/Show')
        }
    },
    {
        path: '/user/:id/edit',
        name: 'UserEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/Edit')
        }
    },
    {
        path: '/user/new',
        name: 'UserNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/New')
        }
    },
    {
        path: '/user/config',
        name: 'UserConfig',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/Config')
        }
    },


    /*Grupy*/
    {
        path: '/user/group',
        name: 'GroupList',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/List')
        }
    },
    {
        path: '/user/group/new',
        name: 'GroupNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/Form.vue')
        }
    },
    {
        path: '/user/group/:id',
        name: 'GroupShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/Show')
        }
    },
    {
        path: '/user/group/:id/edit',
        name: 'GroupEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/Form.vue')
        }
    },
    /*Role*/
    {
        path: '/user/role',
        name: 'RoleList',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Role/List.vue')
        }
    },
    {
        path: '/user/role/new',
        name: 'RoleNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Role/Form.vue')
        }
    },
    {
        path: '/user/role/:id',
        name: 'RoleShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Role/Show.vue')
        }
    },
    {
        path: '/user/role/:id/edit',
        name: 'RoleEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Role/Form.vue')
        }
    },

    /*Języki*/
    {
        path: '/lang',
        name: 'LangList',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Lang/List')
        }
    },
    {
        path: '/system/log',
        name: 'SystemLog',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Log/Page')
        }
    },
    {
        path: '/system/qr',
        name: 'SystemQR',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Qr/List.vue')
        }
    },
    {
        path: '/system/banner',
        name: 'SystemSiteBanner',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Banner/Banner/List.vue')
        }
    },
    {
        path: '/system/banner/new',
        name: 'SystemSiteBannerNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Banner/Banner/New.vue')
        }
    },
    {
        path: '/system/banner/:id',
        name: 'SystemSiteBannerShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Banner/Banner/Show')
        }
    },
    {
        path: '/system/banner/:id/edit',
        name: 'SystemSiteBannerEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Banner/Banner/Edit')
        }
    },


    {
        path: '/system/bannergroup',
        name: 'SystemSiteBannerGroup',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Banner/Group/List.vue')
        }
    },
    {
        path: '/system/bannergroup/new',
        name: 'SystemSiteBannerGroupNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Banner/Group/New.vue')
        }
    },
    {
        path: '/system/bannergroup/:id',
        name: 'SystemSiteBannerGroupShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Banner/Group/Show')
        }
    },
    {
        path: '/system/bannergroup/:id/edit',
        name: 'SystemSiteBannerGroupEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Banner/Group/Edit')
        }
    },

    /*Pliki*/
    {
        path: '/file',
        name: 'File',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/File/File')
        }
    },

    /*Sensory*/
    {
        path: '/sensor',
        name: 'SensorList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/List')
        }
    },
    {
        path: '/sensor/:id',
        name: 'SensorShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/Show')
        }
    },
    {
        path: '/sensor/:id/edit',
        name: 'SensorEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/Edit')
        }
    },
    {
        path: '/sensor/new',
        name: 'SensorNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/New')
        }
    },
    {
        path: '/sensor/category',
        name: 'SensorCategoryList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Category/List')
        }
    },
    {
        path: '/sensor/category/:id',
        name: 'SensorCategoryShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Category/Show')
        }
    },
    {
        path: '/sensor/config',
        name: 'SensorSettings',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/Config')
        }
    },

    /* Zgłoaszenia */

    {
        path: '/ticket/board',
        name: 'BoardsList',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/List.vue')
        }
    },
    {
        path: '/ticket/board/:id',
        name: 'BoardsShow',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/Show.vue')
        }
    },
    {
        path: '/ticket/board/:id/config',
        name: 'BoardConfig',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/Config.vue')
        }
    },
    {
        path: '/ticket/board/:id/all',
        name: 'BoardsTicketsList',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/Tickets.vue')
        }
    },
    {
        path: '/ticket/board/:id/wait',
        name: 'BoardsTicketsWait',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/Wait.vue')
        }
    },
    {
        path: '/ticket/new',
        name: 'TicketsNew',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Modal/New.vue')
        }
    },
    {
        path: '/ticket/new-out',
        name: 'TicketsNewOut',
        meta: {
            'layout': 'blank',
        },
        component: function () {
            return import('@/views/Ticket/Ticket/NewOut.vue')
        }
    },
// {
//     path: '/ticket/archive',
//     name: 'TicketsArchive',
//     meta: {
//         'requiresAuth': true
//     },
//     component: function () {
//         return import('@/views/Ticket/Ticket/Archive.vue')
//     }
// },
    {
        path: '/ticket/all',
        name: 'TicketsAll',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/List.vue')
        }
    },
    {
        path: '/ticket/wait',
        name: 'TicketsWait',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Wait.vue')
        }
    },
    {
        path: '/ticket/own',
        name: 'TicketsOwn',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Own.vue')
        }
    },
    {
        path: '/ticket/own/all',
        name: 'TicketsOwnAll',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/OwnAll.vue')
        }
    },
    {
        path: '/ticket',
        name: 'TicketsCurrent',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Current.vue')
        }
    },
    {
        path: '/ticket/other',
        name: 'TicketsOther',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Other.vue')
        }
    },
    {
        path: '/ticket/explorer',
        name: 'TicketsCurrentExplorer',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/CurrentExplorer.vue')
        }
    },
    {
        path: '/ticket/map',
        name: 'TicketsMap',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Map')
        }
    },
    {
        path: '/ticket/:id',
        name: 'TicketsShow',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Show.vue')
        }
    },
    {
        path: '/config/ticket/',
        name: 'TicketConfig',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Config/Config.vue')
        }
    },


    /**
     * Ankiety
     */
    {
        path: '/poll',
        name: 'PollList',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/List')
        }
    },
    {
        path: '/poll/new',
        name: 'PollNew',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/New')
        }
    },
    {
        path: '/poll/:id',
        name: 'PollShow',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Show')
        }
    },
    {
        path: '/poll/:id/edit',
        name: 'PollEdit',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Edit')
        }
    },
    {
        path: '/poll/:id/results',
        name: 'PollResults',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Results')
        }
    },
    {
        path: '/poll/:id/answers',
        name: 'PollAnswerList',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Answer/List')
        }
    },
    {
        path: '/poll/:id/answer/:id_poll_fill',
        name: 'PollAnswerShow',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Answer/Show')
        }
    },

    /**
     * PortalTeam
     */
    {
        path: '/portal/team/',
        name: 'PortalTeamList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Portal/Team/List')
        }
    },
    {
        path: '/portal/team/:id',
        name: 'PortalTeamShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Portal/Team/Show')
        }
    },
    {
        path: '/portal/team/:id/edit',
        name: 'PortalTeamEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Portal/Team/Edit')
        }
    },
    {
        path: '/portal/team/new',
        name: 'PortalTeamNew',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Portal/Team/New')
        }
    },

    /**
     * Raporty
     */
    {
        path: '/raport',
        name: 'RaportList',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Raport/Raport/List')
        }
    },
    {
        path: '/raport/:id',
        name: 'RaportShow',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Raport/Raport/Show')
        }
    },


    {
        path: '/config/raport',
        name: 'RaportConfig',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Raport/Config/Config.vue')
        }
    },
    {
        path: '/config/raport/new',
        name: 'RaportConfigNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Raport/Config/New.vue')
        }
    },
    {
        path: '/config/raport/:id',
        name: 'RaportConfigShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Raport/Config/Show.vue')
        }
    },
    {
        path: '/config/raport/:id/edit',
        name: 'RaportConfigEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Raport/Config/Edit.vue')
        }
    },



    /**
     * Statystyki
     */
    {
        path: '/statistic',
        name: 'StatisticStatistic',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Statistic/Statistic')
        }
    },
    {
        path: '/statistic/show/path/:path',
        name: 'StatisticShow',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Statistic/Show')
        }
    },
    {
        path: '/statistic/visit',
        name: 'StatisticVisitVisit',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Statistic/Visit/Visit')
        }
    },
    /**
     * Page
     */
    {
        path: '/page/',
        name: 'PageList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Page/List')
        }
    },
    {
        path: '/page/:id',
        name: 'PageShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Page/Show')
        }
    },
    {
        path: '/page/:id/edit',
        name: 'PageEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Page/Edit')
        }
    },
    {
        path: '/page/new',
        name: 'PageNew',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Page/New')
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {


    /**
     * Określa czy element docelowy wymaga autoryzacji
     * @type {boolean}
     */
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    /**
     * Określa czy element docelowy jest dostępny tylko bez autoryzacji
     * @type {boolean}
     */
    const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth);

    /**
     * Wartość tokena z localStorage
     * @type {string}
     */
    const token = localStorage.getItem('token');

    console.log(requiresAuth, token)

    /**
     * Jeśli strona wymaga autoryzacji a obecnie jej nei ma przenosi do logowania
     */
    if (requiresAuth && !token) {
        next('/login');
    }

    /**
     * Jeśli strona nie chce autoryzowanych użytkowników a obecnie jest, przejdź moje konto
     */
    else if (requiresNoAuth && token) {
        next('/');
    }

    /**
     * W innym wypadku przejdź tam gdzie szedłeś
     */
    else {
        next();
    }
});

router.afterEach((to, from) => {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})

export default router