<template>
  <span :class="'badge badge-soft-'+status_type"> {{ texts[status_type][status] }} </span>
</template>

<script>

export default {
  name: "ShowStatus",
  props: ['status'],
  data: () => ({
    texts: {
      danger: {
        dead: 'Nie aktywny',
        'not-active': 'Nie aktywny',
        'no-accept': 'Nie zaakceptowano',
        reject: 'Odrzucone',
        rejected: 'Odrzucone',
        delivered: 'Dostarczone',
        paid: 'Zapłacona',
        bad: 'Źle',
        delete: 'Usunięty'
      },

      success: {
        live: 'Aktywny',
        active: 'Aktywny',
        accept: 'Zaakceptowane',
        accepted: 'Zaakceptowane',
        confirmed: 'Potwierdzone',
        close: 'Zamknięty',
        done: 'Wykonane',
        ordered: 'Zamówione',
        good: 'Dobrze',
        send: 'Wysłane',
        settled: 'Rozliczone',
        public: 'Publiczny'
      },

      primary: {
        open: 'Otwarty',
        save: 'Zapisane',
        working: 'Robocze',
        construct: 'Konstrukcje',
        verification: 'W trakcie weryfikacji',
        writing: 'W trakcie pisania',
      },

      info: {
        create: 'Utworzono',
        selecting: 'W trakcie wyboru',
        waiting: 'Oczekujące',
        correction: 'Korekcja',
        revieving: 'W trakcie recenzji',
        correcting: 'W trakcie korekty',
        need_confirm: 'Wymaga potwierdzenia',
        'not-need': 'Nie wymagane',
        planned: 'Zaplanowane',
        'not-checked': 'Nie wypełniono',
      },

      secondary: {
        undefined: 'Brak',
        planned: 'Planowane',
        acceptation: 'Akceptacja',
        confirmation: 'Potwierdzenie',
        archive: 'Archiwalny'
      }
    },
  }),
  computed: {
    status_type() {
      for (let [type,text] of Object.entries(this.texts))
      {
        if (Object.keys(text).includes(this.status))
          return type;
      }
      return 'secondary'
    },
  }
}
</script>