<template>
  <!-- Make sure you add the `ref` attribute, as that is what gives you the ability
  to open the menu. -->
  <teleport to="body">
    <VueSimpleContextMenu
        element-id="contextMenu2"
        :options="options"
        ref="vueSimpleContextMenu2"
        @option-clicked="actionClick"
    />
  </teleport>
</template>


<script>
import VueSimpleContextMenu from 'vue-simple-context-menu';

import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

export default {
  name: "ContextMenu",
  components: {VueSimpleContextMenu},
  data: () => ({
    options_source: [],
    observator: {},
    obj: {},
  }),
  methods: {
    actionClick(item) {

      this.observator.contextMenuClick(item)

    },
    open(event, observator, options, obj = null) {

      let contextMenu = this.$refs.vueSimpleContextMenu2;

      this.observator = observator;
      this.options_source = options;
      this.obj = obj;


      setTimeout(() => {
        let contectMenuDOM = document.getElementById('contextMenu2');

        contextMenu.menuHeight = contectMenuDOM.offsetHeight;
        contextMenu.menuWidth = contectMenuDOM.offsetWidth;

        this.$refs.vueSimpleContextMenu2.showMenu(event, obj)

      }, 100)
    }
  },
  computed: {
    /**
     * Dane, które są potrzebne do 'right-menu'
     * @returns {*[]}
     */
    options() {

      let options = [];

      this.options_source.map(function (element) {

        let element_new = Object.assign({}, element);

        if (element_new.icon !== undefined && element_new.color !== "red")
          element_new.name = '<span class="material-symbols-outlined menu-icons">' + element_new.icon + '</span> ' + '<span>' + element_new.name + '</span>';

        if (element_new.color !== undefined && element_new.color === "red")
          element_new.name = '<span class="material-symbols-outlined menu-icons" style="color: #FD7972">' + element_new.icon + '</span> ' + '<span style="color: #FD7972">' + element_new.name + '</span>';

        options.push(element_new)

      })

      return options
    }
  }
}
</script>

<style lang="scss">
.vue-simple-context-menu {
  background-color: #fff;
  border-bottom-width: 0px;
  border-radius: 0.728rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  display: none;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 1000000;
}

.vue-simple-context-menu--active {
  display: block;
}

.vue-simple-context-menu__item {
  align-items: center;
  font-size: 16px;
  color: #595F69;
  cursor: pointer;
  display: flex;
  padding: 10px 25px;

  min-width: 220px;

  & > svg {
    margin-right: 1rem;
    margin-bottom: 2px;
    display: block;
  }

  &.hide {
    display: none;
  }

  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    & > span:first-child {
      color: #8A9099;
    }
  }
}

.vue-simple-context-menu__item:hover {
  background-color: #f9f9f9;
  //color: #fff;
}

.vue-simple-context-menu__divider {
  background-clip: content-box;
  background-color: #E8E9EB;
  box-sizing: content-box;
  height: 1px;
  padding: 4px 0;
  pointer-events: none;
}

.vue-simple-context-menu li:first-of-type {
  margin-top: 0.7rem;
}

.vue-simple-context-menu li:last-of-type {
  margin-bottom: 0.7rem;
}

.menu-icons {
  font-size: 18px;
}
</style>