<script>
import {mapState} from "vuex";

export default {
  name: "MenuAbstract",

  computed: {
    ...mapState('app', ['menu','layoutType']),
    layoutType: {
      get() {
        return this.$store ? this.$store.state.app.layoutType : {} || {};
      },
    },
    positions() {

      let ret = [...this.menu]

      ret.push({
        code: 'menu',
        code_top: null,
        name: "Menu",
        link: null,
        icon: 'mdi mdi-cog',
      })

      ret.push({
        code: 'system',
        code_top: null,
        name: "System",
        link: '/system',
        icon_google: 'computer',
      })

      ret.push({
        code: 'config',
        code_top: null,
        name: "Ustawienia",
        link: '/config',
        icon_google: 'settings',
      })

      if (this.layoutType === 'horizontal'){

        ret.push({
          code: 'domain',
          code_top: 'menu',
          name: "Serwisy",
          link: '/domain',
          icon_google: 'globe_uk',
        })

      }

      return ret;
    },
    positionsTop() {
      return this.positions.filter(element => element.code_top === null)
    },
    positionHorizontal() {

      let has_system = this.positions.find(el => el.code_top === "system");
      let has_config = this.positions.find(el => el.code_top === 'config');

      let pos = this.positions.filter(element => element.code_top === 'menu' || has_system && element.code === 'system' || has_config && element.code === 'config');

      return pos;

    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {

              let siblingCollapses = closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  siblingCollapse.classList.remove("show");
                }
              });

            } else {

              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });

            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    }, 0);
  },
  methods: {

    menuCloseAll() {


      setTimeout(() => {
        let menu_down = document.querySelector('.menu-dropdown.show');

        if (menu_down) {
          menu_down.classList.remove('show');
        }

      }, 1000);
      setTimeout(() => {
        let menu_down = document.querySelector('.router-link-active');


        if (menu_down) {
          menu_down.classList.remove('router-link-active');
          menu_down.classList.remove('active');
        }
      }, 900);
      setTimeout(() => {
        let menu_down = document.querySelector('.menu-link.active');

        if (menu_down) {
          menu_down.classList.remove('active');
          menu_down.setAttribute("aria-expanded", false);
        }
      }, 1000);

    },

    onRoutechange(ele) {

      this.menuCloseAll();

      this.initActiveMenu(ele.path);
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition =
              document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop =
                  currentPosition + 300;
        }
      }, 300);
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
              .querySelector("#navbar-nav")
              .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                  "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                  "aria-expanded",
                  "true"
              );
              if (
                  parentCollapseDiv.parentElement.closest(
                      ".collapse.menu-dropdown"
                  )
              ) {
                parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .classList.add("show");
                if (
                    parentCollapseDiv.parentElement.closest(".collapse")
                        .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                      .closest(".collapse")
                      .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
}
</script>

<template>

</template>

<style scoped lang="scss">

</style>