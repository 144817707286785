<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" title="Kokpit"/>
              </span>
              <span class="logo-lg">
                <logotext/>
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22"/>
              </span>
              <span class="logo-lg">
                <logotext/>
              </span>
            </router-link>
          </div>

          <button
              type="button"
              class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
              title="Przycnisk menu"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <DomainSelect v-if="type === 'standard'"/>

        </div>

        <div class="d-flex align-items-center">

          <NavBarBtns/>

          <NavBarTools/>

          <NavBarUser/>

        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {SimpleBar} from "simplebar-vue3";
import Logotext from "@/layouts/part/LogoText";
import {mapActions} from "vuex";
import NavBarUser from "@/layouts/part/nav-bar-user";
import DomainSelect from "@/views/Domain/Domain/Part/Select.vue";
import NavBarTools from "@/layouts/part/nav-bar-tools.vue";
import NavBarBtns from "@/layouts/part/nav-bar-btns.vue";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      text: null,
      flag: null,
      value: null,
      myVar: 1,
    };
  },
  props: {
    type: {
      default(){
        return 'standard'
      }
    },
  },
  components: {NavBarBtns, NavBarTools, DomainSelect, NavBarUser, Logotext, SimpleBar},
  mounted() {
    if (document.getElementById("topnav-hamburger-icon"))
      document
          .getElementById("topnav-hamburger-icon")
          .addEventListener("click", this.toggleHamburgerMenu);

  },
  methods: {
    toggleHamburgerMenu() {
      let windowSize = document.documentElement.clientWidth;

      let documentDOM = document.documentElement;

      let bodyDOM = document.body;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
          documentDOM.getAttribute("data-layout") === "horizontal"
      ) {
        bodyDOM.classList.contains("menu")
            ? bodyDOM.classList.remove("menu")
            : bodyDOM.classList.add("menu");
      }

      //For collapse vertical menu
      if (documentDOM.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          bodyDOM.classList.remove("vertical-sidebar-enable");
          documentDOM.getAttribute("data-sidebar-size") === "sm"
              ? documentDOM.setAttribute("data-sidebar-size", "")
              : documentDOM.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          bodyDOM.classList.remove("vertical-sidebar-enable");
          documentDOM.getAttribute("data-sidebar-size") === "lg"
              ? documentDOM.setAttribute("data-sidebar-size", "sm")
              : documentDOM.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          bodyDOM.classList.add("vertical-sidebar-enable");
          documentDOM.setAttribute("data-sidebar-size", "lg");
        }
      }

    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },

  },
  computed: {},
};
</script>


<style lang="scss" scoped>

.user-internet {

  width: 7px;
  height: 7px;

  display: block;

  background-color: #fff;

  border-radius: 3px;

  position: absolute;
  top: 6px;
  left: 26px;

  border: 1px solid var(--sp-topbar-user-bg-dark);

  transition: background-color .3s;

  &--connect {
    background-color: limegreen;
  }

  &--no-connect {
    background-color: red;
  }
}

/*.navbar-header{*/
/*  background-color: var(--primary-color);*/
/*}*/

/*[data-topbar=dark] .topbar-user{*/
/*  background-color: var(--primary-color-darker) !important;*/

/*}*/


</style>