<script>

import Base from "./Base";
import axios from "@/axios";

export default {
    name: "View",
    extends: Base,

    data() {
        return {}
    },

    computed: {

        appUrl() {

            let link = axios.defaults.baseURL;

            let liinkArr = link.split('/');
            liinkArr.pop();
            liinkArr.pop();
            link = liinkArr.join('/') + '/';

            return link;
        },

        dataUrl() {

            return this.appUrl + 'data/';

        },

        uploadsUrl() {

            return this.appUrl + 'tmp/uploads/';

        },

    },

    methods: {
        app() {
            return this.$root.$refs.app;
        },

        storageSet: function (name, value) {
            let name_full = this.storage_prefix + '_' + name;

            localStorage.setItem(name_full, value);
        },

        storageGet: function (name, def) {
            let name_full = this.storage_prefix + '_' + name;

            let value = localStorage.getItem(name_full);

            value = value === null ? def : value;

            return value;
        },

        observeAdd: function (collection, method, data = {}) {

            // console.log('Dodane do obsewatora',collection)

            this.app().observeAdd(collection, this, method, data);

        },

        observeRemove: function (collection) {
            this.app().observeRemove(collection, this);
        },
        observeRemoveAll: function () {
            this.app().observeRemoveObj(this);
        },

        refresh: function () {

        },

        viewMounted() {


        },

        /**
         * Akcja związana z odmonotwaniem
         */
        viewUnmounted() {

        }

    },
    mounted() {
        // this.viewMounted();
    },
    unmounted() {
        // this.viewUnmounted();
    },
}
</script>

<style scoped>

</style>