<template>
    <div class="row-nice" :class="myClass">
        <div class="row-nice__lang-select" v-if="langHas">
            <InputSelect :options="langAllShort" :value="lang" class="lang-select" @updateValue="langChange"/>
        </div>

        <div class="row-nice__data input-nice">

            <Input
                    class="row-nice__input input-nice__input"
                    ref="input"
                    :type="type"
                    :version="version"
                    :disabled="disabled"
                    :icon="icon"
                    :id="id"
                    :label="label"
                    :max="max"
                    :min="min"
                    :name="name"
                    :options="options"
                    :options_null="options_null"
                    :options_null_label="options_null_label"
                    :pattern="pattern"
                    :placeholder="placeholderText"
                    :autocomplete="autocomplete"
                    :step="step"
                    :unit="unit"
                    :url="url"
                    :url_parameters="url_parameters"
                    :url_parameter_name="url_parameter_name"
                    :validate-is-equal-to="validateIsEqualTo"
                    :validate-mail="validateMail"
                    :validate-min="validateMin"
                    :validate-max="validateMax"
                    :validate-nip="validateNip"
                    :validate-postal-code="validatePostalCode"
                    :validate-required="validateRequired"
                    :validate-unique="validateUnique"
                    :value="value_local"
                    :value_input="value_input"
                    :value_input_false="value_input_false"
                    :value_show="value_show"
                    :config="config"
                    :class="input_class"
                    :show_class="input_show_class"
                    @actionKeyDown="actionKeyDown"
                    @updateValue="updateValue"
                    @updateValidate="updateValidate"
            />
            <label :for="inputId" class="input-nice__label row-nice__label" v-if="hasLabel">
                {{ label }}
            </label>
        </div>


        <div class="row-nice__separator"></div>

        <small class="row-nice__help" v-if="helpText">{{ helpText }}</small>
    </div>
</template>

<script>
import Input from "@/Library/Forms/Input";
import InputSelect from "@/Library/Forms/InputSelect.vue";
import {mapState} from "vuex";

export default {
    name: "Row",
    components: {
        InputSelect,
        Input,
    },
    props: [
        'id',
        'type',
        'label',
        'help',
        'name',
        'icon',
        'value',
        'value_input',
        'value_input_false',
        'value_show',
        'url',
        'url_parameter_name',
        'url_parameters',

        'options',
        'options_null',
        'options_null_label',

        'placeholder',
        'disabled',
        'autocomplete',
        'pattern',

        'validate-required',
        'validate-unique',
        'validate-is-equal-to',
        'validate-min',
        'validate-max',
        'validate-nip',
        'validate-mail',
        'validate-postalCode',

        'min',
        'max',
        'step',
        'unit',
        'config',
        'input_class',
        'input_show_class',
        'version',
        'multilang',
        'obj',
        'langs',

    ],
    emits: ["actionKeyDown", "updateLang", "updateValue", "updateValidate"],
    data() {
        return {
          value_local: null,
            valid: true,
            valid_test: false,
            valid_code: "",
            valid_text: "",
            lang: 'pl',

        }
    },

    methods: {


        langDataGet(langCode) {
            let ret = "";

            let langs = undefined;

            let columnName = this.name;

            if (langs === undefined && this.obj !== undefined && this.obj.langs !== undefined) {
                langs = this.obj.langs;
            }

            if (langs === undefined && this.langs !== undefined) {
                langs = this.langs;
            }

            if (langs !== undefined && langs[langCode] !== undefined && langs[langCode][columnName] !== undefined) {
                ret = langs[langCode][columnName];
            }

            return ret;

        },

        langChange(name, langSelect) {

            let langDefault = this.langDefault;

            this.lang = langSelect;

            if (langDefault === langSelect)
                this.value_local = this.value;

            if (langDefault !== langSelect)
                this.value_local = this.langDataGet(langSelect)

        },

        input: function () {
            return this.$refs.input;
        },

        updateLang: function (value) {

            this.$emit('updateLang',
                this.name, value);

        },

        updateValue: function (name, value, value_show) {

            let input = this.input();

            if (this.langIsDefault || !this.langHas) {
                this.$emit('updateValue', this.name, value, value_show);
            }

            if (!this.langIsDefault) {
                this.$emit('updateLang', this.name, this.lang, value, value_show);
            }

            if (this.valid_test === true) {

                setTimeout(function () {
                    input.validate();
                }, 10)

            }
        },
        actionKeyDown: function (event, name, value, value_show) {

            this.$emit('actionKeyDown',
                event, name, value, value_show);

        },

        updateValidate: function (validate_arr) {

            this.valid = true;
            this.valid_test = true;
            this.valid_code = "";
            this.valid_text = "";

            if (validate_arr[0] !== undefined) {
                this.valid = validate_arr[0].valid;
                this.valid_code = validate_arr[0].code;
                this.valid_text = validate_arr[0].text;
            }

        },

        validate: function () {
            let input = this.input();
            // this.updateValidate(validate_arr);

            return input.validate();
        },

        validateReset: function () {

            this.valid = true;
            this.valid_test = false;
            this.valid_code = "";
            this.valid_text = "";

            if (this.$refs.input)
                this.$refs.input.validateReset();

        },

        refresh: function () {
            // this.value = this.$parent.$data[this.name];
        },

        focus: function () {

            this.$refs['input'].focus();

        },

        clear: function () {

            this.$refs['input'].clear();

        }

    },

    computed: {

        ...mapState('app',
            {appConfig: 'config'}),

        objConfig() {
            return this.obj.config;
        },

        langIsDefault() {
            return this.langDefault === this.lang;
        },

        langDefault() {
            return this.appConfig.base_lang_default;
        },

        langAll() {
            return this.appConfig.base_lang_options ? this.appConfig.base_lang_options : [];
        },

        langAllShort() {
            let langAll = this.langAll;

            return langAll.map(el => ({value: el.value, name: el.value}));

        },

        langColumnHas() {

            let objConfig = this.objConfig ? this.objConfig : {};

            let data = objConfig.langs ? objConfig.langs : {};

            return Object.keys(data);
        },

        langHas() {

            let ret = true;

            ret = this.multilang !== true ? false : ret;
            ret = this.langAll && this.langAll.length === 1 ? false : ret;

            return ret;

        },

        hasLabel() {

            let ret = true;

            if (this.type === 'toggle')
                ret = false;
            if (this.type === 'many')
                ret = false;
            if (this.type === 'radio')
                ret = false;
            if (this.type === 'yesno')
                ret = false;
            if (this.type === 'checkbox')
                ret = false;
            if (this.type === 'image')
                ret = false;
            if (this.type === 'gallery')
                ret = false;
            if (this.type === 'audio')
                ret = false;
            if (this.type === 'file')
                ret = false;

            return ret;

        },
        inputId() {

            let id = 'input' + parseInt(Math.random() * 999999)
            if (this.$props.id)
                id = this.$props.id;

            return id;
        },
        myClass() {

            let type_class = 'row-nice-' + this.type;

            let ret = {
                'row-nice--error': this.valid === false && this.valid_test === true,
                'row-nice--lang': this.langHas,
                type_class: true,
            };

            return ret;
        },

        helpText() {

            let ret = null;

            if (!ret && this.valid_text !== "") {
                ret = this.valid_text;
            }
            if (!ret && this.help !== "") {
                ret = this.help;
            }

            return ret;

        },

        placeholderText() {
            return this.placeholder ? this.placeholder : ' ';
        },

    },

    mounted() {

        this.value_local = this.value;
        this.lang = this.langDefault;

    },

    created() {
        this.refresh();
    },

    watch: {

        value(newValue, oldValue) {

            if(this.langIsDefault){
                this.value_local = newValue;
            }

        }


    },

}
</script>

<style scoped lang="scss">

.row-nice--lang.row-nice-text,
.row-nice--lang.row-nice-textarea,
.row-nice--lang.row-nice-editor {

}

.row-nice {
  display: flex;
  flex-direction: column;
}

.row-nice {

  position: relative;

  &--lang > &__lang-select {

    width: 65px;

    position: absolute;

    top: 0;
    left: 0;

  }

  &--lang > &__data {
    margin-left: 75px;
  }

}

.input-nice {

  position: relative;

  &__input {
  }

  &__data {
  }

}

</style>