<template>
    <TemplateModal ref="modal">
        <template #head>Edycja</template>
        <template #body>
            <TicketTaskTemplateForm :obj="obj"  :class="{'loading': stateLoading()}"/>
        </template>
        <template #foot>
            <button type="submit" class="btn btn-success w-100" @click="formSubmit" :disabled="!is_changed">
                Zapisz zmiany
            </button>
        </template>
    </TemplateModal>
</template>

<script>
import Modal from "@/Library/Components/Modal.vue";
import TicketTaskTemplateForm from "@/views/Ticket/TaskTemplate/Form.vue";
import ControllError from "@/Library/Controll/Error.vue";
import ControllModal from "@/Library/Controll/Modal.vue";
import ControllForm from "@/Library/Controll/Form.vue";
import ControllToast from "@/Library/Controll/Toast.vue";
import axios from "@/axios";
import ComponentObjStateMachine from "@/Library/Extends/ComponentObjStateMachine.vue";
import TemplateModal from "@/Library/Template/Modal.vue";

export default {
    name: "TicketTaskTemplateEdit",
    components: {TemplateModal, TicketTaskTemplateForm, Modal},
    mixins: [ControllError, ControllModal, ControllToast, ControllForm, ComponentObjStateMachine],
    data: () => ({
        /**
         * Obiekt edytowany
         */
        obj: {},

        /**
         * Obiekt bazowy
         */
        obj_src: {},

        /**
         * Adres do pobrania danych
         */
        find_url: 'tickettask/templateFind',

        /**
         * Dodatkowe wytyczne do pobrania informacji
         */
        find_params: {},

        /**
         * Adres do aktualizacji informacji
         */
        update_url: 'tickettask/templateSet',

    }),

    computed: {

        /**
         * Czy w obiekcie zaszły zmiany względem oryginału
         * @returns {boolean} czy zaszły zmiany
         */
        is_changed() {
            return JSON.stringify(this.obj) !== JSON.stringify(this.obj_src)
        }
    },

    methods: {

        /**
         * Akcja wywoływana przy zapsiie
         * @returns {Promise<void>}
         */
        async formSubmit() {

            this.stateUpdate('sendInfo');

            let result = await axios
                .post(this.update_url, this.obj, {params: {id: this.obj.id}})
                .catch(error => this.errorCatch(error));

            if (result) {

                let obj = result.data.data;

                this.stateUpdate('sendSuccess');
                this.$emit('itemUpdate', obj)

                this.close();

            }

            if (!result)
                this.stateUpdate('sendWrong');
        },

        /**
         * Akcja załadowania danych
         *
         * @param id idnetyfikator obiektu
         *
         * @returns {Promise<void>}
         */
        async load(id) {

            let url = this.find_url;

            let params = Object.assign({}, this.find_params);
            params['id'] = id;

            this.stateUpdate('sendInfo');

            let data = await axios
                .get(url, {params: params})
                .then(result => result.data)
                .catch(error => this.errorCatch(error))

            if (data) {

                this.obj = data.data;
                this.obj_src = {...data.data};

                this.stateUpdate('sendSuccess');

            }

            if (!data) {
                this.stateUpdate('sendWrong');
            }

        },


    },

}
</script>