<template>
  <editor
      ref="editor"
      v-model="value_local"
      lang="pl"
      api-key="m2wepjkm0dq8eqz70lng7z8gafkrwb8onjum0uxqc9umx3m4"
      :init="{
        menubar: false,
        plugins: 'autoresize print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable export',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        resize: true,
        powerpaste_word_import: 'prompt',
        powerpaste_html_import: 'prompt',
        force_br_newlines : false,
        force_p_newlines : false,
        forced_root_block : '',
        automatic_uploads: true,

  }"
  />
</template>

<script>
import InputBase from "./InputBase";
import Editor from '@tinymce/tinymce-vue'
import toast from "@/Library/Controll/Toast.vue";

export default {
  name: "InputEditor",
  extends: InputBase,
  props: ['name', 'value'],
  mixins: [toast],
  components: {
    'editor': Editor
  },

  data() {
    return {
      value_local: null
    }
  },
  methods: {
    filePicker(cb, value, meta) {

      let self = this;

      var input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      /*
        Note: In modern browsers input[type="file"] is functional without
        even adding it to the DOM, but that might not be the case in some older
        or quirky browsers like IE, so you might want to add it to the DOM
        just in case, and visually hide it. And do not forget do remove it
        once you do not need it anymore.
      */

      input.onchange = function () {
        var file = this.files[0];

        var reader = new FileReader();

        var size = 0;

        reader.onload = function () {
          /*
            Note: Now we need to register the blob in TinyMCEs image blob
            registry. In the next release this part hopefully won't be
            necessary, as we are looking to handle it internally.
          */
          var id = 'blobid' + (new Date()).getTime();
          var blobCache = tinymce.activeEditor.editorUpload.blobCache;
          var base64 = reader.result.split(',')[1];
          var blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          size = file.size;


          /* call the callback and populate the Title field with the file name */


          if (size < 100000)
            cb(blobInfo.blobUri(), {title: file.name});
          if (size > 100000)
            self.toastInfo('Obraz zbyt duży (max 100kb)');
        };
        reader.readAsDataURL(file);
      };


      input.click();

      // setTimeout(()=>{
      //   console.log(123)
      //   console.log(this.value)
      //   self.updateValue(this.value)
      // }, 500)

    },
    val() {
      return this.value;
    },
    updateValue: function (value) {
      console.log('Update Value')
      this.$emit('updateValue', this.name, this.value_local);
    }
  },
  mounted() {
    this.value_local = this.value;
  },
  watch: {
    value_local(newValue) {
      this.updateValue(newValue)
    },
    value(newValue) {
      // this.updateValue(newValue)

      if (newValue === null)
        newValue = "";

      this.value_local = newValue;
    }
  }
}
</script>


<style lang="scss">
.tox .tox-silver-sink .tox-tinymce-aux .tox-platform-touch {
  display: none !important;
}

.tox .tox-notifications-container {
  display: none !important;
}

.tox .tox-statusbar {
  display: none !important;
}
</style>