<template>
  <div>

    <div v-if="data === 'yes' || data === '1'" style="color: green">Tak</div>
    <div v-if="data === 'no'||data === '0'" style="color: #9b132a">Nie</div>
  </div>


</template>

<script>

export default {
  name: "ShowYesNo",
  props: {
    data: {},
    config: {},
  },
  computed:{
  }

}
</script>

<style scoped lang="scss">

  .contact-info{

    &__name{

    }
    &__mail{

    }
    &__phone{

    }

  }

</style>