<template>
  <div class="container-fluid">
    <ul class="navbar-nav h-100 menu-vertical" id="navbar-nav">
      <li class="menu-title">
        <i class="ri-more-fill" aria-expanded="false"></i>
        <span data-key="menu">Menu</span>
      </li>
      <MenuItems :position="position" :positions="positions" v-for="position in positionHorizontal"/>
    </ul>
    <ul class="navbar-nav h-100 menu-horizontal" id="navbar-nav">
      <li class="menu-title">
        <i class="ri-more-fill" aria-expanded="false"></i>
        <span data-key="menu">Menu</span>
      </li>
      <MenuItems :position="position" :positions="positions" v-for="position in positionHorizontal"/>
    </ul>
  </div>
</template>

<script>

import {SimpleBar} from "simplebar-vue3";
import MenuItems from "@/layouts/part/menu-items";
import MenuAbstract from "@/layouts/part/menu-abstract.vue";

export default {
  components: {MenuItems, SimpleBar},
  extends: MenuAbstract,

  data: () => ({
    settings: {
      minScrollbarLength: 60,
    },
  }),
};
</script>

<style scoped lang="scss">

.menu-horizontal {
  display: none;
}

.menu-vertical {
  display: none;
}

[data-layout="horizontal"] .menu-horizontal {
  display: flex;
}

[data-layout="vertical"] .menu-vertical {
  display: initial;
}

</style>