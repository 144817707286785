<template>

    <ul v-if="files && files.length" class="files">
        <li v-for="file in files" :key="file.id" class="files__item file">
      <span class="file__thumb" :style="{ backgroundImage: `url(${file.thumb})` }"
            @click="showGallery(file.thumb)"></span>

            <div class="file__info">
                <span class="file__name">{{ file.name }}</span>
                <span class="file__size">{{ fileSizeFormat(file.size) }}</span>
            </div>
            <div class="file__buttons" v-if="!file.progress || file.progress == 100.00">
                <a class="file__buttons-item file__buttons-download" :href="file.url_download"
                   :download="file.name"
                >
                    <img src="@/assets/img/download.svg" alt=""/>
                </a>
                <button
                        class="file__buttons-item file__buttons-edit"
                        @click.prevent="this.$emit('fileEdit', file)"
                        v-if="can_edit_info"
                >
                    <img src="@/assets/img/pencil.png"/>
                </button>
                <button class="file__buttons-item file__buttons-delete" @click.prevent="this.$emit('fileDelete', file)"
                        v-if="can_delete"
                >
                    <img src="@/assets/img/trash.svg" alt=""/>
                </button>
            </div>
            <div class="file__progress" v-else>
                <div class="progress" v-if="file.progress">
                    <span class="progress__load" :style="{ width: `${file.progress}%` }"></span>
                </div>
            </div>
        </li>
    </ul>

    <a v-if="download_all_files_url && files.length" @click="downloadAll" ref="downloadAllBtn" class="files__download">
        Pobierz wszystkie pliki
    </a>


    <vue-easy-lightbox
            :visible="gallery_visible"
            :imgs="gallery_imgs"
            :index="gallery_index"
            @hide="hideGallery"
    >

    </vue-easy-lightbox>
</template>

<script>
import {useToast} from "vue-toastification";
import CoreDate from "@/Library/Helpers/date";
import VueEasyLightbox from 'vue-easy-lightbox'
import axios from "@/axios";

export default {
    name: "ShowFiles",
    emits: ['fileEdit', 'fileDelete'],
    components: {
        VueEasyLightbox
    },
    props: ['class', 'style', 'files', 'can_delete', 'can_edit_info', 'can_zooming', 'download_all_files_url'],
    data() {
        return {
            gallery_imgs: '', // Img Url , string or Array of string
            gallery_visible: false,
            gallery_index: 0 // default: 0
        }
    },
    methods: {

        async downloadAll() {

            let toast = useToast();

            let btn = this.$refs.downloadAllBtn;

            btn.style.opacity = .3;

            await axios
                .get(this.download_all_files_url)
                .then(await function (response) {

                    toast.success("Pobieranie rozpoczęte", {
                        timeout: 2000
                    });
                    let ret = response.data.data.url;

                    btn.style.opacity = 1;

                    window.location = ret;
                });


        },

        fileSizeFormat(size) {

            let ret = size;
            if (Number.isInteger(size)) {

                let i = Math.floor(Math.log(size) / Math.log(1024));
                ret = (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
            }

            return ret;

        },

        showDate(date) {
            return CoreDate.show(date);
        },
        showGallery(img) {

            if (this.can_zooming) {
                this.gallery_imgs = img
                this.gallery_visible = true
            }
        },
        hideGallery() {
            this.gallery_visible = false
        }
    }
}
</script>

<style scoped lang="scss">


.progress {

  position: relative;

  left: 0;
  right: 0;
  height: 4px;
  top: 0;
  overflow: hidden;
  box-sizing: border-box;

  &__load {
    //height: 4px;
    background-color: #3ea5fe;
    position: absolute;


    top: 0px;
    left: 0;
    bottom: 0;

    will-change: width;

    transition: .5s;

  }

}


.files {

  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    margin-bottom: 12px;
    height: 48px;
  }

  &__download {
    cursor: pointer;
  }

}

.file {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0 12px;
  padding: 0;

  &__thumb {
    overflow: hidden;

    position: relative;

    display: block;
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 10px;

    background-size: cover;
    background-position: center;
    border: 1px solid #e6e7ea;
  }

  &__info {
    padding: 2px 0 0 12px;

    display: flex;
    flex-direction: column;

    text-align: left;

    flex: 1 0;
    overflow: hidden;

  }

  &__buttons {

    position: relative;
    width: 140px;
    min-width: 140px;

    text-align: right;

    &-item {

      background-color: transparent;
      border-color: transparent;

      display: inline-block;

      margin-top: 12px;

      cursor: pointer;

      margin-left: 12px;
    }

    &--delete {

      & img {
        color: red;
        stroke: red;
      }

    }

    &-edit {
      //color: red;

      & img {
        width: 20px;

      }

    }
  }

  &__progress {
    position: relative;
    width: 80px;
    min-width: 80px;

    .progress {
      top: 50%;
      border-radius: 4px;
      height: 8px;
      overflow: hidden;
      background: #e6e7ea;
      margin: 0;
      padding: 0;

      &__load {
        float: left;
        top: -4px;
        height: 12px;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__name {
    color: #3f424a;
    height: 21px;
    overflow: hidden;
  }

  &__size {
    color: #898f99;

  }

  &__date {
    color: #898f99;

  }
}


</style>