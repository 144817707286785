<script>
export default {
  name: "Temporary",
  data() {
    return {
      temporary: []
    }
  },
  methods: {

    temporarySet(key, val) {

      this.temporary[key] = this.$data[key];
      this.$data[key] = val;


      console.log('Dane tymczasowe', this.temporary);

    },

    temporaryBack(key) {

      this.$data[key] = this.temporary[key];


      console.log('Dane tymczasowe przywrócone', this.temporary);

    },

    temporaryClear() {

      console.log('Dane tymczasowe', this.temporary);
      console.log('Usuwam dane tymczasowe');

      for (const [key, value] of Object.entries(this.temporary)) {
        this.$data[key] = value;
      }


      this.temporary = [];

      console.log('Dane tymczasowe', this.temporary);

    },

  }

}
</script>
