<template>
  <component :is="layout" ref="app">
    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.transitionName" mode="default">
        <component :is="Component"/>
      </transition>
    </router-view>
  </component>


  <TicketsOptions ref="ticket_options" v-if="modulesConnected('ticket')"/>
</template>

<script>

import Blank from '@/layouts/Blank'
import Login from '@/layouts/Login'
import Standard from '@/layouts/Standard'
import Domain from '@/layouts/Domain'
import ControllApp from '@/Library/Controll/App'
import ControllLocation from "@/Library/Controll/ControllLocation";
import TicketsOptions from "@/views/Ticket/Ticket/Part/Options";
import Base from "@/Library/Extends/Base.vue";

export default {
  components: {
    TicketsOptions,
    Blank,
    Login,
    Standard,
    Domain
  },
  extends: Base,
  data: () => ({
    internetChangeToast: false,
    scroll_lock_count: 0,
    scroll_temp: null,
  }),
  mixins: [ControllApp, ControllLocation],
  computed: {

    layout() {
      let defaultLayout = Standard;
      return (this.$route.meta.layout || defaultLayout)
    },

    body() {

      return document.getElementsByTagName('body')[0];


    },

  },

  methods: {
    scrollOff() {

      this.scroll_lock_count++;

      if (this.scroll_lock_count === 1) {
        this.scroll_temp = this.body.style.overflowY;
        this.body.style.overflowY = 'hidden';
      }

    },
    scrollOn() {
      this.scroll_lock_count--;

      if (this.scroll_lock_count === 0) {
        this.body.style.overflowY = this.scroll_temp;
      }

    },
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


//.slide-right-enter-active,
//.slide-right-leave-active,
//.slide-left-enter-active,
//.slide-left-leave-active{
//  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
//}
//
//.slide-left-enter {
//  transform: translateX(100%);
//}
//
//.slide-left-leave-to {
//  transform: translateX(-100%);
//}
//
//.slide-right-enter {
//  transform: translateX(-100%);
//}
//
//.slide-right-leave-to {
//  transform: translateX(100%);
//}


.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease-out;
}

.slide-left-enter-to {
  position: absolute;
  right: 0;
  top: 0px;
}

.slide-left-enter-from {
  position: absolute;
  right: -100%;
  top: 0px;
}

.slide-left-leave-to {
  position: absolute;
  left: -100%;
  top: 0px;
}

.slide-left-leave-from {
  position: absolute;
  left: 0;
  top: 0px;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease-out;
}

.slide-right-enter-to {
  position: absolute;
  left: 0;
  top: 0px;
}

.slide-right-enter-from {
  position: absolute;
  left: -100%;
  top: 0px;
}

.slide-right-leave-to {
  position: absolute;
  right: -100%;
  top: 0px;
}

.slide-right-leave-from {
  position: absolute;
  right: 0;
  top: 0px;
}

</style>