<script>
export default {
  name: "ControllModal",
  data: () => ({
    modal_name: 'modal',
  }),
  methods: {

    modal() {
      let ret =null;
      if(this.$refs[this.modal_name] !== undefined)
        ret = this.$refs[this.modal_name];

      return ret;
    },
    open(){
      this.modal().open()
    },
    close(){
      this.modal().close()
    },
  },
  computed:{

  },
}
</script>
