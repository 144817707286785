<template>
  <div class="map-container">

  <div :id="this.mapId" class="map" :style="style"></div>

  <div v-for="route in routes">
    <LRoutingMachine v-if="route.show === 'yes'"
                     :route="route"
                     :mapObject="map"
                     :waypoints="route.points"
                     :fit-selected-routes="fit_selected_routes"
                     :lineOptions="{styles: [{color: route.color, weight: route.weight}]}"/>
  </div>

  <LRoutingMachine
      v-if="route_user.length > 0"
      :refresh="route_user.length"
      :mapObject="map"
      :waypoints="route_user"
      :fit-selected-routes="false"
      :lineOptions="{styles: [{color: 'orange', weight: 5}]}"/>


  <LRoutingMachine ref="refLRoutingMachine" :mapObject="map" :waypoints="[]"/>
  </div>

</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/leaflet.markercluster.js";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import 'leaflet.offline';
import debounce from 'debounce';
import {urlTemplate} from '@/Library/Components/Map/const';
import storageLayer from '@/Library/Components/Map/storageLayer';
import LRoutingMachine from "@/Library/Components/Map/LRoutingMachine";
import axiosOriginal from "axios";

export default {
  name: "Map",
  components: {LRoutingMachine},
  props: {
    style: {
      default() {
        return "";
      }
    },
    markers: {
      default() {
        return []
      }
    },
    markers_categories: {
      default() {
        return []
      }
    },
    routes: {
      default() {
        return []
      }
    },
    center: {
      default() {
        return {
          lat: 50.2584100,
          lng: 19.0275400
        }
      }
    },
    zoom: {
      default() {
        return 13
      }
    },
    fit_selected_routes: {
      default() {
        return false
      }
    },
    route_user: {
      default() {
        return []
      }
    },
    show_me: {
      default() {
        return false
      }
    },
    get_click_address: {
      default() {
        return false
      }
    },
    clicked_address: {
      default() {
        return {}
      }

    },
    mapId: {
      default() {
        return 'map' + parseInt(Math.random() * 10000000000);
      }
    }
  },
  emits: ["emitClickAddress","markerClick"],
  data: () => ({
    /**
     * Zmienna, która przechowuje objekt mapy
     */
    map: null,


    marker_icons: [],

    selected_marker: null,

    mapMarkersLayout: null,

    userMarkerLayout: null,

    clickMarkerLayout: null,
  }),
  mounted() {

    this.map = L.map(this.mapId);

    this.map.setView(
        this.center,
        this.zoom,
    );

    // offline baselayer, will use offline source if available
    const baseLayer = L.tileLayer
        .offline(urlTemplate, {
          attribution: '',
          subdomains: 'abc',
          minZoom: 1,
          maxZoom: 17,
        })
        .addTo(this.map);

    /**
     * Layout do którego dodaję wszystkie pobrane punkty z bazy danych
     */
    this.mapMarkersLayout = L.markerClusterGroup();
    this.map.addLayer(this.mapMarkersLayout)

    /**
     * Layout do którego dodaję punkt aktualnej lokalizacji użytkownika
     */
    this.userMarkerLayout = L.markerClusterGroup();
    this.map.addLayer(this.userMarkerLayout)

    /**
     * Layout do którego dodaję kliknięty punkt na mapie w dowolnym miejscu
     */
    this.clickMarkerLayout = L.markerClusterGroup();
    this.map.addLayer(this.clickMarkerLayout)


    /**
     * Pobieranie + usuwanie kafelków mapy, jest potrzebne dla mapy offline
     */
    {
      // add buttons to save tiles in area viewed
      const control = L.control.savetiles(baseLayer, {
        zoomlevels: [12, 13, 14, 15, 16, 17], // optional zoomlevels to save, default current zoomlevel
        confirm(layer, successCallback) {
          // eslint-disable-next-line no-alert
          if (window.confirm(`Save ${layer._tilesforSave.length}`)) {
            successCallback();
          }
        },
        confirmRemoval(layer, successCallback) {
          // eslint-disable-next-line no-alert
          if (window.confirm('Remove all the tiles?')) {
            successCallback();
          }
        },
        saveText: '<i class="fa fa-download" aria-hidden="true" title="Save tiles"></i>',
        rmText: '<i class="fa fa-trash" aria-hidden="true"  title="Remove tiles"></i>',
      });
      control.addTo(this.map);
    }

    /**
     * Postęp załadowania kafelków mapy + informacja o załadowanych kafelkach mapy
     */
    {
      // layer switcher control
      const layerswitcher = L.control
          .layers({
            'osm (offline)': baseLayer,
          }, null, {collapsed: false})
          .addTo(this.map);
      // add storage overlay
      storageLayer(baseLayer, layerswitcher);

      let progress, total;
      const showProgress = debounce(() => {
        console.log("Download map: " + Math.floor((progress / total) * 100) + "%");
      }, 10);

      baseLayer.on('savestart', (e) => {
        progress = 0;
        total = e._tilesforSave.length;
      });
      baseLayer.on('savetileend', () => {
        progress += 1;
        showProgress();
      });
    }

    document.querySelector(".leaflet-right .leaflet-control-layers").remove();

    if (!this.$root.internetConnect)
      document.querySelector(".savetiles ").remove();

    this.updateMarkersCategories();
    this.updateMarkers(this.markers);

    /**
     * Pokazywanie użytkownika na mapie (jeżeli `show_me` === true)
     */
    if (this.show_me) {
      if (navigator.geolocation) {
        let self = this
        navigator.geolocation.getCurrentPosition(function (position) {
          let customIcon = L.icon({
            iconUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/User_icon-cp.svg/1200px-User_icon-cp.svg.png",
            iconSize: [30, 40],
          });

          const mapMarker = L.marker([position.coords.latitude, position.coords.longitude], {
            icon: customIcon,
            id: 'userMarker'
          })
          self.userMarkerLayout.addLayers([mapMarker]);
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }

    /**
     * Otrzymanie współrzędnych po kliknieciu w dowolnym miejscu na mapie
     */
    let self = this;
    if (this.get_click_address) {

      if (this.clicked_address.lat)
        self.renderClickedMarker();

      this.map.on('click', function (e) {

        let coord = e.latlng;
        let lat = coord.lat;
        let lng = coord.lng;

        console.log("You clicked the map at latitude: " + lat + " and longitude: " + lng);

        Object.assign(self.clicked_address, coord);
        self.renderClickedMarker();

        axiosOriginal
            .get("https://nominatim.openstreetmap.org/reverse?lat=" + lat + "&lon=" + lng + "&format=json")
            .then(function (result) {

              let data = result.data.address;

              data['lat'] = lat;
              data['lng'] = lng;

              self.$emit("emitClickAddress", data);


            })
      });
    }

  },
  methods: {

    renderClickedMarker() {

      let coord = this.clicked_address;

      let customIcon = L.icon({
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),

        iconSize: [25, 41], // size of the icon
        shadowSize: [50, 64], // size of the shadow
        iconAnchor: [15, 50], // point of the icon which will correspond to marker's location
        shadowAnchor: [15, 70],  // the same for the shadow
        popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
      });

      if (coord.lat) {
      let click_on_map_marker = L.marker([coord.lat, coord.lng], {id: "click_on_map_marker", icon: customIcon});
      this.clickMarkerLayout.clearLayers();
      this.clickMarkerLayout.addLayers([click_on_map_marker]);
      }
    },

    updateMarkersCategories() {

      this.marker_icons = [];

      let customIcon = L.icon({
        code: 'default',
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
      this.marker_icons.push(customIcon)

      for (const marker_category of this.markers_categories) {
        this.marker_icons.push(L.icon({
          code: marker_category.id,
          iconUrl: marker_category.image_url,
          shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        }))
      }

    },

    getMarkerCategory(id) {

      let icon = this.marker_icons.find(marker_icon => marker_icon.options.code === id)

      if (icon === undefined) {
        icon = this.marker_icons.find(marker_icon => marker_icon.options.code === 'default')
      }

      return icon

    },

    /**
     * Aktualizowanie punktów na mapie
     */
    updateMarkers(newValue) {
      console.log(newValue)
      this.mapMarkersLayout.clearLayers();

      newValue.map(marker => {

        let customIcon = this.getMarkerCategory(marker.id_category);

        const mapMarker = L.marker([marker.lat, marker.lng], {icon: customIcon, id: marker.id});
        if (marker.popup !== undefined) {
          mapMarker.bindPopup(marker.popup);
        }

        mapMarker.on("click", () => {
          this.selected_marker = marker;

          this.$emit('markerClick', marker)

          if (marker.popup !== undefined)
            mapMarker.openPopup();

        })

        this.mapMarkersLayout.addLayers([mapMarker]);
      })
    }
  },
  watch: {
    clicked_address: {
      handler(newValue, oldValue) {


        this.renderClickedMarker()
      },
      deep: true
    },
    markers: {
      handler(newValue, oldValue) {
        this.updateMarkers(newValue)
      },
      deep: true
    },
    markers_categories: {
      handler(newValue, oldValue) {
        this.updateMarkersCategories(newValue)
        this.updateMarkers(this.markers)
      },
      deep: true
    },
    routes: {
      handler(newValue, oldValue) {


        setTimeout(() => {
          this.$refs.refLRoutingMachine.refreshOfflineRoutes(newValue)
        }, 200)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.map-container {
  height: inherit;
  z-index: inherit;


}
.map {
  height: inherit;
  z-index: inherit;


}

.leaflet-routing-container {
  display: none;
}
</style>