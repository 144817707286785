<template>
    <label class="row-nice__radio radio" @change="updateValue" v-for="option in options_show">
        <input class="radio__input" type="radio" :name="id" v-model="value" :value="option.value"
               :disabled="disabled"/>
        <span class="radio__text">{{ option.name }}</span>
    </label>

</template>

<script>
import InputOptionsAbstract from "./InputOptionsAbstract.vue";

export default {
    name: "InputRadio",
    extends: InputOptionsAbstract,
    emits: ["updateValue"],
    props: {
        name: {},
        value: {},
        options: {},
        disabled: false
    },
    data: () => ({

        input_id_data: null,
    }),

    methods: {
        val() {
            return this.value;
        },
        updateValue: function (value) {

            let valueShow = this.value;

            let keys = Object.keys(this.options);
            let values = Object.values(this.options);

            let idx = keys.indexOf(this.value);
            if (idx > -1) {
                valueShow = values[idx];
            }

            this.$emit('updateValue', this.name, this.value, valueShow);
        }
    },
    computed: {

        id(){

            this.input_id_data = this.input_id_data ? this.input_id_data : parseInt( Math.random()*1000000 );

            return 'input_'+this.input_id_data;

        },

        options_show() {

            return this.makeOptions(this.options)

        }
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
.radio {
  margin-right: 6px;

  &__input {

  }

  &__text {

  }

  &:first-child &__input {

    margin-left: 0 !important;
  }
}
</style>