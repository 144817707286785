<template>
    <div class="input-text-list">
        <List
                class="input-text-list__list m-0"
                :delete_button="true"
                :objs="value_local_show"
                v-show="value_local_show.length > 0"
                @actionDeleteClick="deleteClick"
        />

        <div class="input-text-list__form input-text-form">
            <div class="row-nice input-text-form__input">
                <InputText
                        placeholder="Wpisz tekst"
                        cl
                        :value="text"
                        ref="input"
                        @updateValue="updateText"
                        @keypress.ctrl.enter="addClick"
                />
            </div>
            <button class="input-text-form__button" @click="addClick"></button>
        </div>

    </div>
</template>

<script>
import List from "@/Library/Components/List.vue";
import InputBase from "@/Library/Forms/InputBase.vue";
import InputText from "@/Library/Forms/InputText.vue";

export default {
    name: "InputTextList",
    extends: InputBase,
    components: {InputText, List},
    props: {
        name: {},
        placeholder: {},
        value: {
            default() {
                return [];
            }
        },
    },

    data: () => ({
        value_local: [],

        text: ""
    }),

    computed: {
        value_local_show() {

            return this.value_local.map((el, idx) => ({id: idx, name: el}))

        }
    },

    methods: {

        addClick() {

            if (this.text === "") {

                this.$refs.input.focus();
            }

            if (this.text !== "") {
                this.value_local.push(this.text);

                this.text = "";

                this.$refs.input.focus();
            }

            this.updateValue();

        },

        deleteClick(element) {

            this.value_local.splice(element.id, 1);

            this.updateValue();

        },

        updateText(name, value) {

            this.text = value;

        },

        updateValue() {

            this.$emit('updateValue', this.name, this.value_local)

        },

        loadValue() {

            let value = this.value !== null ? this.value : [];

            let isArr = typeof value[Symbol.iterator] === 'function';

            let data = isArr ? [...value] : [value]

            this.value_local = data;
        },

    },

    mounted() {

        this.loadValue();
    },

    watch: {

        value: {
            handler(newValue, oldValue) {
                this.loadValue();
            },
            deep: true
        }

    },
}
</script>

<style scoped lang="scss">

.input-text-list {

  &__list {
  }

  &__form {
  }

}

.input-text-form {
  display: flex;

  &__input {
    width: calc(100% - 59px);

    margin: 0 10px 0 0;
  }

  &__button {

    width: 39px;
    height: 39px;
    background-color: #f5a804;
    border: none;

    border-radius: 50%;

    text-align: center;

    margin: 3px auto 0;

    position: relative;

    &:before {

      content: '';

      position: absolute;

      width: 20px; /* the size */
      height: 20px;

      aspect-ratio: 1;
      //border: 10px solid #000; /* the outer space */
      background: conic-gradient(from 90deg at 3px 3px, #f5a804 90deg, #fff 0) calc(100% + 3px / 2) calc(100% + 3px/2)/ calc(50%  + 3px) calc(50% + 3px);
      display: inline-block;

      top: 10px;
      left: 10px;
    }
  }

}

</style>