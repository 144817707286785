<template>

    <ul class="button-list" :class="{
        'button-list--column': direction === 'column',
        'button-list--row': direction === 'row',
        'button-list--full-width': full_width,
    }">
        <li class="button-list__item item" v-for="button in buttons">
            <a v-if="tag === 'a'" :class="cls(button)" @click="$emit('click', button.value, button)"
               class="item__element item__element--link">
                {{ button.name }}
            </a>
            <a v-if="tag === 'button'" :class="cls(button)" @click="$emit('click', button.value, button)"
               class="item__element item__element--button">
                {{ button.name }}
            </a>
        </li>
    </ul>

</template>

<script>
export default {
    name: "ButtonList",
    emits: ['click'],
    props: {
        buttons: {
            default() {
                return [];
            }
        },
        direction: {
            default() {
                return 'row';
            }
        },
        full_width: {
            default() {
                return false;
            }
        },
        tag: {
            default() {
                return 'button';
            }
        },
        button_class_default: {
            default() {
                return 'btn btn-primary';
            }
        }
    },
    methods: {
        cls(button) {
            return button.class ? button.class : this.button_class_default
        },
    }
}
</script>

<style scoped lang="scss">

.button-list {

  margin: 0;
  padding: 0;

  list-style: none;

  display: flex;


  &__item {


  }


  &--row {
    flex-direction: row;
  }

  &--row &__item {
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--column {
    flex-direction: column;
  }

  &--column &__item {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.button-list--column.button-list--full-width .button-list__item {
    width: 100%;

    & > * {
      width: 100%;
    }
  }

  &.button-list--row.button-list--full-width {
    //align-items: stretch;
    //background-color: red;
    flex-wrap: wrap;
  }

  &.button-list--row.button-list--full-width .button-list__item {

      flex: 1 0 0 ; /* flex-grow, flex-shrink, flex-basis */
      & > * {
          width: 100%;
      }
  }

}

//.item {
//
//  &__element {
//      width: 100%;
//    &--button {
//    }
//
//    &--link {
//    }
//  }
//}

</style>