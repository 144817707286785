<template>

  <InputSelect :options="options" class="domain-select" :value="this.domain_selected_id" @updateValue="domainChange"/>

  <ShowSvg icon="refresh" fill="var(--secondary-color)" class="refresh" @click="refresh"/>

  <ShowSvg v-if="info && info.type === 4" icon="cog" fill="var(--secondary-color)" class="cog"
           @click="this.$router.push('/domain/'+domain_selected_id)"/>

</template>

<script>
import InputSelect from "@/Library/Forms/InputSelect.vue";
import ControllError from "@/Library/Controll/Error.vue";
import ControllToast from "@/Library/Controll/Toast.vue";
import {mapActions, mapState} from "vuex";
import ShowSvg from "@/Library/Show/Svg.vue";

export default {

  name: "DomainSelect",

  components: {ShowSvg, InputSelect},

  mixins: [ControllError, ControllToast],

  computed: {

    ...mapState('app', ['domains', 'domain_selected_id']),
    ...mapState('user', ['info']),

    options() {

      let ret = [...this.domains.map(domain => ({
        value: domain.id,
        name: (domain.is_template === 'yes' ? '[Szablon] ' : '') + domain.name
      }))];

      return ret;

    },

    app() {

      return this.$root.$refs.app;

    }

  },

  methods: {

    ...mapActions('app', ['domainSelect']),

    async refresh() {

      await this.app.loadConfig();

    },

    async domainChange(name, value) {

      let self = this;

      this.$router.push('/');

      await this.domainSelect(value);

      await this.refresh();

    }

  },

}
</script>

<style scoped lang="scss">

.refresh {
  cursor: pointer;
  margin: 10px 0 10px 4px;
  padding: 12px 6px;
}

.cog {
  cursor: pointer;
  margin: 10px 0;
  padding: 12px 6px;
}

</style>