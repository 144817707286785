<script>
import Base from "@/Library/Extends/Base.vue";

export default {
  name: "ControllForm",
  emits: ['updateLang', 'updateValue'],
  extends: Base,
  data: () => ({

    /**
     * Nazwy refernecji do pól formularza, wykoorzystuje się przy walidacji
     */
    input: [],

    /**
     * Nazwa pola gdzie zapisujemy dane
     */
    row_obj_name: 'obj',

    /**
     * Nazwa pola gdzie zapisujemy dodatkowe informacje o wyświetlaniu
     */
    row_obj_show: 'show',

    /**
     * Nazw zbioru gdzie zapisywać czy w propsach czy w data
     *  - data - w data
     *  - props - w propsach
     *  - none - nie zapisuje danych
     */
    row_save_to: 'data',
  }),

  computed: {

    target() {

      let save_to = this.row_save_to;

      let ret = null;
      if (save_to === 'data') {
        ret = this.$data;
      }
      if (save_to === 'props') {
        ret = this.$props;
      }

      return ret;

    },

    targetObj() {

      let target = this.target;

      return target[this.row_obj_name] ?? null;
    },

    targetObjShow() {
      let ret = null;

      let target = this.target;

      if (target[this.row_obj_show] !== undefined)
        ret = target[this.row_obj_show];

      return ret;

    },
  },

  methods: {
    updateValue(name, value, value_show) {

      this.debug('Aktualizacja wartości', name, value, value_show);

      let obj = this.targetObj;

      let objShow = this.targetObjShow;

      if (obj)
        obj[name] = value;

      if (objShow)
        objShow[name] = value_show;

      this.$emit("updateValue", name, value, value_show);
    },

    updateLang(name, lang, value) {

      this.debug('Aktualizacja treści językowych', lang, name, value);

      let obj = this.targetObj;

      let lang_column = 'langs';

      if (!obj)
        return null;


      if (obj[lang_column] === undefined)
        obj[lang_column] = {};

      if (obj[lang_column] !== undefined && Array.isArray(obj[lang_column])) {
        obj[lang_column] = {};
      }

      if (obj[lang_column][lang] === undefined)
        obj[lang_column][lang] = {};

      obj[lang_column][lang][name] = value;

      this.$emit("updateLang", name, lang, value);

    },

    inputs() {

      let ref_names = this.$data.input ? this.$data.input : [];

      let inputs = [];
      for (const name of ref_names) {

        if (this.$refs[name]) {
          inputs.push(this.$refs[name]);
        }
      }

      return inputs;

    },

    focus() {

      let inputs = this.inputs();

      let first_input = inputs[0];

      if (first_input !== undefined)
        first_input.focus();

    },

    async validate() {

      let inputs = this.inputs();
      let errors = [];
      let errors_count = 0;
      let errors_first = null;

      console.log('Loop - before');
      for await (const input of inputs) {

        console.log('Loop', input);
        let errors_row = await input.validate();

        if (errors_row === undefined)
          return errors;

        if (errors_row.length > 0) {
          errors_count += errors_row.length;
          errors = errors.concat(errors_row);
        }

        if (errors_first === null && errors_row.length > 0) {
          errors_first = this.$refs[name];
        }

      }
      console.log('Loop - after');


      console.log(errors)
      console.log(inputs)

      if (errors_first)
        errors_first.focus();

      return errors;

    },

    validateReset() {

      let inputs = this.inputs();

      for (const input of inputs) {
        input.validateReset();
      }

    },

    async validateSuccess() {

      let errors = await this.validate();

      let ret = errors.length === 0;

      if (ret) {
        this.validateReset();
      }

      return ret;

    }
  },
}
</script>
