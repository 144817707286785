<script>
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import L from "leaflet";
import { IRouter, IGeocoder, LineOptions } from "leaflet-routing-machine";

// import ControllTemp from "@/Library/Controll/Temp";

export default {
  props: {
    mapObject: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    waypoints: {
      type: Array,
      required: true,
    },
    router: {
      type: IRouter,
    },
    plan: {
      type: L.Routing.Plan,
    },
    geocoder: {
      type: IGeocoder,
    },
    fitSelectedRoutes: {
      type: [String, Boolean],
      default: "smart",
    },
    lineOptions: {
      type: LineOptions,
    },
    routeLine: {
      type: Function,
    },
    autoRoute: {
      type: Boolean,
      default: true,
    },
    routeWhileDragging: {
      type: Boolean,
      default: false,
    },
    routeDragInterval: {
      type: Number,
      default: 500,
    },
    waypointMode: {
      type: String,
      default: "connect",
    },
    useZoomParameter: {
      type: Boolean,
      default: false,
    },
    showAlternatives: {
      type: Boolean,
      default: false,
    },
    altLineOptions: {
      type: LineOptions,
    },
    refresh: {
      default() {
        return 0
      }
    },
    route: {
      default() {
        return null
      }
    },
  },
  name: "LRoutingMachine",
  mixins: [],
  data() {
    return {
      ready: false,
      map: null,
      layer: null,
    };
  },
  watch: {
    mapObject() {
      if (this.mapObject == null) {
        return;
      }
      this.add();
    },
    refresh: {
      handler(newValue, oldValue) {
        this.layer.remove();
        this.add();
      },
      deep: true
    },
  },
  mounted() {
    this.add();
  },
  beforeUnmount() {
    return this.layer ? this.layer.remove() : null;
  },
  methods: {
    add() {
      if (this.mapObject == null) {
        return;
      }

      const {
        waypoints,
        fitSelectedRoutes,
        autoRoute,
        routeWhileDragging,
        routeDragInterval,
        waypointMode,
        useZoomParameter,
        showAlternatives,
      } = this;

      const options = {
        waypoints,
        fitSelectedRoutes,
        autoRoute,
        routeWhileDragging,
        routeDragInterval,
        waypointMode,
        useZoomParameter,
        showAlternatives,
        lineOptions: this.lineOptions,
        createMarker: function () {
          return null;
        }
      };

      /**
       * Rysowanie tras w trybie `online`
       */
      if (this.$root.internetConnect) {
        const routingLayer = L.Routing.control(options);
        routingLayer.addTo(this.mapObject);
        this.layer = routingLayer;

        // this.clearTempElement("routes_coordinates");
        setTimeout(() => {
          // if (this.route)
            // this.addToTemp("routes_coordinates", [{id: this.route.id, coordinates:this.layer._line._route.coordinates}])
        }, 2000)
      }
      this.ready = true;
    },


    /**
     * Metoda, która aktualizuje (rysuje) trasy w trybie `offline`
     * @param routes
     */
    refreshOfflineRoutes(routes) {
      if (!this.$root.internetConnect) {

        /**
         * Usuwanie wszystkich narysowanych tras
         */
        for (let i in this.mapObject._layers) {
          if (this.mapObject._layers[i]._path !== undefined) {
            try {
              this.mapObject.removeLayer(this.mapObject._layers[i]);
            } catch (e) {
              console.log("problem with " + e + this.mapObject._layers[i]);
            }
          }
        }


        /**
         * Rysowanie tras, otrzymanych na podstawie filtrowania
         */
        let routes_coordinates  = this.getTempElement("routes_coordinates");

        routes.map(route => {
          let route_coordinates = routes_coordinates.find(el => el.id === route.id)

          if (route_coordinates) {
            new L.Polyline(route_coordinates.coordinates, {color: route.color, weight: route.weight}).addTo(this.mapObject);
          }
        })
      }
    }

  },
};
</script>