<template>
  <span class="price">
    <span v-if="price !== undefined && price !== null" style="display: inline-block">
      {{ price_show }}<span class="price__currency grey-text">{{ currency_show }}</span>
    </span>
    <span v-if="price === null || price === undefined" class="grey-text">{{ price_show }}</span>
  </span>
</template>

<script>

export default {
  name: "ShowPrice",
  props: {
    price: {
      default() {
        return undefined;
      }
    },
    show_null: {
      default() {
        return true;
      }
    },
    show_currency: {
      default() {
        return true;
      }
    },
    null_text: {
      default() {
        return "Brak";
      }
    },
    currency: {
      default() {
        return ' zł';
      }
    },
  },
  methods: {
    format(val) {
      let format = new Intl.NumberFormat(undefined,
          {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
      let num = val.toString();
      const comma = num.indexOf(',');
      if (comma === -1) {
        return format.format(parseFloat(num));
      }
      if (comma > -1) {
        num.replace(',', '.')
      }
      return format.format(parseFloat(num));

    }
  },
  computed: {
    price_show() {
      let ret = null;
      if (this.show_null)
        ret = this.null_text;

      if (this.price !== null && this.price !== undefined) {
        let price = this.price;
        ret = this.format(price);
      }
      return ret;
    },

    currency_show() {
      let ret = null;
      if ((this.price !== null && this.price !== undefined) && this.show_currency) {
        ret = this.currency;
      }
      return ret;
    }
  }
}
</script>

<style scoped lang="scss">

.price {
  &__currency {
    //color: #aaa;
  }
}

</style>