<template>
  <input
      :class="this.class"
      :style="this.style"
      :min="min"
      :max="max"
      :step="step"
      type="number"
      v-model="value"
      @change="updateValue"
      ref="input"
      :placeholder="placeholder"/>
</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputInt",
  extends: InputBase,
  props: {
    name: {},
    placeholder: {},
    value: {},
    class: {
      default() {
        return 'row-nice__input';
      }
    },
    style: {
      default() {
        return '';
      }
    },
    min: {
      default() {
        return undefined;
      }
    },
    max: {
      default() {
        return undefined;
      }
    },
    step: {
      default() {
        return 1;
      }
    },
  },
  data() {
    return {}
  },
  methods: {
    val() {
      return this.value;
    },
    focus() {
      this.$refs['input'].focus()
    },
    updateValue() {
      this.$emit('updateValue', this.name, Math.round(this.value));
    }
  }
}
</script>

<style scoped lang="scss">input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: button;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {

  //opacity: 1;

}

.placeholder-white {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }
}

</style>