<template>
    <TemplateModal ref="modal">
        <template #head>Nowe</template>
        <template #body>
            <TicketTaskTemplateForm :obj="obj" :class="{loading: stateLoading()}"/>
        </template>
        <template #foot>
            <button type="submit" class="btn btn-success" style="width: 100%;" @click="formSubmit">
                Dodaj
            </button>
        </template>
    </TemplateModal>
</template>

<script>
import Modal from "@/Library/Components/Modal.vue";
import TicketTaskTemplateForm from "@/views/Ticket/TaskTemplate/Form.vue";
import ControllError from "@/Library/Controll/Error.vue";
import ControllModal from "@/Library/Controll/Modal.vue";
import ControllForm from "@/Library/Controll/Form.vue";
import ComponentObjStateMachine from "@/Library/Extends/ComponentObjStateMachine.vue";
import axios from "@/axios";
import TemplateModal from "@/Library/Template/Modal.vue";

export default {
    name: "TicketTaskTemplateNew",
    components: {TemplateModal, TicketTaskTemplateForm, Modal},
    mixins: [ControllError, ControllModal, ControllForm, ComponentObjStateMachine],
    emits: ['itemAdd'],
    data: () => ({
        /**
         * obiekt
         */
        obj: {

        },
        /**
         * Domyślne wartości obiektu
         */
        obj_template: {

        },

        /**
         * Adres do dodania obiektu
         */
        add_url: 'tickettask/templateAdd',

    }),
    methods: {
        /**
         * Akcja wykonywana przy zapisie
         * @returns {Promise<void>}
         */
        async formSubmit() {

            this.stateUpdate('sendInfo');

            let result = await axios
                .post(this.add_url, this.obj)
                .catch(error => this.errorCatch(error));

            if (result) {

                let obj = result.data.data;

                this.stateUpdate('sendSuccess');
                this.$emit('itemAdd', obj)

                this.close();

            }

            if (!result)
                this.stateUpdate('sendWrong');

        }
    },

    /**
     * Akcje związane z zamonotowaniem komponentu
     */
    mounted() {
        this.obj = {...this.obj_template};
    }


}
</script>

<style scoped lang="scss">

</style>