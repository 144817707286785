<template>

  <TemplateModal ref="modal">
    <template #head>
      <span @click="goTop" v-if="this.id_folder" v-html="'<'"></span>
      Repozytorium plików
    </template>
    <template #body>
      <ul class="folder-list">
        <li class="folder-list__item" v-for="folder in this.folders_actual" @click="selectFolder(folder)">
          {{ folder.name }}
        </li>
      </ul>
      <ul class="file-list">
        <li class="file-list__item" v-for="file in this.files_actual" @click="selectFile(file)">
          {{ file.name }}
        </li>
      </ul>
    </template>
    <template #foot>
      <button class="btn btn-primary" :disabled="!this.id_file" @click="fileSelected">Wybierz plik</button>
    </template>
  </TemplateModal>

</template>

<script>
import TemplateModal from "@/Library/Template/Modal.vue";
import ControllModal from "@/Library/Controll/Modal.vue";
import axios from "@/axios";
import axiosOriginal from "axios";

export default {
  name: "FileModal",
  components: {TemplateModal},
  emits: ['fileSelected','fileSelectedInfo'],
  mixins: [ControllModal],
  props: {
    format: {
      default() {
        return '*';
      }
    }
  },
  data: () => ({
    id_folder: null,
    id_file: null,
    files: [],
    folders: [],
  }),

  computed: {
    folders_actual() {
      return this.folders.filter(folder => folder.id_top === this.id_folder)
    },
    files_actual() {
      return this.files.filter(folder => folder.id_collection === this.id_folder)
    },
    selected_file() {

      return this.files.find(file => file.id === this.id_file)
    }

  },

  methods: {
    /**
     * Wybiera folder
     * @param folder
     */
    selectFolder(folder) {

      this.id_folder = folder.id;
      this.id_file = null;

    },
    /**
     * Wybiera plik
     * @param file
     */
    selectFile(file) {
      this.id_file = file.id;

    },

    /**
     * Informuje o wyborze pliku
     */ async fileSelected() {

      let file = this.selected_file;

      this.$emit('fileSelected', file);

      let data = await this.getFileInfo();

      this.$emit('fileSelectedInfo', data);

      this.close();

    },

    /**
     * Przejdź wyżej
     */
    goTop() {

      let folder = this.folders.find(folder => folder.id === this.id_folder)

      if (folder) {
        this.id_folder = folder.id_top;
        this.id_file = null;
      }

    },


    /**
     * Pobieranie wszystkich folderów z bazy danych przy odświeżaniu strony
     */
    async loadFolders() {
      let self = this;
      let url = "file/collectionget";
      await axios
          .get(url, {params: {sort: 'name asc'}})
          .then(async function (result) {
            let data = result.data.data;

            self.folders = data;
            self.folders.map(folder => folder.type = "Folder")

          })
    },

    /**
     * Pobieranie wszystkich plików z bazy danych przy odświeżaniu strony
     */
    async loadFiles() {
      let self = this;
      let url = "file/get";
      await axios
          .get(url, {params: {sort: 'name asc'}})
          .then(async function (result) {
            let data = result.data.data;

            self.files = data;
            self.files.map(file => file.type = "File")

          })
    },

    async getFileInfo() {

      let file = this.selected_file;

      let url_base = axios.defaults.baseURL;

      let url = url_base + 'file/copytoupload?id=' + file.id;

      let params = {
        id: file.id
      }

      let data = await axios.get(url, {params: params}).then(result => result.data.data)

      return data;


    }


  },
  mounted() {

    this.loadFiles();

    this.loadFolders();

  }

}
</script>

<style scoped lang="scss">

.file-list {
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    padding: 10px 0;
  }
}

.folder-list {
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    padding: 10px 0;
    font-weight: bold;
  }
}

</style>