<template>

  <div class="dropdown ms-sm-3 header-item topbar-user">
    <button
        type="button"
        class="btn"
        id="page-header-user-dropdown"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
    >
              <span class="d-flex align-items-center position-relative">
                <span class="rounded-circle header-profile-user" :style="'background-image: url('+info.avatar_url+');'"></span>
                
                <span class="user-internet" :class="{
                  'user-internet--connect': this.$root.internetConnect,
                  'user-internet--no-connect': !this.$root.internetConnect,
                }"
                      :title="this.$root.internetConnect ? 'Masz połączenie z internetem':'Nie masz połączenia z internetem'"
                ></span>
                <span class="text-start ms-xl-2">
                  <span
                      class="
                      d-none d-xl-inline-block
                      ms-1
                      fw-medium
                      user-name-text
                    "
                  >{{ info.name_all }}</span
                  >
                  <span
                      class="
                      d-none d-xl-block
                      ms-1
                      fs-12
                      text-muted
                      user-name-sub-text
                    "
                  >Operator</span
                  >
                </span>
              </span>
    </button>
    <div class="dropdown-menu dropdown-menu-end">
      <!-- item-->
      <h6 class="dropdown-header">Witaj {{info.firstname}}!</h6>

      <div class="dropdown-divider"></div>
      <router-link class="dropdown-item" :to="'/account'">
        <span class="align-middle">Moje konto</span>
      </router-link>


      <router-link class="dropdown-item" :to="pos.link" v-for="pos in poss">
        <span class="align-middle">{{ pos.name }}</span>
      </router-link>

      <div class="dropdown-divider"></div>

      <router-link class="dropdown-item" :to="'/auth/logout'">
        <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
        <span class="align-middle" data-key="t-logout">Wyloguj</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "NavBarUser",

  computed: {

    ...mapState('app', ['menu']),
    ...mapState('user', ['info']),

    poss() {

      let poss = this.menu;

      return poss.filter(pos => pos.code_top === 'account')
    }

  },
}
</script>

<style scoped>

</style>