<template>

    <td class="column column--name" v-show="columns.includes('name')" :title="obj.name">
        <Show
                type="info"
                :data="obj.name"
        />
    </td>

    <td class="column column--buttons">
        <div class="button button--edit" @click="$emit('actionEditClick', obj)">
            <ShowSvg icon="edit" color="#465d8b"/>
        </div>
        <div class="button button--delete" @click="$emit('actionDeleteClick', obj)">
            <ShowSvg icon="delete" color="red"/>
        </div>
    </td>
</template>

<script>
import Show from "@/Library/Show/Show.vue";
import ShowSvg from "@/Library/Show/Svg.vue";

export default {
    name: "TicketTaskTemplateRow",
    components: {Show,ShowSvg},
    emits: ['actionDeleteClick', 'actionEditClick', 'actionShowClick'],
    props: {
        obj: null,
        columns: null,
    }
}
</script>

<style scoped lang="scss">

.column {

  &--id { }
  &--name { }
  &--data { }
  &--create_date { }
  &--create_ip { }
  &--mod_date { }
  &--mod_ip { }
  &--buttons {
      width: 120px;
      text-align: right;
  }


}

.button{

    display: inline-block;
    margin: 0 6px;
}

</style>