//Mylib.js
import date from "./date";

let exports = {};


exports.keyval = function (arr, key_name = 'id', val_name = 'name'){

    let ret = {};

    let keys = Object.keys(arr);
    let values = Object.values(arr);
    let size = keys.length;

    for (let i = 0; i < size; i++) {

        let key_new = values[i][key_name];
        let val_new = values[i][val_name];

        ret[key_new] = val_new;
    }

    return ret;
}
exports.objToOptions = function (arr, value_key = 'id', name_key = 'name'){

    let ret = [];

    let values = Object.values(arr);
    let size = values.length;

    for (let i = 0; i < size; i++) {
        ret.push({
            value: values[i][value_key],
            name: values[i][name_key],
        })
    }

    console.log(arr,ret)

    return ret;
}
exports.kvToOptions = function (arr){

    if(!arr){
        return null;
    }

    let ret = [];

    let keys = Object.keys(arr);
    let values = Object.values(arr);
    let size = values.length;

    for (let i = 0; i < size; i++) {
        ret.push({
            value: keys[i],
            name: values[i],
        })
    }

    return ret;
}

export default exports;