<template>



  <div class="ms-1 header-item d-none d-sm-flex">
    <button
        type="button"
        class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        data-toggle="fullscreen"
        @click="initFullScreen"
        title="Pełen ekran"
    >
      <i class="bx bx-fullscreen fs-22"></i>
    </button>
  </div>

  <div class="ms-1 header-item d-none d-sm-flex">
    <button
        type="button"
        class="
                btn btn-icon btn-topbar btn-ghost-secondary
                rounded-circle
                light-dark-mode
              "
        title="Tryb nocny"
        @click="layoutModeToggle"
    >
      <i class="bx bx-moon fs-22"></i>
    </button>
  </div>

  <div class="ms-1 header-item d-none d-sm-flex">
    <button
        type="button"
        class="
                btn btn-icon btn-topbar btn-ghost-secondary
                rounded-circle
                light-dark-mode
              "
        title="Zmiana miejsca menu"
        @click="layoutTypeToggle"
    >
      <i class="bx bx-sidebar fs-22"></i>
    </button>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "NavBarBtns",

  computed: {},

  methods: {

    ...mapActions('app', ['layoutTypeToggle', 'layoutModeToggle']),

    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
          !document.fullscreenElement &&
          /* alternative standard method */ !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
  },
}
</script>

<style scoped>

</style>