<template>
    <div ref="group" class="row-many-group" :class="{
      'row-many-group--column': direction === 'column',
      'row-many-group--row': direction === 'row',
  }">
        <label class="row-many-group__item many" @change="updateValue" v-for="option in options">
            <input class="many__input"
                   type="checkbox"
                   v-model="value_arr"
                   :value="option.value"
                   :checked="values.indexOf(option.value)!==-1"/>
            <span class="many__text">{{ option.name }}</span>
        </label>
        <div class="clearfix"></div>
        <template v-if="show_select_all && options.length>1">
            <span v-if="options.length !== value_arr.length" @click="selectAll" class="button button--select-all">Wybierz wszystkie</span>
            <span v-if="options.length !== value_arr.length && value_arr.length>0"
                  class="button button--separator"> / </span>
            <span v-if="value_arr.length>0" @click="unselectAll"
                  class="button button--unselect-all">Odznacz wszystkie</span>
        </template>
    </div>


</template>

<script>
import InputBase from "./InputBase";

export default {
    name: "InputMany",
    extends: InputBase,
    emits: ["updateValue"],
    props: {
        name: {},
        value: {
            default() {
                return [];
            }
        },
        options: {
            default() {
                return [];
            }
        },
        direction: {
            default() {
                return 'column';
            }
        },
        show_select_all: {
            default() {
                return true;
            }
        },
    },

    data() {
        return {
            value_arr: [],
            response_type: 'array'
        }
    },
    methods: {
        selectAll() {
            this.value_arr = [...this.options.map(option => option.value)]
            this.updateValue()
        },
        unselectAll() {
            this.value_arr = []
            this.updateValue()
        },
        val() {
            return this.value;
        },
        focus() {
            let input = document.querySelectorAll('.row-many-group input')[0];

            input.focus();
        },
        updateValue() {

            let ret = this.value_arr;

            let name = this.name;
            let options = this.options;
            let response_type = this.response_type;

            let ret_show = [];
            this.value_arr.forEach(value => {
                let option = options.find(option => option.value === value);
                if (option)
                    ret_show.push({
                        name: option.name,
                        value: value ?? option.name
                    })
            });

            if (response_type === 'string') {
                ret = ret.join(',');
                ret_show = ret_show.join(', ');
            }

            this.$emit('updateValue', name, ret, ret_show);

        },
        updateValueArr() {

            this.response_type = 'array';

            let ret = this.value;
            if (typeof ret === 'string' || ret instanceof String) {

                ret = ret.split(",");
                this.response_type = 'string';
            }

            this.value_arr = ret;
        }
    },
    computed: {

        values() {
            let ret = this.value;
            if (typeof ret === 'string' || ret instanceof String)
                ret = ret.split(",");

            return ret ? Object.values(ret) : [];

        },

    },
    mounted() {
        this.updateValueArr();
    },
    watch: {
        value() {

            this.updateValueArr();

        }

    }
}
</script>

<style scoped lang="scss">

.button {
  &--select-all,
  &--unselect-all {
    cursor: pointer;
    user-select: none;
  }

  &--select-all {
  }

  &--unselect-all {
  }

  &--separator {
  }
}

.row-many-group {


  &--row {
  }

  &--columnt {
    display: flex;
    flex-direction: column;
  }
}

.many {
  padding-right: 12px;

  & > * {
    display: inline-block;
    float: left;
  }

  &__input {
    margin-right: 3px;
    margin-top: 2px;

  }

  &__text {
    margin-top: -2px;
  }

  &:first-child &__input {

    margin-left: 0 !important;
  }
}
</style>