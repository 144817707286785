<template>
    <div>

        <preloader ref="preloader" :class="loader_class" :name="loader_name" :progress="loader_progress"
                   v-if="loader_progress < 100"/>

        <div id="layout-wrapper" v-if="loader_progress >= 100">

            <NavBar type="domain"/>

            <SideBar type="domain"/>

            <div class="main-content">
                <div class="page-content">
                    <!-- Start Content-->
                    <div class="container-fluid">
                        <div id="app-content">
                            <slot/>
                        </div>
                    </div>
                </div>
                <Footer v-if="this.footer"/>
            </div>
          <RightBar/>
        </div>

        <ContextMenu ref="contextMenu"/>

    </div>
</template>
<script>

import NavBar from "@/layouts/part/nav-bar";
import SideBar from "@/layouts/part/side-bar";
import Footer from "@/layouts/part/footer";
import Speech from "@/Library/Controll/Speech";
import App from "@/Library/Controll/App";
import Observe from "@/Library/Controll/Observe";
import {mapActions, mapState} from "vuex";
import Preloader from "@/layouts/part/preloader";
import SystemAccessObjSet from "@/Library/System/Access/ObjSet";
import SystemAccessObjManage from "@/Library/System/Access/ObjManage";
import SystemAccessObjGroupManage from "@/Library/System/Access/Group/ObjManage";
import SystemAccessObjGroupSet from "@/Library/System/Access/Group/ObjSet";
import TicketNewModal from "@/views/Ticket/Ticket/Modal/NewModal";
import TicketInfoNew from "@/views/Ticket/Ticket/Modal/InfoNew";
import ContextMenu from "@/Library/Components/ContextMenu";
import Base from "@/Library/Extends/Base.vue";
import RightBar from "@/layouts/part/right-bar.vue";


/**
 * Vertical layout
 */
export default {
    components: {
      RightBar,
        ContextMenu,
        TicketInfoNew,
        TicketNewModal,
        SystemAccessObjGroupSet,
        SystemAccessObjGroupManage,
        SystemAccessObjManage, SystemAccessObjSet, Preloader, SideBar, NavBar, Footer
    },
    extends: Base,
    mixins: [App, Observe, Speech],
    data: () => ({
        isMenuCondensed: false,
        loader_progress: 20,
        loader_name: '',
        loader_class: '',
        loader_step: 0,
        loader_step_all: 4,
        refresh_time: 120000,
    }),
    computed: {
        ...mapState('app', ['layoutType', 'layoutMode', 'footer', 'config']),
        ...mapState('user', ['info']),
    },
    created: () => {
        document.documentElement.removeAttribute("data-layout", "horizontal");
        document.documentElement.removeAttribute("data-topbar", "dark");
        document.documentElement.removeAttribute("data-layout-size", "boxed");
    },
    methods: {
        ...mapActions('app', [
            'domainLoad',
            'domainUpdate',
            'layoutTypeSet', 'layoutModeSet', 'init', 'getApplicationServerPublicKey', "refreshingOn", "refreshingOff"]),
        ...mapActions('user', ['getUserInfo']),
        async refresh() {
            let self = this;

            this.refreshingOn();

            /**
             * Aktualizacja obiektów
             */
            this.observeCall('refresh');

            this.refreshingOff();

            setTimeout(function () {
                self.refresh();
            }, this.refresh_time);
        },
        speechTest() {
            document.documentElement.setAttribute("data-layout", "horizontal");
            document.documentElement.setAttribute("data-layout", "horizontal");
        },
        progressStep(name) {
            let progress = (this.loader_step / this.loader_step_all) * 100;

            console.log(this.loader_step, this.loader_step_all, progress)

            this.loader_progress = progress;
            this.loader_name = name;
        },

        async loadConfig() {

            let self = this;

            this.loader_step = 0;
            this.progressStep('Rozpoczęcie');

            this.loader_step++;
            this.progressStep('Inicjowanie ustawień');

            let arr_order = [];
            arr_order.push({name: 'Ładowanie domen', function: this.domainUpdate})
            arr_order.push({name: 'Ładowanie domen', function: this.domainLoad})
            arr_order.push({name: 'Pobranie ustawień', function: this.init})

            let arr_parallel_all = [];

            let arr_parallel = [];

            for (let el of arr_parallel_all) {

                let requiredArr = el.required ?? [];
                let requiredAccess = true;
                requiredArr.forEach(required => {
                    if (!this.modulesConnected(required)) {
                        requiredAccess = false;
                    }
                })

                if (requiredAccess) {
                    this.debug('Wykonuje akcję', el.name)

                    arr_parallel.push(el);
                }
                if (!requiredAccess) {
                    this.debug('Akcja pominięta', el.name)
                }

            }

            this.loader_step = 0;
            this.loader_step_all = arr_order.length + arr_parallel.length;

            for await (let el of arr_order) {

                await this.progressStep(el.name);
                this.loader_step++;

                await el.function();

            }

            for (let el of arr_parallel) {


                setTimeout(async () => {
                    await el.function();
                    await self.progressStep(el.name);
                    self.loader_step++;
                }, 1);

            }

            await this.layoutTypeSet(this.layoutType);
            await this.layoutModeSet(this.layoutMode);

            this.loader_class = 'preloader--hide';

            setTimeout(() => {

                this.loader_step++;
                this.loader_class = '';

                this.progressStep('Zakończono');

            }, 100);

            if (this.info.accept_condition === 'no'){
              this.$router.push('/condition')
            }

        }

    },
    async mounted() {

        let self = this;

        await this.loadConfig();

        this.observeAdd('refresh', this, 'updateMessages');
        this.observeAdd('refresh', this, 'updateTicketWeek');

        this.refresh_timer = setTimeout(function () {
            self.refresh();
        }, this.refresh_time);

    },
};
</script>

<style lang="scss">


#layout-wrapper {
  z-index: 1;
  position: relative;
}

#app-content {
  position: relative;
}

</style>
