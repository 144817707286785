<template>

  <div style="max-width: 1170px; margin: 0 auto;">
          <slot/>


  </div>
</template>

<script>

import router from '@/router'
import {useToast} from "vue-toastification";

export default {
  name: "Blank",
  components: {

  },
  data() {
    return {
      status: 'wait',
    }
  },
  computed: {
  },
  methods: {



  },
}
</script>

<style scoped lang="scss">


</style>