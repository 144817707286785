<template>

  <table class="table table--striped">
    <thead>
    <tr>
      <th>Użytkownik</th>
      <th>Uprawnienia</th>
    </tr>
    </thead>
    <tbody>
      <SystemAccessObjSetTableRow v-for="access in access_list"
                                  :access="access"
                                  :permissions="permissions"
                                  :id_obj="id_obj"
                                  v-on:accessUpdate="accessUpdate"/>
    </tbody>
  </table>

</template>

<script>


import axios from "@/axios";
import SystemAccessObjSetTableRow from "./ObjSetTableRow";

export default {
  name: "SystemAccessObjSetTable",
  components: {SystemAccessObjSetTableRow},
  props: [],
  emits: ['accessUpdate'],
  data: () => ({
    permissions: [],
    access_list: [],
    id_obj: null,
    window: null,
  }),
  methods: {

    objType() {
      let id_obj = this.id_obj;

      let id_obj_arr = id_obj.split("_");

      id_obj_arr.pop();

      return id_obj_arr.join("");
    },

    async refreshPermissions() {

      let self = this;

      let type = this.objType();

      let params = {
        'type': type
      }

      await axios
          .get('base/perrmissiontoobj', {'params': params})
          .then(function (result) {

            self.permissions = result.data;

          })
          .catch(function (error) {

          });

    },

    async refreshAccess() {

      let self = this;

      let id_obj = this.id_obj;

      let params = {
        'id_obj': id_obj,
        'type': 'user',
        'user_scope': 'id,name_all,avatar_url'
      }

      await axios
          .get('base/accesstoobj', {'params': params})
          .then(function (result) {

            self.access_list = result.data;

          })
          .catch(function (error) {

          });


    },
    accessUpdate(){

      this.$emit('accessUpdate', this.access_list)

    },
  },
  watch: {
    id_obj: async function (id_obj) {

      this.permissions = [];
      this.access_list = [];

      if (id_obj){
        await this.refreshPermissions()
        await this.refreshAccess()
      }
    }
  },
}
</script>

<style scoped>

</style>