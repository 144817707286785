<template>
  <span :style="'color: '+data" class="show-color">{{ data }}</span>
</template>

<script>

export default {
  name: "ShowColor",
  props: {
    data: {
      default() {
        return {};
      }
    }
  },

  computed: {
  }
}
</script>

<style scoped lang="scss">

.show-color {


}

</style>