<template>

    <ul class="switch-list">

        <li class="switch-list__item" v-for="option in options">
            <label :for="id" class="switch-item"
                   :class="{'switch-item--selected': this.optionIsSelected(option) }"
                   :style="{
                'background-color': this.optionIsSelected(option) ? (colorOption(option))  : (colorOption(option)+33) ,
                'color': (option.value === value)?'#ffffff':  colorOption(option),

            }"
                   @click="updateValue(option.value)"
            >
                <input type="radio"
                       class="switch-item__input"
                       :name="id"
                       :disabled="disabled"
                       :checked="option.value === value"
                >
                <span class="switch-item__name">{{ option.name }}</span>
            </label>
        </li>
    </ul>

</template>

<script>
import InputOptionsAbstract from "./InputOptionsAbstract.vue";

export default {
    name: "InputRadioSwitch",
    extends: InputOptionsAbstract,
    props: {
        name: {},
        value: {},
        options: {},
        disabled: false
    },
    data: () => ({
        background_color_default: '#FFAE01',
        color_default: '#465D8B',
        input_id_data: null,
    }),

    methods: {

        val() {
            return this.value;
        },

        updateValue: function (value) {

            this.$emit('updateValue', this.name, value, value);

        },

        optionByValue(value) {

            let values = [...Object.values(this.options_show)];
            return values.find(el => el.value = value);

        },

        optionIsSelected(option) {

            return option.value === this.value;

        },

        color(option, color_default) {

            let option_color = option.color;

            return option_color ? option_color : color_default;

        },

        colorOption(option) {

            let color_default = this.color_default;

            let option_selected = this.optionIsSelected(option);

            let color = this.color(option, color_default);

            return option_selected ? color : color;

        },

    },
    computed: {

        colorSelected() {

            let option = this.optionByValue(this.value);

            return option ? this.color(option, this.color_default) : this.color_default;

        },

        backgroundColorSelected() {

            let option = this.optionByValue(this.value);

            return option ? this.color(option, this.background_color_default) : this.background_color_default;

        },

        id() {

            this.input_id_data = this.input_id_data ? this.input_id_data : parseInt(Math.random() * 1000000);

            return 'input_' + this.input_id_data;

        },

        options_show() {

            let options = this.options.map(el => ({name: el.name, value: el.value}));

            return options;

        }
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
.switch-list {

  //padding: 10px;
  padding: 1px;
  border-radius: 4px;

  list-style: none;
  margin: 0;

  &__item {

    margin: 0;
    padding: 0;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

  }

}

.switch-item {

  position: relative;
  overflow: hidden;
  padding: 8px 20px;
  margin: 0 12px 0 0;

  border-radius: 8px;

  cursor: pointer;

  &--selected {
    background-color: #ffffff33;
  }

  &__input {

    position: absolute;
    top: -100px;

  }

  &__name {
    user-select: none;
  }

}

</style>