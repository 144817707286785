<template>
    <audio controls :class="this.class" ref="show">
        <source :src="audio" :type="type" v-if="audio">
        Your browser does not support the audio element.
    </audio>
</template>

<script>

export default {
    name: "ShowAudio",
    components: {},
    props: {
        audio: {},
        class: {},
    },
    data() {
        return {}
    },
    methods: {
        load() {
            let element = this.$refs.show;

            element.load();
        }
    },
    computed: {
        format() {

            let filename = this.audio;

            let filemane_is_str = (typeof filename === 'string' || filename instanceof String);

            return filename && filemane_is_str ? filename.split('.').pop() : 'mp3';

        },
        type() {
            return 'audio/' + this.format
        }
    },
    watch: {
        audio() {
            this.load();
        }
    }

}
</script>

<style scoped lang="scss">

audio {
  width: 100%;
}

.mw-600 {
  background-color: var(--sp-body-bg) !important;
  max-width: 600px;
  padding-bottom: 400px;
  border-radius: 6px;
}

</style>