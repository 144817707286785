<template>
    <InputSelect class="page-count" :options="objs_options" :options_null="true" options_null_label="Brak"
                 :value="option" @updateValue="selectValue"/>
</template>

<script>
import axios from "@/axios";
import ControllError from "@/Library/Controll/Error.vue";
import ControllToast from "@/Library/Controll/Toast.vue";
import TemplateCard from "@/Library/Template/Card.vue";
import Table from "@/Library/Show/Table.vue";
import ViewList from "@/Library/Extends/ViewList.vue";
import Alert from "@/Library/Components/Alert.vue";
import TicketTaskTemplateRow from "@/views/Ticket/TaskTemplate/Row.vue";
import TicketTaskTemplateNew from "@/views/Ticket/TaskTemplate/New.vue";
import TicketTaskTemplateEdit from "@/views/Ticket/TaskTemplate/Edit.vue";
import InputSelect from "@/Library/Forms/InputSelect.vue";

export default {
    name: "TicketTaskTemplateSelect",
    emit: ['addTasks'],
    components: {
        InputSelect
    },
    extends: ViewList,
    mixins: [ControllError, ControllToast],

    data: () => ({

        option: null,

        /**
         * Tablica obiektów
         */
        objs: [],

        /**
         * Kolumna sortowania
         */
        sort: 'name',

        /**
         * Kierunek sortowania
         */
        sort_type: "DESC",

        /**
         * Adres do pobrania obiektów
         */
        get_url: 'tickettask/templateGet',

        /**
         * Tablica wytycznych do pobrania obiektów
         */
        get_params: {},

    }),

    computed: {
        objs_options() {
            return this.objs.map(el => ({value: el.id, name: el.name}))
        },
    },

    methods: {

        selectValue(name, value) {

            this.option = value;

            let task_template = this.objs.find(el => el.id === value);

            if (task_template) {
                this.$emit('addTasks', task_template.data);

                this.option = null;
            }

        },

        /**
         * Ładuje dane
         * @returns {Promise<void>}
         */
        async load() {

            let url = this.get_url;

            let params = this.get_params;

            this.stateUpdate('sendInfo');

            let result_data = await axios.get(url, {params: params})
                .then(result => result.data)
                .catch(error => this.errorCatch(error))

            if (result_data) {
                this.stateUpdate('sendSuccess');
                this.objs = result_data.data;
            }

            if (!result_data) {
                this.stateUpdate('sendWrong');
            }
        },
    },

    /**
     * Akcje związane z zamonotowaniem komponentu
     */
    mounted() {
        this.load();
    }
}
</script>