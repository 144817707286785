<template>

    <div class="row-nice__tags tags">

        <vue3-tags-input class="tags__input" :tags="value_local"
                         :placeholder="placeholder"
                         @on-tags-changed="updateValue"/>
    </div>
</template>

<script>

import Vue3TagsInput from 'vue3-tags-input';
import InputBase from "@/Library/Forms/InputBase.vue";

export default {
    name: "InputTags",
    extends: InputBase,
    components: {
        Vue3TagsInput
    },

    props: {
        name: {},
        value: {},
        class: {
            default() {
                return 'row-nice__tags'
            }
        },
        placeholder: {
            default() {
                return 'Wpisz treść';
            }
        },
    },

    data: () => ({
        value_local: [],
        value_type: null,
        value_separator: ',',
    }),
    methods: {
        updateValue() {

            this.$emit('updateValue', this.name, this.getValue(), this.getValue())

        },

        loadValue() {

            let value = this.value;

            if (typeof value === 'string' || value instanceof String) {
                this.value_local = value.split(this.value_separator);
                this.value_type = 'string';
            }

            if (Array.isArray(value)) {
                this.value_local = [...value];
                this.value_type = 'array';
            }

        },

        getValue() {

            let ret = null;

            if (this.value_type === 'array')
                ret = [...this.value_local];

            if (this.value_type === 'array')
                ret = this.value_local.join(this.value_separator);

            return ret;
        },
    },

    mounted() {

        this.loadValue();

    },

    watch: {
        value() {
            this.loadValue();
        }
    },

}
</script>

<style lang="scss">


.v3ti {
  background-color: transparent;
  border: none;
    margin-top: 4px;

    &--focus{
        border: none!important;

        outline: none!important;
        box-shadow: none!important;

    }

  & &-content {
  }

  & &-tag {
    background-color: #f5a804;
    /*border: 1px solid #222222;*/
    /*border-radius: 0;*/

    padding: 12px 6px 12px 12px;
    margin: 0 3px 0 0;
  }

  & &-new-tag {
      margin: 0;
  }

  & &-tag &-remove-tag {
    color: #fff;
    transition: color .3s;

    &:hover {
      color: #ffffff;
    }

  }
}

.tags {
  &__input {
    z-index: 10000;
  }
}

</style>