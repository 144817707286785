<script>

import {mapState} from 'vuex';

export default {
  name: "ControllLang",
  data: () => ({

    lang_default: 'pl'
  }),
  methods: {

    langGet(){

      let LangLS = this.lang;

      return LangLS !== undefined ? LangLS : this.lang_default;

    },

    t(code){

      let lang = this.langGet();
      let lang_data = this.lang_arr;

      let ret = lang_data[lang] !== undefined && lang_data[lang][code] !== undefined ? lang_data[lang][code] : "";

      if(ret === undefined){
        ret = lang_data[this.lang_default][code];
        console.log('[Brak tłumaczenia] Język:'+lang+' Code:'+code)
      }

      if(ret === undefined){
        ret = "";
        console.log('[Brak tłumaczenia] Język:'+this.lang_default+' Code:'+code)
      }

      return ret;


    },

    to(object, column){

      let ret = null;

      let lang = this.langGet();

      let texts = object['langs'];

      if(this.lang_default === this.langGet()) {
        ret = object[column];

        if (column === 'title' && ret === undefined){
          ret = object['name'];
        }
      }

      if( texts !== undefined && texts[lang] !== undefined  && texts[lang][column] !== undefined )
        ret = texts[lang][column];

      console.log(column,lang,object,ret)

      return ret;

    }
  },
  computed: {
    ...mapState('app',['lang_arr', 'lang'])

  },
  watch: {

  },
  mounted() {

  },
  unmounted() {
  }

}
</script>
