<template>

  <InputDate ref="input"
             v-if="type === 'date'"
             :name="name"
             :value="value_local"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             @actionKeyDown="actionKeyDown"
             placeholder=" "
             :disabled="disabled"
             :validate-required="this.validateRequired"
  />
  <InputDateTime ref="input"
                 v-if="type === 'datetime'"
                 :name="name"
                 :value="value_local"
                 @updateValidate="updateValidate"
                 @updateValue="updateValue"
                 placeholder=" "
                 :validate-required="this.validateRequired"
  />
  <InputTime ref="input"
             v-if="type === 'time'"
             :name="name"
             :value="value_local"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             @actionKeyDown="actionKeyDown"
             :validate-required="this.validateRequired"
  />
  <InputWeek ref="input"
             v-if="type === 'week'"
             :name="name"
             :value="value_local"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             @actionKeyDown="actionKeyDown"
             :validate-required="this.validateRequired"
  />
  <InputMonth ref="input"
              v-if="type === 'month'"
              :name="name"
              :value="value_local"
              @updateValidate="updateValidate"
              @updateValue="updateValue"
              @actionKeyDown="actionKeyDown"
              :validate-required="this.validateRequired"
  />
  <InputTextarea ref="input"
                 v-if="type === 'textarea'"
                 :name="name"
                 :value="value_local"
                 @updateValidate="updateValidate"
                 @updateValue="updateValue"
                 @actionKeyDown="actionKeyDown"
                 placeholder=" "
                 :validate-required="this.validateRequired"
  />
  <InputEditor ref="input"
               v-if="type === 'editor'"
               :name="name"
               :value="value_local"
               @updateValidate="updateValidate"
               @updateValue="updateValue"
               @actionKeyDown="actionKeyDown"
               placeholder=" "
               :validate-required="this.validateRequired"
  />
  <InputText ref="input"
             v-if="type === 'text'"
             :name="name"
             :value="value_local"
             :disabled="disabled"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             @actionKeyDown="actionKeyDown"
             :id="id"
             :class="this.class"
             :placeholder="placeholder"
             :autocomplete="autocomplete"
             :validate-is-equal-to="this.validateIsEqualTo"
             :validate-min="this.validateMin"
             :validate-max="this.validateMax"
             :validate-mail="this.validateMail"
             :validate-nip="this.validateNip"
             :validate-required="this.validateRequired"
             :validate-unique="this.validateUnique"
  />
  <InputIcon ref="input"
             v-if="type === 'icon'"
             :name="name"
             :value="value_local"
             :disabled="disabled"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             @actionKeyDown="actionKeyDown"
             :id="id"
             :placeholder="placeholder"
             :validate-required="this.validateRequired"
  />
  <InputTextList ref="input"
                 v-if="type === 'textlist'"
                 :name="name"
                 :value="value_local"
                 :disabled="disabled"
                 @updateValidate="updateValidate"
                 @updateValue="updateValue"

                 :id="id"
                 :placeholder="placeholder"
                 :validate-required="this.validateRequired"

  />
  <InputTags ref="input"
             v-if="type === 'tags'"
             :name="name"
             :value="value_local"
             :disabled="disabled"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             @actionKeyDown="actionKeyDown"
             :id="id"
             :class="this.class"
             :placeholder="placeholder"
             :validate-required="this.validateRequired"

  />
  <InputPassword ref="input"
                 v-if="type === 'password'"
                 :name="name"
                 :value="value_local"
                 @updateValidate="updateValidate"
                 @updateValue="updateValue"
                 @actionKeyDown="actionKeyDown"
                 :id="id" placeholder=" "
                 :validate-required="this.validateRequired"
                 :validate-is-equal-to="this.validateIsEqualTo"
  />
  <InputMail ref="input"
             v-if="type === 'mail'"
             :name="name"
             :value="value_local"
             :disabled="disabled"
             :pattern="pattern"
             :multiple="multiple"
             :autocomplete="autocomplete"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             @actionKeyDown="actionKeyDown"
             :id="id" :placeholder="placeholder"
             :validate-required="validateRequired"
             :validate-mail="validateMail"
             :validate-unique="validateUnique"
  />
  <InputTel ref="input"
            v-if="type === 'tel' || type === 'phone'"
            :name="name"
            :value="value_local"
            :disabled="disabled"
            :pattern="pattern"
            @updateValidate="updateValidate"
            @updateValue="updateValue"
            @actionKeyDown="actionKeyDown"
            :id="id" :placeholder="placeholder"
            :validate-required="validateRequired"
            :validate-mail="validateMail"
  />
  <InputUrl ref="input"
            v-if="type === 'url' || type === 'link'"
            :name="name"
            :value="value_local"
            :disabled="disabled"
            :pattern="pattern"
            @updateValidate="updateValidate"
            @updateValue="updateValue"
            @actionKeyDown="actionKeyDown"
            :id="id" :placeholder="placeholder"
            :validate-required="validateRequired"
  />
  <InputNumber ref="input"
               v-if="type === 'number'"
               :name="name"
               :value="value_local"
               @updateValidate="updateValidate"
               @updateValue="updateValue"
               @actionKeyDown="actionKeyDown"
               :id="id" placeholder=" "
               :validate-required="this.validateRequired"
               :validate-min="this.validateMin"
               :validate-max="this.validateMax"
               :validate-nip="this.validateNip"
               :min="this.min"
               :max="this.max"
               :step="this.step"
  />
  <InputInt ref="input"
            v-if="type === 'int'"
            :name="name"
            :value="value_local"
            @updateValidate="updateValidate"
            @updateValue="updateValue"
            @actionKeyDown="actionKeyDown"
            :id="id" placeholder=" "
            :validate-required="validateRequired"
            :validate-min="validateMin"
            :validate-max="validateMax"
            :min="min" :max="max" :step="step"
  />

  <InputPrice ref="input"
              v-if="type === 'price'"
              :name="name"
              :value="value_local"
              @updateValidate="updateValidate"
              @updateValue="updateValue"
              @actionKeyDown="actionKeyDown"
              :id="id" placeholder=" "
              :validate-required="validateRequired"
              :validate-min="validateMin"
              :validate-max="validateMax"
              :currency="unit"
              :min="min" :max="max" :step="step"
  />

  <InputAutocomplete ref="input"
                     v-if="type === 'autocomplete'"
                     :id="id"
                     :name="name"
                     :value="value_local"
                     :value_show="value_show"
                     :url="url"
                     :url_parameter_name="url_parameter_name"
                     :url_parameters="url_parameters"
                     @updateValidate="updateValidate"
                     @updateValue="updateValue"
                     @actionKeyDown="actionKeyDown"
                     @focusout="$emit('focusout')"
                     placeholder=" "
                     :validate-required="this.validateRequired"
  />

  <InputObjs ref="input"
             v-if="type === 'objs'"
             :id="id"
             :name="name"
             :value="value_local"
             :url="url"
             :url_parameter_name="url_parameter_name"
             :url_parameters="url_parameters"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             :validate-required="this.validateRequired"
  />

  <InputSelect ref="input"
               v-if="type === 'select'"
               :name="name"
               :value="value_local"
               :options="options"
               :options_null="options_null"
               :options_null_label="options_null_label"
               @updateValidate="updateValidate"
               @updateValue="updateValue"
               :disabled="disabled"
               placeholder=" "
               :validate-required="this.validateRequired"
  />

  <InputRadio ref="input"
              v-if="type === 'radio'"
              :name="name"
              :value="value_local"
              :options="options"
              :disabled="disabled"
              @updateValidate="updateValidate"
              @updateValue="updateValue"
              :validate-required="this.validateRequired"
  />

  <InputRadioSwitch ref="input"
                    v-if="type === 'radio-switch'"
                    :class="this.class"
                    :disabled="disabled"
                    :name="name"
                    :options="options"
                    :value="value_local"
                    @updateValidate="updateValidate"
                    @updateValue="updateValue"
                    :validate-required="this.validateRequired"/>

  <InputCheckbox ref="input"
                 v-if="type === 'checkbox'"
                 :name="name"
                 :value="value_local"
                 :value_input="value_input"
                 :value_input_false="value_input_false"
                 :options="options"
                 :label="label"
                 :disabled="disabled"
                 @updateValidate="updateValidate"
                 @updateValue="updateValue"
                 :validate-required="this.validateRequired"
  />
  <InputToggle ref="input"
               v-if="type === 'toggle'"
               :id="id"
               :name="name"
               :value="value_local"
               :value_input="value_input"
               :value_input_false="value_input_false"
               :label="label"
               :disabled="disabled"
               @updateValidate="updateValidate"
               @updateValue="updateValue"
               :validate-required="this.validateRequired"
  />
  <InputYesNo ref="input"
              v-if="type === 'yesno'"
              :id="id"
              :name="name"
              :value="value_local"
              :label="label"
              @updateValidate="updateValidate"
              @updateValue="updateValue"
              :validate-required="this.validateRequired"
  />
  <InputMany ref="input"
             v-if="type === 'many'"
             :name="name"
             :value="value_local"
             :options="options"
             @updateValidate="updateValidate"
             @updateValue="updateValue"
             :validate-required="this.validateRequired"
  />
  <InputColor ref="input"
              v-if="type === 'color'"
              :name="name"
              :value="value_local"
              @updateValidate="updateValidate"
              @updateValue="updateValue"
              :validate-required="this.validateRequired"/>
  <InputRange ref="input"
              v-if="type === 'range'"
              :name="name"
              :value="value_local"
              @updateValidate="updateValidate"
              @updateValue="updateValue"
              @actionKeyDown="actionKeyDown"
              :id="id"
              :validate-required="this.validateRequired"
              :validate-min="this.validateMin"
              :validate-max="this.validateMax"
              :min="this.min"
              :max="this.max"
              :step="this.step"/>

  <InputImage
      v-if="type === 'image'"
      :name="name"
      :id="id"
      :value="value_local"
      :class="this.class"
      :show_class="this.show_class"
      :value_show="value_show"
      :config="config"
      @updateValidate="updateValidate"
      @updateValue="updateValue"
  />

  <InputAudio
      v-if="type === 'audio'"
      :name="name"
      :id="id"
      :value="value_local"
      :class="this.class"
      :show_class="this.show_class"
      :value_show="value_show"
      :config="config"
      @updateValidate="updateValidate"
      @updateValue="updateValue"
  />

  <InputVideo
      v-if="type === 'video'"
      :name="name"
      :id="id"
      :value="value_local"
      :class="this.class"
      :show_class="this.show_class"
      :value_show="value_show"
      :config="config"
      @updateValidate="updateValidate"
      @updateValue="updateValue"
  />

  <InputGallery
      v-if="type === 'gallery'"
      :name="name"
      :id="id"
      :value="value_local"
      :class="this.class"
      :show_class="this.show_class"
      :value_show="value_show"
      :config="config"
      :version="version"
      @updateValidate="updateValidate"
      @updateValue="updateValue"
  />

  <InputFile
      v-if="type === 'file'"
      :version="version"
      :name="name"
      :id="id"
      :value="value_local"
      :class="this.class"
      :show_class="this.show_class"
      :value_show="value_show"
      :config="config"
      @updateValidate="updateValidate"
      @updateValue="updateValue"
  />

</template>

<script>
import InputPrice from "@/Library/Forms/InputPrice";
import InputInt from "@/Library/Forms/InputInt";
import InputUrl from "@/Library/Forms/InputUrl";
import InputTel from "@/Library/Forms/InputTel";
import InputMail from "@/Library/Forms/InputMail";
import InputTime from "@/Library/Forms/InputTime";
import InputMonth from "@/Library/Forms/InputMonth";
import InputWeek from "@/Library/Forms/InputWeek";
import InputRange from "@/Library/Forms/InputRange";
import InputColor from "@/Library/Forms/InputColor";
import InputDateTime from "@/Library/Forms/InputDateTime";
import InputPassword from "@/Library/Forms/InputPassword";
import InputToggle from "@/Library/Forms/InputToggle";
import InputNumber from "@/Library/Forms/InputNumber";
import InputCheckbox from "@/Library/Forms/InputCheckbox";
import InputEditor from "@/Library/Forms/InputEditor";
import InputMany from "@/Library/Forms/InputMany";
import InputRadio from "@/Library/Forms/InputRadio";
import InputSelect from "@/Library/Forms/InputSelect";
import InputAutocomplete from "@/Library/Forms/InputAutocomplete";
import InputDate from "@/Library/Forms/InputDate";
import InputTextarea from "@/Library/Forms/InputTextarea";
import InputText from "@/Library/Forms/InputText";
import InputImage from "@/Library/Forms/InputImage";
import InputFile from "@/Library/Forms/InputFile";
import InputAudio from "@/Library/Forms/InputAudio";
import InputVideo from "@/Library/Forms/InputVideo";
import InputGallery from "@/Library/Forms/InputGallery";
import InputYesNo from "@/Library/Forms/InputYesNo";
import InputObjs from "@/Library/Forms/InputObjs.vue";
import InputRadioSwitch from "@/Library/Forms/InputRadioSwitch.vue";
import InputTextList from "@/Library/Forms/InputTextList.vue";
import InputTags from "@/Library/Forms/InputTags.vue";
import InputIcon from "@/Library/Forms/InputIcon.vue";

export default {
  name: "Input",
  components: {
    InputIcon,
    InputTags,
    InputTextList,
    InputRadioSwitch,
    InputObjs,
    InputYesNo,
    InputGallery,
    InputVideo,
    InputAudio,
    InputFile,
    InputImage,
    InputPrice,
    InputInt,
    InputUrl,
    InputTel,
    InputMail,
    InputTime,
    InputMonth,
    InputWeek,
    InputRange,
    InputColor,
    InputDateTime,
    InputPassword,
    InputToggle,
    InputNumber,
    InputCheckbox,
    InputEditor, InputMany, InputRadio, InputSelect, InputAutocomplete, InputDate, InputTextarea, InputText
  },
  props: [
    'id', 'type', 'version', 'label', 'name', 'icon', 'value', 'value_input', 'value_input_false', 'value_show', 'url', 'url_parameter_name', 'url_parameters', 'options',
    'options', 'options_null', 'options_null_label', 'placeholder', 'disabled', 'pattern', 'autocomplete',
    'validate-required',
    'validate-unique',
    'validate-is-equal-to', 'validate-min', 'validate-max', 'validate-nip', 'validate-mail', 'validate-postalCode',
    'min', 'max', 'step', 'unit', 'config', 'class', 'show_class'
  ],
  emits: ["actionKeyDown", "updateValue", "updateValidate"],
  data: () => ({
    value_local: null,
  }),
  methods: {


    input: function () {
      return this.$refs.input;
    },

    updateValue: function (name, value, value_show) {

      this.value_local = value;

      let input = this.input();

      this.$emit('updateValue', this.name, value, value_show);

      if (this.$parent.valid_test === true) {

        setTimeout(function () {
          input.validate();
        }, 10)
      }
    },
    actionKeyDown: function (event, name, value, value_show) {

      this.$emit('actionKeyDown', event, name, value, value_show);

    },

    updateValidate: function (validate_arr) {

      this.$emit('updateValidate', validate_arr)

    },

    validate: async function () {
      let input = this.input();
      // this.updateValidate(validate_arr);

      return await input.validate();
    },

    validateReset: function () {

      if (this.$refs.input)
        this.$refs.input.validateReset();

    },

    refresh: function () {
      // this.value = this.$parent.$data[this.name];
    },

    focus: function () {
      // this.value = this.$parent.$data[this.name];

      this.$refs['input'].focus();

    },

    clear: function () {
      // this.value = this.$parent.$data[this.name];

      this.$refs['input'].clear();

    }
  },

  mounted() {
    this.value_local = this.value;
  },

  watch: {
    value(newValue) {
      this.value_local = newValue
    },
  }
}
</script>

<style scoped>

</style>