<template>
    <div class="letter-avatar"  :style="{'backgroundColor': color}">{{ acronim }}</div>
</template>

<script>

import CoreString from "@/Library/Helpers/string";

export default {
    name: "ShowLetterAvatar",
    props: {
        text: {},
    },
    computed: {
        acronim() {
            let text = this.text ? this.text : "";

            let textArr = text.split(" ");

            let short = textArr.map(textEl => textEl.charAt(0))

            return short.join("");
        },

        firstLetter() {

            let acronim = this.acronim;

            return acronim.charAt(0);

        },

        color() {

            let letter = this.firstLetter;

            return CoreString.letterColor(letter);

        }
    }
}
</script>

<style scoped lang="scss">

.letter-avatar {

  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Colors */
  background-color: #d1d5db;
  color: #fff;

  /* Rounded border */
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
}

</style>