<template>

</template>

<script>
import {mapActions, mapState} from "vuex";

import ControllLang from '@/Library/Controll/Lang.vue';

import CryptoJS from 'crypto-js';

export default {
  name: "Base",
  mixins: [ControllLang],
  computed: {
    ...mapState('app', ['config', 'modules', 'initial', 'perspective']),
    ...mapState('user', ['info']),
  },
  data: () => ({
    debug_on: true,
    key: '1234567890123456',
  }),
  methods: {
    ...mapActions('app', ['perspectiveIn']),

    debug(...args) {

      if (this.debug_on) {
        console.log(...args)
      }

    },

    encrypt(text){
      let key = this.key;
      let iv = this.key;

      return CryptoJS.AES.encrypt(text,key,{iv: iv}).toString();
    },
    decrypt(code){
      return CryptoJS.AES.decrypt(code,this.key).toString(this.$CryptoJS.enc.Utf8);

    },

    app() {
      return this.$root.$refs.app;
    },


    initGet(name, def = 'no') {

      let val = this.initial[name] ?? def;

      this.debug('Pobranie informacji z ustawień zainicjowanych', name, val, def);

      return val;
    },

    initIs(name, value_test = 'yes', def = 'no') {

      let val = this.initGet(name, def);

      let test = val === value_test;

      this.debug('Sprawdzenie czy wartość inicjująca ' + name + ' jest równa ' + value_test + ' wynik ' + test)

      return test;
    },

    initIsYes(name, def = 'no') {

      return this.initIs(name, 'yes', def);

    },

    configGet(name, def = 'no') {

      let val = this.config[name] ?? def;

      this.debug('Pobranie informacji z ustawień aplikacji', name, val, def);

      return val;

    },
    configIs(name, value_test = 'yes', def = 'no') {


      let val = this.configGet(name, def);

      let test = val === value_test;

      this.debug('Sprawdzenie czy wartość konfiguracji ' + name + ' jest równa ' + value_test + ' wynik ' + test)

      return test;


    },
    configIsYes(name, def = 'no') {
      return this.configIs(name, 'yes', def);

    },

    modulesConnected(name) {

      return !!this.modules.find(el => el === name);

    },


    perspectiveIn(data) {

      let user_roles = this.info.role;
      let perspective = this.perspective;

      let needs = data;
      if (typeof data === 'string' || data instanceof String) {
        needs = data.split(",");
      }

      let ret = needs.includes(perspective);

      return ret;

    },
  }
}
</script>

<style scoped>

</style>