<template>
  <div class="progress progress-bar-primary" :class="this.class" :style="this.style">
    <div class="progress-bar" role="progressbar" :aria-valuenow="data" :aria-valuemin="data" aria-valuemax="100" :style="{'width': data+'%'}" aria-describedby="example-caption-2"></div>
  </div>
</template>

<script>
import ShowPercent from "@/Library/Show/Percent";
export default {
  name: "ShowProgress",
  components: {ShowPercent},
  props: {
    data: {},
    class: {},
    style: {},
  },

}
</script>

<style scoped lang="scss">

.progress{
  max-width: 100%;
  &-bar-primary{

  }
}

</style>