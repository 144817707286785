import axios from "@/axios";

const state = {
    domain: JSON.parse(localStorage.getItem('domain')) || {},
    domains: JSON.parse(localStorage.getItem('domains')) || [],
    domain_selected_id: localStorage.getItem('domain_selected_id') || null,
    config: JSON.parse(localStorage.getItem('config')) || [],
    initial: JSON.parse(localStorage.getItem('initial')) || [],
    modules: JSON.parse(localStorage.getItem('modules')) || [],
    menu: JSON.parse(localStorage.getItem('menu')) || [],
    privileges: JSON.parse(localStorage.getItem('privileges')) || [],
    perspective: localStorage.getItem('perspective') || null,
    refreshing: false,
    footer: true,
    scroll: true,
    layoutType: localStorage.getItem('layoutType') || 'vertical',
    layoutMode: localStorage.getItem('layoutMode') || 'light',
    layoutSidebarColor: localStorage.getItem('layoutSidebarColor') || 'dark',
    layoutSidebarView: localStorage.getItem('layoutSidebarView') || 'dark',
    layoutSidebarSize: localStorage.getItem('layoutSidebarSize') || 'lg',
    layoutPosition: localStorage.getItem('layoutPosition') || 'fixed',
    layoutTopbar: localStorage.getItem('layoutTopbar') || 'dark',
    menu_open: localStorage.getItem('menu_open') || false,
    application_server_public_key: localStorage.getItem('application_server_public_key') || null,
    push: localStorage.getItem('push') || 'yes',
};

const actions = {
    refreshingOn({commit, data}) {
        commit('refreshing', true);
    },

    refreshingOff({commit, data}) {
        commit('refreshing', false);
    },

    footerShow({commit, data}) {
        commit('footer', true);
    },

    footerHide({commit, data}) {
        commit('footer', false);
    },

    scrollShow({commit, data}) {
        commit('scroll', true);

        let body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'auto';

    },

    scrollHide({commit, data}) {
        commit('scroll', false);

        let body = document.getElementsByTagName('body')[0];
        body.style.overflowY = 'hidden';
    },

    perspectiveIn({commit}, data) {

        let user_roles = this.state.user.info.role;
        let perspective = this.state.app.perspective;

        let needs = data;
        if (typeof data === 'string' || data instanceof String) {
            needs = [];
            needs.push(data);
        }

        let ret = needs.includes(perspective);

        console.log(perspective, needs, ret)

        return ret;

    },

    perspectiveChange({commit}, data) {

        localStorage.setItem('perspective', data);
        commit('perspective', data);

    },

    menuToggle({commit}) {

        let menu_open = localStorage.getItem('menu_open') || false;

        menu_open = (menu_open === 'true');

        menu_open = !menu_open;

        localStorage.setItem('menu_open', menu_open);
        commit('menu_open', menu_open);

        let body = document.getElementsByTagName('body')[0];
        body.classList.remove('menu-expanded')
        body.classList.remove('menu-collapsed')
        body.classList.remove('vertical-overlay-menu')
        body.classList.remove('vertical-menu-modern')
        body.classList.remove('menu-open')
        body.classList.remove('menu-close')

        let cls_open = 'menu-expanded';
        let cls_close = 'menu-collapsed';

        if (window.innerWidth > 1200) {

            body.classList.add('vertical-menu-modern')
        }
        if (window.innerWidth <= 1200) {
            cls_open = 'menu-open';
            cls_close = 'menu-close';

            body.classList.add('vertical-overlay-menu')
        }

        if (menu_open) {
            body.classList.add(cls_open);
        }
        if (!menu_open) {
            body.classList.add(cls_close);
        }
        setTimeout(function () {


            body.classList.remove('changing-menu');


        }, 2000)


        console.log(menu_open)


    },

    /**
     * W sytuacji w której nie ma wybranego serwisu, pobiera pierwszą z listy
     *
     * @param commit
     * @param state
     * @param dispatch
     *
     * @returns {Promise<void>}
     */
    async domainLoad({commit, state, dispatch}) {

        let selected = state.domain_selected_id;

        let domains = state.domains;

        if(!selected){

            let id = domains[0]?.id;
            dispatch("domainSelect", id)

        }

    },

    async domainUpdate({commit, dispatch}) {

        let url = 'domain/get';

        let params = {
            status: 'live',
            type: 'service',
            login_user: true,
            sort: ['is_template ASC','id DESC'],
        };

        let domains = await axios
            .get(url, {params: params})
            .then(result => result.data.data)
            .catch(error => this.errorCatch(error))

        localStorage.setItem('domains', JSON.stringify(domains));
        commit('domains', domains);

    },

    async domainSelect({commit, dispatch}, id_domain) {

        await localStorage.setItem('domain_selected_id', id_domain);
        await commit('domain_selected_id', id_domain);

        delete axios.defaults.headers['Domain-id'];
        axios.defaults.headers['Domain-id'] = id_domain;
    },

    async init({commit, dispatch}) {

        await axios
            .get('base/init')
            .then(response => {

                let data = response.data;


                let config = data.config;
                let domain = data.domain;
                let initial = data.initial;
                let modules = data.modules;
                let menu = data.menu;
                let privileges = data.privileges;

                localStorage.setItem('domain', JSON.stringify(domain));
                commit('domain', domain);

                localStorage.setItem('config', JSON.stringify(config));
                commit('config', config);

                localStorage.setItem('initial', JSON.stringify(initial));
                commit('initial', initial);

                localStorage.setItem('modules', JSON.stringify(modules));
                commit('modules', modules);

                localStorage.setItem('menu', JSON.stringify(menu));
                commit('menu', menu);

                localStorage.setItem('privileges', JSON.stringify(privileges));
                commit('privileges', privileges);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, {root: true})
                        break;
                }


            });
    },

    async initMenu({commit, dispatch}) {

        await axios
            .get('base/menu')
            .then(response => {

                let data = response.data;

                let menu = data.menu;

                localStorage.setItem('menu', JSON.stringify(menu));
                commit('menu', menu);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, {root: true})
                        break;
                }


            });
    },

    async getConfig({commit, dispatch}) {

        await axios
            .get('base/config')
            .then(response => {

                let data = response.data;

                let config = data.data;

                localStorage.setItem('config', JSON.stringify(config));
                commit('config', config);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, {root: true})
                        break;
                }


            });
    },

    configUpdate(name, value) {

        // let config = this.$.s

    },

    layoutTypeToggle({commit, dispatch}) {

        let htmlDOM = document.documentElement;

        let layoutType = htmlDOM.getAttribute("data-layout") === "vertical" ? 'vertical' : 'horizontal';

        let layoutTypeNew = layoutType === "vertical" ? "horizontal" : "vertical";

        localStorage.setItem('layoutType', layoutTypeNew);
        commit('layoutType', layoutTypeNew);

        dispatch('app/layoutTypeSet', layoutTypeNew, {root: true})

    },

    layoutTypeSet({commit}, data) {

        let htmlDOM = document.documentElement;

        data = data ? data : 'vertical';

        htmlDOM.setAttribute("data-layout", data);

    },

    layoutModeToggle({commit, dispatch}) {

        let htmlDOM = document.documentElement;

        let layoutMode = htmlDOM.getAttribute("data-layout-mode") === "dark" ? 'dark' : 'light';

        let layoutModeNew = layoutMode === "dark" ? "light" : "dark";

        localStorage.setItem('layoutMode', layoutModeNew);
        commit('layoutMode', layoutModeNew);

        dispatch('app/layoutModeSet', layoutModeNew, {root: true})

    },

    layoutModeSet({commit}, data) {

        let htmlDOM = document.documentElement;

        data = data ? data : 'light';

        htmlDOM.setAttribute("data-layout-mode", data);

    },

    layoutSidebarSizeSet({commit}, data) {

        let layoutType = this.state.app.layoutType;

        let layoutSidebarSize = this.state.app.layoutSidebarSize;

        let windowSize = document.documentElement.clientWidth;

        let documentDOM = document.documentElement;

        let bodyCLL = document.body.classList;

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle("open");

        //For collapse horizontal menu
        if (layoutType === "horizontal") {
            bodyCLL.contains("menu") ? bodyCLL.remove("menu") : bodyCLL.add("menu");
        }

        //For collapse vertical menu
        if (layoutType === "vertical") {
            if (windowSize < 1025 && windowSize > 767) {
                bodyCLL.remove("vertical-sidebar-enable");
                documentDOM.getAttribute("data-sidebar-size") === "sm"
                    ? documentDOM.setAttribute("data-sidebar-size", "")
                    : documentDOM.setAttribute("data-sidebar-size", "sm");
            } else if (windowSize > 1025) {
                bodyCLL.remove("vertical-sidebar-enable");
                documentDOM.getAttribute("data-sidebar-size") === "lg"
                    ? documentDOM.setAttribute("data-sidebar-size", "sm")
                    : documentDOM.setAttribute("data-sidebar-size", "lg");
            } else if (windowSize <= 767) {
                bodyCLL.add("vertical-sidebar-enable");
                documentDOM.setAttribute("data-sidebar-size", "lg");
            }
        }


    },

    async getApplicationServerPublicKey({commit}) {

        let headers = {
            'token': localStorage.getItem('token')
        }

        await axios
            .get('base/pushpublickkey', {'headers': headers})
            .then(response => {

                let data = response.data.data;
                let application_server_public_key = data.key;

                localStorage.setItem('application_server_public_key', application_server_public_key);
                commit('application_server_public_key', application_server_public_key);

            })
            .catch(error => {
            });
    },

    async pushSet({commit, push}) {

        localStorage.setItem('push', push);
        commit('push', push);

    },
};

const mutations = {

    perspective(state, perspective) {
        state.perspective = perspective;
    },

    refreshing(state, refreshing) {
        state.refreshing = refreshing;
    },

    footer(state, footer) {
        state.footer = footer;
    },

    scroll(state, scroll) {
        state.scroll = scroll;
    },

    domains(state, domains) {
        state.domains = domains;
    },

    domain_selected_id(state, domain_selected_id) {
        state.domain_selected_id = domain_selected_id;
    },

    domain(state, domain) {
        state.domain = domain;
    },

    config(state, config) {
        state.config = config;
    },

    initial(state, initial) {
        state.initial = initial;
    },

    modules(state, modules) {
        state.modules = modules;
    },

    menu(state, menu) {
        state.menu = menu;
    },

    menu_open(state, menu_open) {
        state.menu_open = menu_open;
    },

    privileges(state, privileges) {
        state.privileges = privileges;
    },

    layoutType(state, layoutType) {
        state.layoutType = layoutType;
    },

    layoutMode(state, layoutMode) {
        state.layoutMode = layoutMode;
    },

    application_server_public_key(state, application_server_public_key) {
        state.application_server_public_key = application_server_public_key;
    },

    push(state, push) {
        state.push = push;
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};