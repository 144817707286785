<template>


  <div class="options"
       :class="{'options__hide':is_hide}">
    <ul class="options-menu" :style="{ left: leftVal, top: topVal }" ref="optionsMenu">
      <li class="options-menu__item" v-for="column in columns_temp">

        <label class="toggle">
          <input class="toggle__input" type="checkbox" v-model="column.show" v-on:change="updateColumns"/>
          <label class="toggle__item" :for="column.key"></label>
          <span class="toggle__name">{{ column.label }}</span>
        </label>

      </li>
    </ul>
    <div class="options__bg" @click="close"></div>
  </div>
</template>

<script>
import Options from "@/Library/Components/Options";

export default {
  name: "TableOptions",
  extends: Options,
  data() {
    return {
      'columns_temp': []
    }
  },
  props: ['columns'],
  emits: ['updateColumns'],
  methods: {
    none(e) {
      e.preventDefault();
    },
    updateColumns() {
      this.$emit('updateColumns', this.columns_temp)
      return false;
    }
  },

  watch: {
    columns(val) {
      this.columns_temp = val
    }
  },
  mounted() {
    this.columns_temp = this.columns
  }
}
</script>

<style scoped lang="scss">
.options {
  //position: fixed;
}
.options-menu {
  //margin: 10px 0;
  padding: 10px 0;


  &__item {
    padding: 0 12px;
    border-radius: 0;
  }
}

</style>