<template>

  <TemplateModal ref="modal" :class="{ modal__hide: !is_show }">

    <template #head>Nowy komunikat</template>
    <template #body>

      <div class="row-nice" v-if="action.text_set_final === 'yes'">
          <textarea class="row-nice__textarea" id="infoNewInputText" name="text" placeholder=" "
                    v-model="text"
                    ref="text_input"
                    @keyup.prevent.ctrl.enter="ticketInfoNewSubmit"
                    @keyup.prevent.esc="close"
          ></textarea>
        <label for="infoNewInputText" class="row-nice__label">
          Opis komunikatu
        </label>
        <label class="btn-files" @click.prevent="this.$refs.input_files.open()"
               v-if="action.files_set_final === 'yes'">
          <img src="@/assets/img/files.svg" alt="">
        </label>
      </div>

      <inputFiles ref="input_files" v-if="action.files_set_final === 'yes'" :show_button="false"/>

      <div class="row-nice" v-if="action.date === 'manual'">
        <input class="row-nice__input" type="datetime-local" v-model="date" id="infoNewInputDate">
        <label for="infoNewInputDate" class="row-nice__label">
          Data
        </label>
      </div>


      <template v-if="action.original_set === 'yes'">
        <Row type="autocomplete" name="id_original" label="Oryginał" :url="'ticket/get'" @updateValue="updateValue"
             :url_parameters="{id_original_NULL: true,id_top_NULL: true}"/>
      </template>

      <div class="row-nice" v-if="action.worker_set === 'chose'">
        <select name="id_worker" id="infoNewInputWorker" class="row-nice__select" v-model="id_worker">
          <option :value="worker.id" v-for="worker in workers">
            {{ worker.firstname }} {{ worker.lastname }}
          </option>
        </select>
        <label for="infoNewInputWorker" class="row-nice__label">
          Przekaż wykonawcy
        </label>
      </div>
      <div class="row-nice" v-if="action.due_date_set === 'yes'">
        <input name="due_date" type="datetime-local" v-model="due_date" class="row-nice__input"/>
        <label for="infoNewInputBoard" class="row-nice__label">
          Data wykonania
        </label>
      </div>

      <div v-if="action.board_set === 'chose'">
        <div class="row-nice" v-if="!configOne">
          <select name="id_board" id="infoNewInputBoard" class="row-nice__select" v-model="id_board">
            <option :value="board.id" v-for="board in ticket_board">{{ board.name }}</option>
          </select>
          <label for="infoNewInputBoard" class="row-nice__label">
            Przypisz do tablicy
          </label>
        </div>
        <div v-if="configOne">
          <div class="board-block" v-for="board in ticket_board"
               @click="id_board = board.id;ticketInfoNewSubmit();">
            {{ board.name }}
          </div>
        </div>
      </div>

      <div v-if="action.priority_set === 'chose'">
        <div class="row-nice" v-if="!configOne">
          <select name="priority" id="infoNewInputBoard" class="row-nice__select" v-model="priority">
            <option :value="priority.value" v-for="priority in config.ticket_priority">{{
                priority.name
              }}
            </option>
          </select>
          <label for="infoNewInputBoard" class="row-nice__label">
            Wybierz priorytet
          </label>
        </div>
        <div v-if="configOne">
          <div class="board-block" v-for="priority in config.ticket_priority"
               @click="priority = priority.value;ticketInfoNewSubmit();">
            {{ priority.name }}
          </div>
        </div>
      </div>

    </template>
    <template #foot>

      <button type="submit" class="btn" style="width: 100%;" @click="ticketInfoNewSubmit">
        Dodaj
      </button>
    </template>

  </TemplateModal>

</template>

<script>

import router from "@/router";
import axios from "@/axios";

import {mapActions, mapState} from 'vuex'
import {useToast} from "vue-toastification";
import ComponentModalFormStateMachine from "@/Library/Extends/ComponentModalFormStateMachine";
import InputFiles from "@/Library/Forms/InputFiles";
import TemplateModal from "@/Library/Template/Modal";
import Row from "@/Library/Forms/Row.vue";
import Input from "@/Library/Forms/Input.vue";

export default {
  name: "TicketInfoNew",
  components: {Input, Row, TemplateModal, InputFiles},
  extends: ComponentModalFormStateMachine,
  data() {
    return {
      text: "",
      date: "",
      id_ticket: null,
      id_action: null,
      id_worker: null,
      id_board: null,
      id_original: null,
      due_date: null,
      priority: null,
      action: [],
      workers: [],
      ticket_refresh_params: null,
      ticket: null,
    }
  },
  methods: {

    ...mapActions('ticket', [
      'ticketUpdate',
      'ticketUpdateNeedLoading',
      'ticketUpdateWeek'
    ]),

    updateValue(name, value) {
      this.$data[name] = value;
    },

    open(ticket, action, config = {}) {

      let ticket_refresh_params = config.ticket_refresh_params ? config.ticket_refresh_params : this.tickets_params;


      this.ticket = ticket;
      this.action = action;
      this.ticket_refresh_params = ticket_refresh_params;

      this.date = (new Date());

      this.id_ticket = ticket.id;
      this.id_action = action.id;

      if (action.due_date_set === 'yes') {

        let date = new Date();

        let hours = parseInt(action.due_date_hour);

        console.log(date, hours)

        date.setSeconds(0);
        date.setMinutes(0);
        date.setHours(date.getHours() + hours);

        let date_str = date.toISOString().split('.')[0].replace("T", " ");


        this.due_date = date_str;


      }
      if (action.fast_action === 'no') {

        this.$refs.modal.open();

        this.is_show = true;
        if (this.$refs.text_input)
          this.$refs.text_input.focus();

        this.stateUpdate('openModal');

      }
      if (action.fast_action === 'yes') {
        this.exec();
      }
    },
    exec() {
      this.ticketInfoNewSubmit();
    },
    clear() {
      this.text = null;
      this.date = null;

      if (this.$refs.input_files) {
        this.$refs.input_files.clear();
      }
    },
    close() {
      this.is_show = false;
      this.$refs.modal.close();
      this.stateUpdate('closeModal');
    },

    async ticketLoadChanges() {

      let get_params = Object.assign({}, {id: this.ticket.id}, this.ticket_refresh_params);

      await axios.get('ticket/find', {
        params: get_params
      }).then(response => {

        let data = response.data;

        let ticket = data.data;

        if (ticket) {

          Object.assign(this.ticket, ticket);

        }
      })
    },

    async ticketInfoNewSubmit() {
      this.stateUpdate('sendInfo');
      const toast = useToast();

      let data = {
        id_ticket: this.id_ticket,
        id_action: this.id_action,
        id_worker: this.id_worker,
        id_board: this.id_board,
        id_original: this.id_original,
        due_date: this.due_date,
        priority: this.priority,
        text: this.text,
        date: this.date,
      };

      if (this.$refs.input_files) {
        data['file'] = this.$refs.input_files.val();
      }

      let url = this.id_action ? 'ticketaction/exec' : 'ticketinfo/add';

      this.ticketUpdateNeedLoading();

      await axios
          .post(
              url,
              data,
              {},
          )
          .then(async response => {

            toast.success("Komunikat dodany", {
              timeout: 2000
            });

            this.stateUpdate('sendSuccess');


            this.close();
            this.clear();

            await this.ticketLoadChanges();
            await this.ticketUpdate();
            await this.ticketUpdateWeek();

            this.listRefresh();

          })
          .catch(error => {

            this.stateUpdate('sendWrong');

            let error_data = error.response.data;
            let error_status = error.response.status;
            let error_header = error.response.headers;
            let error_code = error_data.code;

            console.log(error_data);
            console.log(error_status);
            console.log(error_header);

            switch (error_code) {
              case 'no-required-data':
                this.errorMessage = 'Brak wymaganych danych.'
                break;
            }

          })


    },
    loadWorkers() {

      axios
          .get('user/get', {'params': {'scope': 'id,firstname,lastname', 'role': "worker"}})
          .then(response => {
            this.workers = response.data.data
          });
    },
    listRefresh: function () {

      let app = this.$root.$refs.app;
      app.observeCall('refresh');

    },
    configCount() {

      let count = 0;
      if (this.action.priority_set === 'chose')
        count++;
      if (this.action.board_set === 'chose')
        count++;
      if (this.action.worker_set === 'chose')
        count++;
      if (this.action.date === 'manual')
        count++;

      return count;

    },

  },
  computed: {

    ...mapState('ticket', ['ticket_board', 'tickets_params']),
    ...mapState('app', ['config']),
    configOne() {
      return this.configCount() === 1;

    },
    modal() {
      return document.getElementById("ticketInfoNewModal");
    },
    hasBtn() {
      let ret = true;

      let configCount = this.configCount();
      let configOne = configCount === 1;

      if (configOne && this.config.board_set === 'chose') {
        ret = false;
      }

      return ret;

    }
  },
  created() {
    this.loadWorkers();
  },
  mounted() {

    // this.open();

  }
}
</script>

<style scoped lang="scss">
.board-block {
  padding: 20px;
  background-color: #1a90ff;
  color: #fff;
  margin-bottom: 12px;

  cursor: pointer;
}

.subtitle {

  color: #333;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin: 0 0 6px;

  font-size: 12.8px;

}

.labels-list {
  margin: 0;
  padding: 0;
  list-style: none;

  text-align: left;

  &__item {

    position: relative;
    color: #fff;

    border-radius: 8px;

    padding: 8px 20px 8px 40px;
    margin-right: 8px;

    font-size: 12px;
    line-height: 18px;

    display: inline-block;
    *display: inline;
    *zoom: 1;
    user-select: none;

    cursor: pointer;
  }

  &__label {
    cursor: pointer;
  }

  &__check {


    width: 8px;
    height: 16px;
    display: block;

    position: absolute;

    left: 16px;
    top: 7px;
    //background-color: inherit;

    border-bottom: 2px solid;
    border-right: 2px solid;

    transform: rotate(45deg);

    border-color: #fff;
    opacity: .05;
  }

  &__input:checked + &__check {
    border-color: inherit;
    opacity: 1;
  }

  &__input {

    position: absolute;
    top: -1000px;
  }
}


.btn-files {
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
}

.ticketInfoNewForm {
}


.ticketInfoNewForm__remember {
  float: left;
  margin-bottom: 15px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ticketInfoNewForm__error {
  background-color: red;
  color: #ffffff;

  padding: 22px 15px;
  margin-bottom: 15px;
}

.ticketInfoNewForm__forgot {
  float: right;
  margin-bottom: 15px;
  font-size: 14px;
}

</style>