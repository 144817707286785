<template>
  <textarea class="row-nice__textarea" v-model="value" @change="updateValue"></textarea>
</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputTextarea",
  extends: InputBase,
  props: ['name','value'],
  data() {
    return {
    }
  },
  methods: {
    val(){
      return this.value;
    },
    updateValue: function (value) {
      this.$emit('updateValue', this.name, this.value);
    }
  }
}
</script>

<style scoped>

</style>