<template>

    <input class="row-nice__input" type="datetime-local" v-model="value" @keyup="updateValue" @change="updateValue"/>

</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputDateTime",
  extends: InputBase,
  props: ['name','value'],
  data() {
    return {
    }
  },
  methods: {
    val(){
      return this.value;
    },
    updateValue() {
      this.$emit('updateValue', this.name, this.value);
    }
  }
}
</script>

<style scoped>

</style>