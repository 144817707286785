<template>

  <div class="dropdown topbar-head-dropdown ms-1 header-item">
    <button
        type="button"
        class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        id="page-header-tools"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Narzędzia"
    >
      <i class="bx bx-category-alt fs-22"></i>

    </button>
    <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" data-popper-placement="bottom-end">
      <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
        <div class="row align-items-center">
          <div class="col">
            <h6 class="m-0 fw-semibold fs-15"> Narzędzia </h6>
          </div>
        </div>
      </div>

      <div class="p-2">
        <div class="row g-0">
          <div class="col">
            <a class="dropdown-icon-item" @click="ticketNew">
              <span class="material-symbols-outlined">notifications</span>
              <span>Zgłoś</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "NavBarTools",

  computed: {},

  methods: {

    ticketNew() {
      let app = this.$root.$refs.app;
      let ticketNewModal = app.$refs.ticketNew;

      ticketNewModal.open();

    },
  },
}
</script>

<style scoped>

</style>