<template>

    <form action="" class="ticketForm " :class="{
    ticketFormWaiting: stateCompare('waiting'),
    ticketFormLoading: stateCompare('loading'),
    loading: stateCompare('loading'),
     }"
          @submit.prevent="ticketSubmit"
    >
        <div class="ticketForm__error" v-if="stateCompare('error')">
            {{ errorMessage }}
        </div>

        <PartTextFiles
                :text="obj.description"
                text_name="description"
                :files="obj.files"

                ref="textFiles"
                @updateValue="updateValue"
                @actionSubmit="ticketSubmit"
                @actionClose="this.$emit('actionClose')"
        />

        <div style="position:relative;" class="mb-3" v-if="more_options === 'show'">

            <div class="subtitle">
                Oznaczenia
            </div>
            <InputLabels ref="labels" module_code="ticket"/>
        </div>
        <div class="mb-3" style="position:relative;">
            <div class="subtitle" v-if="more_options === 'show'">
                Dodatkowe parametry
            </div>
            <InputLabels module_code="ticket" ref="labels" v-if="more_options === 'toggle'"/>
            <button
                    class="toggle"
                    :class="{'toggle-clicked':more === 'no'}"
                    v-if="more_options === 'toggle'"
                    @click="toggleMore"
            ></button>
        </div>

        <div v-show="more_options === 'show' || more ==='yes'">

            <div v-if="hasSignaller">
                <Row label="Zgłaszający" type="select" name="signaller" :value="obj.signaller"
                     :options="signallerOptions" @updateValue="updateValue"/>

                <template v-if="this.obj.signaller === 'new' || this.obj.signaller === 'own'">
                    <Row label="Imię" name="signaller_firstname" type="text" :value="obj.signaller_firstname" ref="signaller_firstname" @updateValue="updateValue"/>
                    <Row label="Nazwisko" name="signaller_lastname" type="text" :value="obj.signaller_lastname" ref="signaller_lastname" @updateValue="updateValue"/>
                    <b>Adres</b>
                    <Row label="Ulica" name="signaller_street" type="text" :value="obj.signaller_street" ref="signaller_street" @updateValue="updateValue"/>
                    <Row label="Numer domu" name="signaller_building_number" type="text" :value="obj.signaller_building_number" ref="signaller_building_number" @updateValue="updateValue"/>
                    <Row label="Numer mieszkania" name="signaller_appartment_number" type="text" :value="obj.signaller_appartment_number" ref="signaller_appartment_number" @updateValue="updateValue"/>
                    <Row label="Kod pocztowy" name="signaller_zip_code" type="text" :value="obj.signaller_zip_code" ref="signaller_zip_code" @updateValue="updateValue"/>
                    <Row label="Miasto" name="signaller_city" type="text" :value="obj.signaller_city" ref="signaller_city" @updateValue="updateValue"/>
                    <b>Kontakt</b>
                    <Row label="Adres mail" name="signaller_mail" type="text" :value="obj.signaller_mail" ref="signaller_mail" @updateValue="updateValue"/>
                    <Row label="Numer telefonu" name="signaller_phone" type="text" :value="obj.signaller_phone" ref="signaller_phone" @updateValue="updateValue"/>
                </template>

            </div>

            <div v-if="hasLocation">
                <Row name="location" label="Adres" type="select" :value="obj.location" :options="this.locationOptions"
                     @updateValue="updateValue"/>
                <PartLocation label="Lokacja zgłoszenia"
                              :show-find-me="true"
                              class="mb-3 mt-3"
                              :location_inline="true"
                              :location_city="obj.location_city"
                              :location_street="obj.location_street"
                              :location_building_number="obj.location_building_number"
                              :location_appartment_number="obj.location_appartment_number"
                              :location_zip_code="obj.location_zip_code"
                              :location_lat="obj.location_lat"
                              :location_lng="obj.location_lng"
                              v-if="obj.location === 'own'"
                              @updateValue="updateValue"
                />
            </div>

            <div v-if="hasContact">

                <Row name="contact" label="Kontakt" type="select" :value="obj.contact" :options="this.contactOptions"
                     @updateValue="updateValue"/>
                <PartContact label="Informacje kontaktowe" class="mt-3"
                             :contact_name="obj.contact_name"
                             :contact_mail="obj.contact_mail"
                             :contact_phone="obj.contact_phone"
                             @updateValue="updateValue"
                             v-if="obj.contact === 'own'"
                />
            </div>

            <div v-if="hasPriority">

                Priorytet
                <Row
                    name="priority"
                    type="radio-switch"
                    :value="this.$data.obj.priority"
                    :options="priorityArr"
                    @updateValue="updateValue"/>

            </div>

            <div v-if="hasAction">
                <Row label="Akcja"
                     type="select"
                     name="id_action"
                     :value="this.$data.obj.id_action"
                     :options="actionArr"
                     @updateValue="updateValue"/>
            </div>

            <div v-if="hasEstimate">
                <Row label="Szacowany czas"
                     type="select"
                     name="estimate"
                     :value="this.$data.obj.estimate"
                     :options="this.config.ticket_estimate_options"
                     @updateValue="updateValue"/>
            </div>

            <div v-if="hasType">
                <Row label="Typ"
                     type="select"
                     name="id_type"
                     :value="obj.id_type"
                     :options="typeArr"
                     :options_null="true"
                     @updateValue="updateValue"/>
            </div>

            <div v-if="hasPlace">
                <Row label="Miejsce"
                     type="select"
                     name="id_place"
                     :value="obj.id_place"
                     :options="placeArr"
                     @updateValue="updateValue"/>
            </div>

            <div v-if="hasCategory">
                <Row label="Wskaż kategorię"
                     type="select"
                     name="id_category"
                     :value="obj.id_category"
                     :options="categoryArr"
                     @updateValue="updateValue"/>
            </div>

            <div v-if="hasBoards">
                <Row label="Wskaż tablicę"
                     type="select"
                     name="id_board"
                     :value="obj.id_board"
                     :options="boardsArr"
                     @updateValue="updateValue"/>
            </div>

            <div v-if="hasTopics">
                {{topicsArr}}
                <Row label="Wybierz tematy"
                     type="many"
                     name="topic_ids"
                     :value="obj.topic_ids"
                     :options="topicsArr"
                     @updateValue="updateValue"/>
            </div>

            <div v-if="hasWorker">
                <Row label="Wykonawca"
                     type="autocomplete"
                     :value="obj.worker_id"
                     :value_show="obj.worker_id_name"
                     :input_id="inputId"
                     url="user/get"
                     url_parameter_name="search_text"
                     @updateValue="updateWorker"/>
            </div>

            <div v-if="hasAddLater">
                <div v-if="hasAddLaterSwitch" class="mb-3">
                    <InputToggle
                            label="Dodaj później"
                            name="planed_later"
                            :value="add_later"
                            :value_input="true"
                            @updateValue="(name, value) => { add_later = value }"/>
                </div>
                <div class="row-nice__planed mb-3" style="text-align: left">
                    <div class="row-nice__planed-date" v-if="hasAddLaterBlock">
                        <Row type="date"
                             name="date_state_open"
                             label="Data dodania na tablicę"
                             :value="this.obj.date_state_open"
                             @updateValue="updateValue"
                        />
                    </div>
                </div>
            </div>

            <div v-if="hasTask">
                <div class="mb-3">
                    <InputToggle label="Dodaj zadania"
                                 name="add_task"
                                 :value="add_task"
                                 :value_input="true"
                                 @updateValue="(name, value) => { add_task = value }"/>
                </div>
                <div class="mb-3" v-show="add_task">
                    <TaskNew ref="tasks" v-if="this.config.ticket_task"/>
                </div>
            </div>

            <div class="mb-5"></div>
        </div>
        <div v-if="this.ticket_type === 'interval'">

            <TicketFormInterval ref="interval"
                                :interval_type="obj.interval_type"
                                :interval_option="obj.interval_option"
                                :interval_value="obj.interval_value"
                                :interval_start="obj.interval_start"
                                :interval_start_set="obj.interval_start_set"
                                :interval_end="obj.interval_end"
                                :interval_end_set="obj.interval_end_set"
                                @updateValue="updateValue"
            />

        </div>
    </form>
</template>

<script>

import axios from "@/axios";
import {mapActions, mapState} from 'vuex'
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine";
import {useToast} from "vue-toastification";
import InputLabels from "@/Library/Forms/InputLabels";
import InputFiles from "@/Library/Forms/InputFiles";
import PartLocation from "@/Library/Forms/PartLocation";
import PartContact from "@/Library/Forms/PartContact";
import Row from "@/Library/Forms/Row";
import CoreDate from "@/Library/Helpers/date";
import CoreObject from "@/Library/Helpers/object";
import router from "@/router";
import InputAutocomplete from "@/Library/Forms/InputAutocomplete";
import TaskNew from "../../Task/New";
import InputToggle from "@/Library/Forms/InputToggle";
import TicketFormInterval from "./FormInterval";
import Temporary from "@/Library/Prefabricate/Temporary";
import PartTextFiles from "@/Library/Forms/PartTextFiles";
import InputRadioSwitch from "@/Library/Forms/InputRadioSwitch.vue";
import SystemConfigModule from "@/Library/System/Config/Module.vue";

export default {
    name: "TicketForm",
    components: {
        InputRadioSwitch,
        PartTextFiles,
        TicketFormInterval,
        InputToggle, TaskNew, InputAutocomplete, Row, PartContact, PartLocation, InputFiles, InputLabels
    },
    extends: ComponentFormStateMachine,
    mixins: [Temporary, SystemConfigModule],
    emits: ['actionClose','updateTicketType'],
    props: {
        'more_options': {
            type: String,
            default: 'toggle',
        }
    },
    data() {
        return {
            input_id: null,
            obj: {},

            obj_template: {
                location_street: '',
                location_zip_code: '',
                location_city: '',
                location_lat: null,
                location_lng: null,
                location: 'no',

                contact_phone: null,
                contact_mail: null,
                contact_name: null,
                contact: 'no',

                worker_id: null,
                worker_id_name: null,

                interval_type: 'week',
                interval_option: 'first',
                interval_value: 1,
                interval_start: CoreDate.now(),
                interval_start_set: 'no',
                interval_end: CoreDate.now(),
                interval_end_set: 'no',

                signaller: 'no',

                estimate: '999999',
                priority: 'low',
                date_state_open: CoreDate.yyyymmdd(null, false),

                description: "",
                id: null,
                id_action: null,
                id_board: null,
                id_board_temp: null,
                id_type: null,
                id_place: null,
                files: [],

            },
            ticket_type: "ticket",
            type: "new",
            planned: 'chose',

            add_later: false,
            add_task: false,
            interval: false,
            more: 'no',
            actions: [],
        }
    },
    methods: {
        ...mapActions('ticket', [
            'ticketUpdateMessages',
            'ticketUpdate',
            'ticketUpdateNeedLoading',
            'ticketUpdateWeek'
        ]),

        updateValue(name, value, value_show) {

            console.log(name, value, value_show)
            this.$data['obj'][name] = value;

        },

        updateWorker(name, value, label, avatar) {
            this.worker_id = value;
            this.worker_id_name = label;
        },

        clear() {

            this.obj = this.obj_template;

            this.more = 'no';
            this.$refs.labels.clear();

            if (this.$refs.tasks)
                this.$refs.tasks.clear();

            if (this.$refs.interval)
                this.$refs.interval.clear();

        },
        open() {
            let app = this.$root.$refs.app;

            this.type = 'new';

            this.id_board_temp = this.obj.id_board;

            this.obj.id_action = this.config.ticket_add_action;
            this.obj.id_board = app.board ? app.board.id : this.defaultIdBoard;

            /**
             * Czy otworzyć dodatkowe opcje
             */
            if (this.more_options === 'toggle' && window.innerHeight > 800) {
                this.more = 'yes';
            }

            this.$refs.textFiles.focus();

            if (this.$refs.interval)
                this.$refs.interval.updateOptions();

        },
        close() {

            this.temporaryClear();
        },
        load(id = null) {

            if (id) {

                this.type = 'edit';

                this.obj.id = id;

                this.ticketLoad(id);

            }

        },
        toggleMore(e) {

            e.preventDefault();

            let new_val = 'yes'
            if (this.more === 'yes')
                new_val = 'no'

            this.more = new_val;

        },
        ticketStartActionLoad() {

            this.actions = this.ticket_actions_start;

            this.actions.map(function (action_value, action_key) {
                action_value.checked = false;
            });

        },

        ticketAdd() {
            this.stateUpdate('sendInfo');
            const toast = useToast();

            let data = this.obj;
            data['id_worker'] = this.obj.worker_id;
            data['labels'] = this.$refs.labels.getIds;

            if (this.add_later) {
                data['state'] = 'create';
                data['date_state_open'] = this.obj.date_state_open;
            }

            if (this.add_task) {
                data['tasks'] = this.$refs.tasks.value();
            }

            let url = 'ticket/add';
            if (this.ticket_type === 'interval')
                url = 'ticket/intervaladd';
            if (this.ticket_type === 'template')
                url = 'ticket/templateadd';

            axios
                .post(
                    url,
                    data
                )
                .then(response => {

                    toast.success("Zgłoszenie dodane", {
                        timeout: 2000
                    });

                    this.ticketUpdateNeedLoading();
                    this.stateUpdate('sendSuccess');
                    this.ticketUpdateMessages();
                    this.ticketUpdate();
                    this.ticketUpdateWeek();
                    this.clear();

                    /**
                     * Zamyka formularz
                     */
                    this.$emit('actionClose')


                    let app = this.$root.$refs.app;
                    app.refresh();

                })
                .catch(error => {

                    this.stateUpdate('sendWrong');

                    let error_data = error.response.data;
                    let error_status = error.response.status;
                    let error_header = error.response.headers;
                    let error_code = error_data.code;

                    console.log(error_data);
                    console.log(error_status);
                    console.log(error_header);

                    switch (error_code) {
                        case 'no-required-data':
                            this.errorMessage = 'Brak wymaganych danych.'
                            break;
                    }

                })


        },
        ticketEdit() {
            this.stateUpdate('sendInfo');
            const toast = useToast();

            let data = this.obj;
            data['labels'] = this.$refs.labels.getIds;

            if (this.add_later) {
                data['state'] = 'create';
                data['date_state_open'] = this.obj.date_state_open;
            }

            if (this.add_task) {
                data['tasks'] = this.$refs.tasks.value();
            }

            let config = {
                'params': {
                    'id': this.obj.id
                },
                'headers': {
                    'token': localStorage.getItem('token')
                }
            };


            axios
                .post(
                    'ticket/set',
                    data,
                    config
                )
                .then(response => {

                    toast.success("Zgłoszenie zaktualizowane", {
                        timeout: 2000
                    });

                    this.stateUpdate('sendSuccess');

                    this.ticketUpdateNeedLoading();
                    this.ticketUpdateMessages();
                    this.ticketUpdate();
                    this.ticketUpdateWeek();

                    this.clear();


                    this.$emit('actionClose')

                    let app = this.$root.$refs.app;
                    app.observeCall('refresh');


                })
                .catch(error => {

                    this.stateUpdate('sendWrong');

                    let error_data = error.response.data;
                    let error_status = error.response.status;
                    let error_header = error.response.headers;
                    let error_code = error_data.code;

                    console.log(error_data);
                    console.log(error_status);
                    console.log(error_header);

                    switch (error_code) {
                        case 'no-required-data':
                            this.errorMessage = 'Brak wymaganych danych.'
                            break;
                    }

                })


        },
        ticketLoad(id) {

            let self = this;

            let params = {
                'id': id,
                'role': 'ticket',
                'scope': 'id,type,state,description,status,signaller,worker,labels,date,mod_date,info,board,work_end_date,' +
                    'contact,contact_name,contact_mail,contact_phone,' +
                    'id_category,' +
                    'files,id_worker,worker_name,signaller_name,type,id_type,id_place,id_board,date_state_open,estimate,priority,' +
                    'interval_type,interval_option,interval_value,interval_start_set,interval_start,interval_end_set,interval_end,' +
                    'tasks,' +
                    'location,location_street,location_city,location_zip_code,location_building_number,location_appartment_number',
                'info_scope': 'id,type,text,files,create_date,user,work,state,status_set',
                'info_user_scope': 'id,avatar_url,name',
                'signaller_scope': 'id,avatar_url,name_all',
                'tasks_scope': 'id,done,text',
                'board_scope': 'id,name',
                'type_scope': 'id,name',
                'worker_scope': 'id,avatar_url,name_all',
            };
            let headers = {
                'token': localStorage.getItem('token')
            }

            axios
                .get('ticket/get', {'params': params, 'headers': headers})
                .then(response => {

                    let ticket = response.data.data[0];

                    console.log(ticket)

                    self.obj = ticket;

                    let labelIds = [];
                    ticket.labels.map(function (label) {
                        labelIds.push(label.id)
                    })

                    this.$refs.labels.setIds(labelIds);
                    if (ticket.tasks) {
                        this.add_task = ticket.tasks.length > 0;
                        this.$refs.tasks.tasks = ticket.tasks;
                    }

                    if (ticket.state === "create") {
                        this.add_later = true;
                        this.obj.date_state_open = ticket.date_state_open.substr(0, 10);
                    }

                    if (ticket.type === 'interval') {
                        this.$refs.interval.load(ticket.interval_type, ticket.interval_option)
                    }

                    if (ticket.state === "create") {

                        this.temporarySet('add_later', false);
                    }

                    this.temporarySet('ticket_type', ticket.type);
                    this.temporarySet('type', 'edit');

                })

                .catch(error => {
                    console.log(error)
                    let error_code = error.response.data.code ? error.response.data.code : null;
                    switch (error_code) {
                        case 'no-login':
                            router.push('/logout');
                            break;
                    }

                })
        },
        ticketSubmit() {
            switch (this.type) {

                case "new":
                    this.ticketAdd();
                    break;
                case "edit":
                    this.ticketEdit();
                    break;

            }
        },

        optionFind(arr, value) {
            return arr.find(option => option.value === value);
        },

        contactUpdate() {

            let contact_code = this.obj.contact;

            let contact_arr = this.contactOptions;

            let contact_option = this.optionFind(contact_arr, contact_code)

            if (contact_option && contact_option.disabled === true) {
                this.obj.contact = 'no';
            }

        },

        locationUpdate() {

            let location_code = this.obj.location;

            let location_arr = this.locationOptions;

            let location_option = this.optionFind(location_arr, location_code)

            if (location_option && location_option.disabled === true) {
                this.obj.location = 'no';
            }

        },

        boardGetById(id){
            return this.ticket_board.find(el=>el.id === id)
        }

    },
    computed: {
        ...mapState('app', ['config']),
        ...mapState('user', ['info']),
        ...mapState('ticket', ['ticket_board', 'ticket_category', 'ticket_board_ticket_add', 'ticket_actions_start', 'ticket_types']),

        contactOptionsBase() {

            /**
             * Tablica opcji kontaktu
             */
            let options = this.config.ticket_contact_options

            return CoreObject.kvToOptions(options);

        },

        contactOptions() {
            let optionsArr = [...this.contactOptionsBase];

            optionsArr.map(option => option.disabled = false)

            if (this.obj.signaller === 'no') {
                let signallerOption = this.optionFind(optionsArr, 'signaller');
                if (signallerOption)
                    signallerOption.disabled = true;
            }

            if (!this.obj.id_place) {
                let placeOption = this.optionFind(optionsArr, 'place');
                if (placeOption)
                    placeOption.disabled = true;
            }

            if (!this.obj.id_asset) {
                let assetOption = this.optionFind(optionsArr, 'asset');
                if (assetOption)
                    assetOption.disabled = true;
            }

            return optionsArr;

        },

        contactOptionsCount() {

            return this.contactOptions().length;

        },

        contactOptionsHas() {

            return this.contactOptionsCount() > 1;

        },

        locationOptionsBase() {

            /**
             * Tablica opcji kontaktu
             */
            let options = this.config.ticket_location_options

            return CoreObject.kvToOptions(options);

        },

        locationOptions() {

            /**
             * Tablica opcji kontaktu
             */
            let optionsArr = [...this.locationOptionsBase];

            optionsArr.map(option => option.disabled = false)

            if (this.obj.signaller === 'no') {
                let signallerOption = this.optionFind(optionsArr, 'signaller');
                if (signallerOption)
                    signallerOption.disabled = true;
            }

            if (!this.obj.id_place) {
                let placeOption = this.optionFind(optionsArr, 'place');
                if (placeOption)
                    placeOption.disabled = true;
            }

            if (!this.obj.id_asset) {
                let assetOption = this.optionFind(optionsArr, 'asset');
                if (assetOption)
                    assetOption.disabled = true;
            }

            return optionsArr;

        },

        locationOptionsCount() {

            return this.locationOptions().length;

        },

        locationOptionsHas() {

            return this.locationOptionsCount() > 1;

        },

        signallerOptions() {

            let options = this.config.ticket_signaller_options;

            let optionsArr = CoreObject.kvToOptions(options);

            return optionsArr;

        },

        hasAddLater() {

            let ret = false;
            if (this.type === 'new' && this.ticket_type === 'ticket') {
                ret = true;
            }
            if (this.ticket_type === 'planned') {
                ret = true;
            }

            return ret;


        },

        hasAddLaterSwitch() {
//this.planned === 'chose'
            let ret = false;
            if (this.type === 'new' && this.planned !== 'yes' && this.ticket_type === 'ticket') {
                ret = true;
            }

            return ret;
        },

        hasAddLaterBlock() {

            let ret = false;
            if (this.planned === 'yes') {
                ret = true;
            }

            if (this.add_later === true) {
                ret = true;
            }

            return ret;

        },

        hasPriority() {
            let ret = true;

            if (this.ticket_priority === 'no')
                ret = false;

            return ret;
        },

        hasAction() {
            let ret = true;

            if (this.ticket_type === 'ticket')
                ret = false;

            return ret;
        },

        hasBoards() {
            let ret = true;
            if (this.config.ticket_add_board === 'no')
                ret = false;

            if (this.ticket_type === 'template')
                ret = false;

            if(this.boardsArr.length === 0)
                ret = false;

            if(this.config.ticket_add_action_board === 'no')
                ret = false;

            return ret;
        },

        hasTopics() {

            let board = this.boardGetById(this.obj.id_board);

            let ret = true;
            if (ret && !this.hasBoards)
                ret = false;
            if (ret && !board)
                ret = false;
            if (ret && !board.topic === 'no')
                ret = false;
            if (ret && this.config.ticket_topic === 'no')
                ret = false;

            return ret;
        },

        hasCategory() {
            let ret = true;
            if (this.config.ticket_category === 'no')
                ret = false;

            if(this.categoryArr.length === 0)
                ret = false;

            return ret;
        },

        hasWorker() {

            let ret = true;

            if (this.config.ticket_add_worker === 'no')
                ret = false;

            if (this.config.ticket_worker === 'no')
                ret = false;

            if (this.config.ticket_add_action_worker === 'no')
                ret = false;

            return ret;

        },

        hasTask() {

            return this.config.ticket_task === 'yes';

        },

        hasLocation() {

            return this.config.ticket_location !== 'no';

        },

        hasContact() {

            return this.config.ticket_contact !== 'no';

        },

        hasSignaller() {

            return this.config.ticket_signaller !== 'no';

        },

        defaultIdBoard() {

            let id_default = null;
            let boards = this.boardsArr;

            if (this.hasBoards) {
                id_default = Object.keys(boards)[0];
            }

            if (this.info && this.info.config && this.info.config.ticket_board_fav) {
                id_default = this.info.config.ticket_board_fav;
            }
            return id_default;
        },

        defaultIdCategory() {

            let id_default = null;
            let categoryArr = this.categoryArr;

            if (this.hasCategory) {
                id_default = Object.keys(categoryArr)[0];
            }

            return id_default;
        },

        actionArr() {

            return CoreObject.keyval(this.ticket_actions_start);

        },

        typeArr() {

            return this.hasType ? CoreObject.kvToOptions(this.config.ticket_type_options) : [];

        },

        priorityArr() {

            return this.hasPriority ? this.config.ticket_priority_options : [];

        },

        placeArr() {

            // return CoreObject.keyval(this.ticket_place);
            return [];

        },

        boardsArr() {

            return CoreObject.keyval(this.ticket_board);

        },

        topicsArr() {

            let ret = [];
            if(this.hasTopics){
                let board = this.boardGetById(this.obj.id_board);
                ret = board.topic_options
            }

            return ret;

        },

        categoryArr() {

            return CoreObject.keyval(this.ticket_category);

        },


        inputId() {
            return this.input_id ? this.input_id : 'input' + parseInt(Math.random() * 99999);
        },

        showLocation() {

            return this.config.ticket_location !== 'no';

        },

        showContact() {

            return this.config.ticket_contact !== 'no';

        },

        hasPlace() {

            let ret= true;

            if( this.config.ticket_place === 'no')
                ret = false;

            if (this.placeArr.length === 0)
                ret = false;

            return ret;

        },

        hasType() {

            return this.config.ticket_type !== 'no';

        },

        hasEstimate() {

            return this.config.ticket_estimate !== 'no';

        },

        isNew() {
            return this.type === 'new';
        },

        isEdit() {
            return this.type === 'edit';
        },

    },
    created() {

        this.obj = this.obj_template
        this.obj.id_board = this.defaultIdBoard;
        this.obj.id_category = this.defaultIdCategory;
        this.obj.id_action = this.config.ticket_add_action;

        this.ticketStartActionLoad();
    },
    mounted() {

        this.contactUpdate();
        this.locationUpdate();

    },
    watch: {
        add_later: function (val) {

            // if (val === true){
            //     this.temporarySet('ticket_type', 'planned')
            // }
            // if (val === false){
            //     this.temporaryBack('ticket_type')
            // }


            // this.$emit('updateTicketType', val)

        },
        ticket_type: function (val) {
            this.$emit('updateTicketType', val)
        },

        'obj.signaller': function (newVal, oldVal) {
            this.contactUpdate();
            this.locationUpdate();
        },
        'obj.id_asset': function (newVal, oldVal) {
            this.contactUpdate();
            this.locationUpdate();
        },
        'obj.id_place': function (newVal, oldVal) {
            this.contactUpdate();
            this.locationUpdate();
        }

    }
}
</script>

<style scoped lang="scss">

.toggle {

  background-color: transparent;
  border: none;

  width: 30px;
  height: 30px;
  //background-color: red;
  //background-image: url('@/assets/img/dart.svg');
  background-repeat: no-repeat;

  position: absolute;

  cursor: pointer;

  top: 0;
  margin: 0;
  padding: 0;

  right: 0;
  left: auto;

  &:before {
    top: 11px;
    left: 12px;
    right: 0;
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-top: 0;
    border-left: 0;
    border-right: 1.5px solid #3F434A;
    border-bottom: 1.5px solid #3F434A;
    z-index: 2;

    will-change: transform, top;

    transition: .3s;
    transform: rotate(
                    45deg);
  }

  &-clicked {
    &:before {
      top: 11px;
      transform: rotate(-135deg);

    }

  }
}

.subtitle {

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #3F434A;
  margin-bottom: 12px;
}


.ticketForm {
  &__remember {
    float: left;
    margin-bottom: 15px;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__error {
    background-color: red;
    color: #ffffff;

    padding: 22px 15px;
    margin-bottom: 15px;
  }

  &__forgot {
    float: right;
    margin-bottom: 15px;
    font-size: 14px;
  }
}


</style>