<template>
    <div>
        <div class="row" v-if="label">
            <div class="col s12">
        <span class="subtitle">
          <label v-if="label">
            <input type="checkbox" style="float:left; margin: 4px 6px 0 0;" :value="true" :name="name_contact"
                   v-model="contact" v-if="show_checkbox">
            {{ label }}
          </label>

        </span>
            </div>
        </div>
        <div class="row" v-if="contact">
            <div class="col s12">
                <Row type="text"
                     label="Imię i nazwisko"
                     ref="contact_name"
                     :name="name_contact_name"
                     @updateValue="updateValue"
                     @validate="validate"
                     validate-required=""
                     :value="contact_name"/>
            </div>
        </div>
        <div class="row" v-if="contact">
            <div class="col s12 l6">
                <Row type="text"
                     label="Adres mail"
                     ref="contact_mail"
                     :name="name_contact_mail"
                     @updateValue="updateValue"
                     validate-required=""
                     validate-mail=""
                     :value="contact_mail"/>
            </div>
            <div class="col s12 l6">
                <Row type="text"
                     label="Numer telefonu"
                     ref="contact_phone"
                     :name="name_contact_phone"
                     @updateValue="updateValue"
                     validate-required=""
                     :value="contact_phone"/>

            </div>
        </div>

        <div class="row" v-if="fax">
            <div class="col s12">
                <Row type="text"
                     label="Numer fax'u"
                     ref="contact_fax"
                     :name="name_contact_fax"
                     @updateValue="updateValue"
                     validate-required=""
                     :value="contact_fax"/>

            </div>
        </div>
    </div>
</template>

<script>

import Row from "./Row";

export default {
    name: "PartContact",
    emits: ['updateValue'],
    props: {
        label: String,
        showFindMe: Boolean,
        show_checkbox: Boolean,
        name_contact: {
            type: String,
            default: 'contact'
        },
        name_contact_name: {
            type: String,
            default: 'contact_name'
        },
        name_contact_mail: {
            type: String,
            default: 'contact_mail'
        },
        name_contact_phone: {
            type: String,
            default: 'contact_phone'
        },
        name_contact_fax: {
            type: String,
            default: 'contact_fax'
        },
        contact_name: {
            type: String,
            default: ''
        },
        contact_mail: {
            type: String,
            default: ''
        },
        contact_phone: {
            type: String,
            default: ''
        },
        contact_fax: {
            type: String,
            default: ''
        },
        contact: {
            type: Boolean,
            default: true
        },
        fax: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {}
    },
    components: {Row},
    methods: {
        val() {

            let self = this;

            let ret = {};
            ret[self.name_contact_name] = self.contact_name;
            ret[self.name_contact_mail] = self.contact_mail;
            ret[self.name_contact_phone] = self.contact_phone;
            ret[self.name_contact_fax] = self.contact_fax;

            return ret;

        },
        updateValue(name, value) {
            this.$emit('updateValue', name, value);
        },
        validate() {
            let arr = [this.$refs.contact_name, this.$refs.contact_mail, this.$refs.contact_phone, this.$refs.contact_fax];
            for (const object of arr)
                object.validate();
        }
    }
}
</script>

<style scoped lang="scss">
.subtitle {

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #3F434A;
  margin-bottom: 12px;

  text-align: left;
  width: 100%;

  display: inline-block;
}
</style>