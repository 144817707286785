<script>
import {useToast} from "vue-toastification";

export default {
  name: "ControllToast",
  data: () => ({

  }),
  methods: {

    toastConfig(config = {}) {

      let ret = {
        timeout: 2000,
      }

      for( let [config_key, config_val] of Object.entries(config) )
        ret[config_key] = config_val;

      return ret;

    },
    toastShowAbstract(type = 'show', text = '', config = {}) {

      let toast = useToast();

      let configArr = this.toastConfig(config);

      switch (type) {
        case 'info':
          toast.info(text, configArr);
          break;
        case 'success':
          toast.success(text, configArr);
          break;
        case 'error':
          toast.error(text, configArr);
          break;
        case 'warning':
          toast.warning(text, configArr);
          break;
        default:
          toast.info(text, configArr);
          break;

      }

    },
    toastShow(text='', config = {}) {

      this.toastShowAbstract('show',text, config);

    },
    toastSuccess(text='', config = {}) {

      this.toastShowAbstract('success',text, config);

    },
    toastInfo(text='', config = {}) {

      this.toastShowAbstract('info',text, config);

    },
    toastError(text='', config = {}) {

      this.toastShowAbstract('error',text, config);

    },
    toastWarning(text='', config = {}) {

      this.toastShowAbstract('info',text, config);

    },
  }
}
</script>

<style scoped lang="scss">

</style>