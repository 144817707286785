<template>

  <div class="ticket-priority" v-if="ticket.priority" @mouseleave="show_form = false"
       :class="{'ticket-priority--open': show_form}"
  >

    <div class="ticket-priority__show"
         :style="{ backgroundColor: `${priority.color}22`,color: `${priority.color}88` }"
         @click="show_form = !show_form">
      {{ priority.name }}
    </div>
    <ul class="ticket-priority__form" v-show="show_form">
      <li class="ticket-priority__form-item"
          v-for="priority in ticket_prioritys" @click="change(priority.value)">
        <span class="ticket-priority__form-name"
              :style="{ backgroundColor: `${priority.color}22`,color: `${priority.color}` }">
          {{ priority.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "@/axios";
import {mapActions, mapState} from "vuex";

export default {
  name: "TicketShowPriority",
  props: {
    ticket: {}
  },
  emits: ['updatePriority'],
  data() {
    return {
      show_form: false
    }
  },

  methods: {

    ...mapActions('ticket', [
      'ticketUpdateMessages',
      'ticketUpdate',
      'ticketUpdateNeedLoading',
      'ticketUpdateWeek'
    ]),
    async change(priority) {

      this.show_form = false;

      let data_arr = {
        'priority': priority
      };
      let params = {
        'id': this.ticket.id,
        'scope': 'id,priority',
      };

      this.ticket.priority = priority;

      await this.ticketUpdateNeedLoading();

      await axios
          .post('ticket/set/', data_arr, {params: params})
          .then((response) => {

            let data = response.data.data;


            this.ticketUpdate();

          });

      this.$emit('updatePriority', priority)

    }
  },

  computed: {
    ...mapState('app', ['config']),
    ticket_prioritys() {
      return this.config.ticket_priority_options
    },

    priority() {
      return this.ticket_prioritys.find(priority => priority.value === this.ticket.priority)
    },

  },

}
</script>

<style scoped lang="scss">

.big {
  position: relative;
  z-index: 1;

  max-width: 300px;

}

.big .ticket-priority {

  position: relative;
  z-index: 1000;

  max-width: 300px;

  &--open {

    //filter: drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15));
    border-radius: 14px;
  }

  &__show {

    display: inline-block;
    color: #fff;

    border-radius: 14px;
    border: none;
    padding: 8px 18px;

    user-select: none;

    font-size: 15px;
    line-height: 22px;

    cursor: pointer;

    transition: .3s all;
  }

  &__form {
    border-top: 1px dashed #e6e7ea;
    position: absolute;
    left: 0;
    top: 0;

    margin: 39px 0 0;
    padding: 3px 12px 0;

    list-style: none;

    background-color: #fff;

    display: flex;
    flex-direction: column;
    border-top: 5px dashed transparent;
    border-bottom: 5px dashed transparent;

    filter: drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15));

    &-item {
      padding: 6px 0;

      font-size: 15px;
      line-height: 22px;
      width: 100%;
    }

    &-name {
      cursor: pointer;

      display: inline-block;
      color: #fff;

      border-radius: 14px;
      border: none;
      padding: 8px 18px;

      user-select: none;

      font-size: 15px;
      line-height: 22px;

      width: 100%;
    }
  }
}

.ticket-priority {

  position: relative;
  z-index: 1;

  max-width: 300px;

  &--open {
    z-index: 1060;
  }

  [data-layout-mode="dark"] &__form {
    border: 1px solid var(--sp-border-color) !important;
    background: var(--sp-body-bg) !important;
  }

}

.small .ticket-priority {


  &--open {

    //filter: drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15));
    border-radius: 14px;
  }

  &__show {

    transition: .3s all;
    position: relative;

    width: 100%;
    height: 22px;

    font-size: 14px;
    line-height: 22px;
    margin: 0;
    padding: 0;
    text-align: center;
    border-radius: 6px;

    background-color: #000;
    color: #fff;

    cursor: pointer;
  }

  &__form {
    position: absolute;
    left: 0;
    top: -4px;
    right: 0;

    margin: 26px 0 0;
    padding: 3px 6px 3px;

    list-style: none;

    background-color: #fff;

    display: flex;
    flex-direction: column;

    border: 1px solid #E8E9EB;
    border-top: 5px dashed transparent;

    &-item {
      padding: 2px 0;

      font-size: 12px;
      line-height: 16px;
      width: 100%;
    }

    &-name {
      cursor: pointer;

      display: inline-block;
      color: #fff;

      position: relative;
      width: 100%;
      height: 22px;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      padding: 0;
      text-align: center;
      border-radius: 6px;
    }
  }
}
</style>