<template>

  <div class="card" :class="this.class">
    <div class="card-header border-bottom mb-0" v-if="showTitle">
      <h3 class="card-title m-0">
        <slot name="title"></slot>
      </h3>
      <div class="card-buttons">
        <slot name="buttons"></slot>
      </div>
    </div>
    <div class="card-body" v-if="showBody" :class="{p0: p0}">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import ControllTemplate from "@/Library/Controll/Template";
export default {
  name: "TemplateCard",
  mixins: [ControllTemplate],
  props: {
    p0: {},
    class: {
      default() {
        return {};
      }
    }
  },
  computed: {

    showTitle() {
      return this.showElement('title');
    },
    showBody() {
      return this.showElement('body');
    },

  }
}
</script>

<style scoped lang="scss">

.card-header {
  //overflow: hidden;
  display: flex;



}

.card-title{
  width: 100%;
}

.card-body{
  line-height: 1.8em;
}

.header_relative .card-header{
  position: relative;
}

.no_padding .card-body,
.card--no_padding .card-body {
  padding: 0;

}
.no_overflow .card-body {
overflow: initial;
}

.p0 {
  padding: 0;
  //margin: 1.5rem 1.5rem;
  //height: calc(100vh - 370px);
  max-height: 900px;
}
</style>