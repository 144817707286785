<template>
  <ul class="task-list">
    <TaskLine class="task-list__item" v-for="task in tasks"
              :task="task"
              :can_delete="can_delete"
              :can_check="can_check"
               v-on:del="del"
               v-on:check="check"
    />
  </ul>
</template>

<script>
import InputCheckbox from "@/Library/Forms/InputCheckbox";
import TaskLine from "./Line";
export default {
  name: "TaskList",
  components: {TaskLine, InputCheckbox},
  props: ['tasks', 'can_delete','can_check'],
  emits: ['del', 'check'],
  methods: {
    del(task){

      this.$emit('del', task)

    },
    check(task, value){
      this.$emit('check', task, value)
    }
  }
}
</script>

<style scoped lang="scss">

.task-list {
  text-align: left;

  list-style: none;

  margin: 0;
  padding: 0;

  &__item {

    padding: 12px;

    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.02);
    }

  }

}



</style>