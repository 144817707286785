<template>
    <label class="input-toggle" :class="[...this.class,{'input-toggle--disabled': disabled}]">
        <input :id="id" class="input-toggle__input push-btn" type="checkbox" :name="name" :value="value"
               v-model="isChecked"
               :disabled="disabled"
               @change="change"/>
        <label class="input-toggle__item" :for="id"></label>
        <span class="input-toggle__name">{{ label }}</span>
    </label>
</template>

<script>
import InputBase from "./InputBase";

export default {
    name: "InputToggle",
    extends: InputBase,
    emits: ['update', 'updateValue'],
    props: {
        'id': {
            default() {
                return 'input-' + parseInt(Math.random() * 10000000000);
            }
        },
        'name': {},
        'class': {
            default() {
                return [];
            }
        },
        'value': {},
        'value_input': {
            default() {
                return true;
            }
        },
        'value_input_false': {
            default() {
                return false;
            }
        },
        'disabled': {
            default() {
                return false;
            }
        },
        'label': {},
        'checked': {}
    },
    data() {
        return {
            isChecked: false
        }
    },
    methods: {
        change() {

            let show = this.checked ? this.label : "";

            let ret = this.isChecked ? this.value_input : this.value_input_false;

            this.$emit('update', this.checked)
            this.$emit('updateValue', this.name, ret, show)
        },
        isCheckedUpdate() {

            this.isChecked = this.value === this.value_input;

            if (this.checked)
                this.isChecked = true;


        },
    },
    computed: {},
    mounted() {
        this.isCheckedUpdate();

    },

    watch: {
        value() {

            this.isCheckedUpdate();

        },
        value_input() {

            this.isCheckedUpdate();

        },
        checked() {

            this.isCheckedUpdate();

        },
    }
}
</script>

<style scoped lang="scss">
.input-toggle {
  position: relative;
  display: flex;
  //margin: 10px 0;
  cursor: pointer;
  margin-bottom: 0;

  &--disabled {
    opacity: .3;
  }

  &__item {
    border: 0.5px solid rgba(117, 117, 117, 0.31);
    position: relative;

    &:before {
      border: none;
      width: 2.5em;
      height: 2.5em;
      background: #fff;
      transform: rotate(-25deg);
      content: "";
      position: absolute;
    }

    &:after {
      background: transparent;
      height: calc(100% + 8px);
      border-radius: 30px;
      top: -5px;
      width: calc(100% + 8px);
      left: -4px;
      z-index: 0;
      content: "";
      position: absolute;
    }
  }

  &__input {
    height: 40px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 40px;

    &:checked + label {
      background: #3ea5fe;
    }

    &:checked + label:before {
      left: auto !important;
      right: 3px;
    }
  }

  &__item {
    width: 3em;
    min-width: 3em;
    background: #2e394d;
    height: 1.5em;
    display: inline-block;
    border-radius: 50px;
    position: relative;
    transition: all 0.3s ease;
    transform-origin: 20% center;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 0;

    &:before {
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      top: 0.23em;
      left: 0.25em;
      border-radius: 2em;
      transition: 0.3s ease;
    }
  }

  &__name {
    text-align: left;

    float: left;

    user-select: none;
    margin-top: -3px;
  }

}

</style>