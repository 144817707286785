<template>

  <div class="ticket-interval grey-block">
    <Row label="Co jaki czas" type="select" name="interval_type" :options="config.ticket_interval_types"
         :value="interval_type" v-on:updateValue="updateValue"/>
    <Row label="W jaki dzień" type="select" name="interval_option" :options="interval_options" :value="interval_option"
         v-on:updateValue="updateValue"/>
    <Row v-show="hasOptionValue" label="Ile dni" type="text"
         name="interval_value" :value="interval_value" v-on:updateValue="updateValue"/>
    <hr>

    <div class="mb-3">
      <InputToggle label="Określ datę początkową" name="interval_start_set" :value="interval_start_set"
                   :value_input="'yes'"
                   v-on:updateValue="updateValue"/>

    </div>

    <div class="" v-show="interval_start_set === 'yes'">
      <Row label="Data początkowa" name="interval_start" type="date" :value="interval_start"
           v-on:updateValue="updateValue"/>
    </div>


    <div class="mb-3">
    <InputToggle
        class="mb-3" label="Określ datę końcowa" name="interval_end_set" :value="interval_end_set" :value_input="'yes'"
                 v-on:updateValue="updateValue"/>
    </div>

    <div class="" v-show="interval_end_set === 'yes'">
      <Row label="Data końcowa" name="interval_end" type="date" :value="interval_end"
           v-on:updateValue="updateValue"/>
    </div>

  </div>

</template>

<script>

import CoreDate from "@/Library/Helpers/date";
import {mapActions, mapState} from 'vuex'
import Row from "@/Library/Forms/Row";
import InputToggle from "@/Library/Forms/InputToggle";

export default {
  name: "TicketFormInterval",
  components: {InputToggle, Row},
  emits: ['updateValue'],
  props: ['interval_type', 'interval_option', 'interval_value', 'interval_start', 'interval_start_set', 'interval_end', 'interval_end_set'],
  data() {
    return {
      interval_options: [],
    }
  },
  methods: {

    updateOptions(type, option) {
      type = type ? type : this.interval_type;
      option = option ? option : this.interval_option;

      this.interval_options = this.config.ticket_interval_types_options[type];

      if (type === 'week')
        option = 'monday'
      if (type !== 'week')
        option = 'first';

      this.$emit('updateValue','interval_option', option);

    },

    updateValue(name, value, value_show) {

      if (name === 'interval_type') {

        this.updateOptions(value);

      }

      this.$emit('updateValue', name, value, value_show);

    },

    clear() {
      let date_now = CoreDate.now();

      let interval_type = 'week';

      this.updateOptions(interval_type);

      this.$emit('updateValue', 'interval_type', interval_type);
      this.$emit('updateValue', 'interval_option', 'monday');
      this.$emit('updateValue', 'interval_value', 1);

      this.$emit('updateValue', 'interval_end_set', 'no');
      this.$emit('updateValue', 'interval_end', date_now);
      this.$emit('updateValue', 'interval_start_set', 'no');
      this.$emit('updateValue', 'interval_start', date_now);
    },

    load(interval_type, interval_option, interval_value){

      this.updateOptions(interval_type, interval_option)

    }

  },
  computed: {
    ...mapState('app', ['config']),

    hasOptionValue() {

      let ret = false;

      if (this.interval_option === 'after-first')
        ret = true;
      if (this.interval_option === 'before-last')
        ret = true;


      return ret;

    },


  },
  mounted() {
    this.updateOptions();
  },
  watch: {
    add_later: function (val) {
      console.log(val)
    }
  }
}
</script>

<style scoped lang="scss">

.ticket-interval {

}
</style>