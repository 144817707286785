<template>

  <ul class="access-list">
    <SystemAccessObjManageListItem
        v-for="(access_value, access_key) in this.access"
        :access="access_value"
        :permissions="permissions"
        :id_obj="id_obj"
        v-on:accessItemUpdate="itemUpdate"
        v-on:accessItemDelete="itemDelete"

    />

  </ul>
  <div class="access-none alert alert-info" v-show="this.size === 0">
    Brak
  </div>

</template>

<script>

import SystemAccessObjManageListItem from "./ObjManageListItem";

export default {
  name: "SystemAccessObjManageList",
  components: {SystemAccessObjManageListItem},
  emits: ['accessUpdate', 'accessDelete'],
  props: {
    id_obj: {
      type: String,
      default() {
        return null;
      },
    },
    access: {
      type: Object,
      default() {
        return {};
      }
    },
    permissions: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data: () => ({
  }),
  methods: {
    update: function (data) {
    },
    itemUpdate: function (key, data) {
      this.$emit('accessUpdate', key, data)
    },
    itemDelete: function (key) {
      this.$emit('accessDelete', key)
    }
  },
  computed: {

    size: function () {
      return Object.keys(this.access).length;
    }


  },
  watch: {
    'access': function () {
      this.update(this.access);
    }
  }

}
</script>

<style scoped lang="scss">
.access-list {
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
  }
}

.right-item {

  display: flex;
  flex-direction: row;

  margin-bottom: 12px;

  &__thumb {
    width: 60px;
    height: 60px;

    min-width: 60px;

    background-position: center center;
    background-size: cover;

    border-radius: 50%;

  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding-left: 12px;

    &-name {
      font-size: 16px;
      font-weight: 700;

      margin-top: 6px;

    }

    &-text {
      color: #ccc;
    }
  }

  &__buttons {

    &-edit {

      border: none;
      background-color: transparent;
      padding: 12px;

      cursor: pointer;

      //filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);

    }

    &-delete {
      color: red;

      border: none;
      background-color: transparent;
      padding: 12px;

      cursor: pointer;

      filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);

    }

  }
}

</style>