<template>
    <TemplateModal ref="modal">

        <template #head>
            {{ name }}
            <button
                    class="modal__button"
                    @click="$router.push('/new'); modal_state = 'close'; this.close();"
                    v-if="type === 'new'"
                    style="display: none;"
            >
                <img src="@/assets/img/new_window.svg" alt="" class="modal__button-img">
            </button>

        </template>
        <template #body>

            <TicketForm
                    ref="ticketForm"
                    @actionClose="close"
                    @updateTicketType="updateTicketType"
            />

        </template>
        <template #foot>

            <template v-if="type === 'new'">
                <ButtonList
                        :buttons="addButtons"
                        direction="column"
                        :full_width="true"
                        @click="newSubmit"
                />
            </template>

            <button v-if="type === 'edit'" type="submit" class="btn btn-primary w-100" @click="editSubmit">
                Zapisz zmiany
            </button>
        </template>

    </TemplateModal>

</template>

<script>
import router from '@/router';
import {mapActions, mapState} from 'vuex'
import TicketForm from "../Part/Form";
import TemplateModal from "@/Library/Template/Modal";
import ButtonList from "@/Library/Components/ButtonList.vue";

export default {
    name: "TicketNewModal",
    components: {ButtonList, TemplateModal, TicketForm, router},
    data() {
        return {
            modal_state: 'close',
            type: "new",
            ticket_type: 'ticket',
        }
    },
    methods: {

        updateTicketType(value){
            console.log(value)
            this.ticket_type = value;
        },

        newSubmit(action) {

            let ticketForm = this.$refs.ticketForm;

            if (action !== 'add') {
                ticketForm.obj.id_action = action;
            }

            ticketForm.ticketSubmit();

        },

        editSubmit() {
            this.$refs.ticketForm.ticketSubmit();
        },

        open() {

            let form = this.$refs.ticketForm;
            let textFiles = form.$refs.textFiles;

            this.$refs.modal.open();

            this.type = 'new';

            setTimeout(function () {
                textFiles.focus();
            }, 100);

        },
        load(id) {

            this.type = 'edit';

            this.$refs.ticketForm.load(id);

        },
        close() {

            this.id_board = this.id_board_temp;

            this.modal_state = 'close';

            this.$refs.modal.close();

            this.clear();

        },
        clear() {

            let form = this.$refs.ticketForm;

            form.temporaryClear();
            form.clear();
        },

    },
    computed: {
        ...mapState('app', ['config']),
        ...mapState('ticket', ['ticket_actions_start']),


        addButtons() {

            let ticket_type = this.ticket_type;

            let actions = this.ticket_actions_start.map(el => ({value: el.id, name: el.name}));

            let actions_count = actions.length

            if (ticket_type !== 'ticket' || actions_count === 0) {
                actions = [{
                    name: 'Dodaj',
                    value: 'add'
                }]
            }


            return actions;

        },

        modal() {
            return document.getElementById("ticketModal");
        },

        name() {
            let ret = 'Nowe zgłoszenie';
            if (this.type === 'edit')
                ret = 'Zmień zgłoszenie';

            return ret;
        },
    },
}
</script>

<style scoped lang="scss">

</style>