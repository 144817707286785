<template>

</template>

<script>

import SystemConfigAbstractConfig from '@/Library/System/Config/Abstract/Config'

export default {
  name: "SystemConfigModule",
  extends: SystemConfigAbstractConfig,
  data: () => ({

  }),
  methods: {

  },
  created() {

    let segments = this.$route.path.split('/');
    this.module = segments[1];

  }


}
</script>

<style scoped>

</style>