<script>
import Validate from "@/Library/Forms/Validate";

export default {
  name: "InputBase",
  mixins: [Validate],
  emits: ['updateValue'],
  props: {
    autocomplete: {
      default() {
        return 'on';
      }
    },
  },
  computed: {
    autocompleteTxt(){

      let ret = this.autocomplete;

      let retType = typeof ret;

      if (retType === 'boolean' && ret === true)
        ret = 'on';
      if (retType === 'boolean' && ret === false)
        ret = 'off';

      return ret;

    },
  },
  methods: {
    focus() {

    }
  },
}
</script>